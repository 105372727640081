.noTasksPlaceholder {
  padding: 16px;
  width: 100%;
  background-color: var(--grey-50);
  color: var(--grey-700);
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.noTasksWarningTooltip {
  max-width: 250px;
}
