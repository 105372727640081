.icon {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: var(
    --Seppo-Gradient,
    linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    #cc3e76
  );
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  cursor: move;
}

.icon svg {
  height: 2rem;
  width: 2rem;
}
