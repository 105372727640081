.pageContainer {
  width: 100vw;
  height: 100vh;
  background-color: var(--grey-200);
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  overflow-y: auto;
}

.pageContainer > * {
  color: var(--grey-900);
}

.languageSelect {
  min-width: 200px;
}

.trialLoginWizard {
  height: 75%;
  min-height: 732px;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
}

.languageContainer {
  display: flex;
  justify-content: flex-end;
  width: min(84%, 1200px);
  min-width: 45rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: min(5%, 2rem);
  margin-bottom: 1.5rem;
}

.container {
  width: min(84%, 1200px);
  min-width: 720px;
  height: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 19px;
  display: flex;
}

.leftSideContainer {
  background: var(--gradient-primary-normal);
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  max-width: 336px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.leftSideContainer > .firstRow {
  color: white;
  padding: 40px 40px 40px 40px;
  display: flex;
  flex: 0;
}

.rightSideContainer {
  padding: 40px 40px 48px 58px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rightSideContainer > .logoContainer {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-bottom: 40px;
}

.imageWrapper {
  max-width: 336px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.imageWrapper > img {
  height: auto;
  /* Made the image a bit smaller so it has room to be responsive on smaller screens, otherwise
     it goes out of bounds at the bottom if it's e.g. set to 100% width
  */
  width: 85%;
  object-fit: contain;
  display: block;
}

.formSubmitButtonContainer {
  padding-top: 12px;
}

.loginForm {
  max-width: 528px;
}

.goToLoginContainer {
  display: flex;
  justify-content: center;
  max-width: 528px;
  margin-top: auto;
}

.goToLoginFont {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.linkLikeButton {
  text-decoration: underline;
  color: var(--primary-normal);
  background-color: unset;
  border: unset;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .languageContainer,
  .container {
    width: min(92%, 1200px);
  }
  .leftSideContainer {
    max-width: 300px;
  }
  .leftSideContainer > .firstRow {
    padding: 40px 35px 40px 35px;
  }
}

@media (max-width: 525px) {
  .pageContainer {
    background-color: white;
  }
  .trialLoginWizard {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: unset;
    min-height: unset;
    filter: unset;
  }
}
