.taskInformation {
  display: flex;
  align-items: center;
  row-gap: 16px;
  column-gap: 16px;
  flex-wrap: wrap;
}

.taskInformation > *:first-child {
  flex: 1;
}

.taskInformation:not(.taskInformation_withBoardsLevels) > *:last-child {
  min-width: 30%;
}

.taskInformation_withBoardsLevels > *:first-child {
  min-width: 100%;
}

.taskInformation_withBoardsLevels > *:not(:first-child) {
  flex: 1;
}
