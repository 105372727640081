.themeSelectorModal {
  --tsm-header-height: 4rem;
  --tsm-width: calc(100vw - 16px);
  --tsm-height: calc(100vh - 32px);
  --tsm-padding: 16px;

  min-width: var(--tsm-width);
  max-width: var(--tsm-width);
  max-height: var(--tsm-height);

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 19px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 24px;
  padding-top: 10px;
  overflow-y: auto;
}

@media (min-width: 800px) {
  .themeSelectorModal {
    --tsm-width: min(90vw, 875px);
  }
}

.themeSelectorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--tsm-header-height);
}

.themeSelectorHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 27px;
  font-weight: bold;
  color: #333333;
}

.themeSelectorSubTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 23px;
  font-weight: bold;
  color: #333333;
  margin-top: 16px;
  margin-bottom: 16px;
}

.themeSelectorSubText {
  font-size: 16px;
  color: #333333;
  margin-top: -4px;
  margin-bottom: 16px;
}

.themeGrid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 14px;
  padding-top: 0px;
}

.themeItem {
  display: flex;
  height: 124px;
  flex-direction: row;
  min-width: 320px;
  gap: 8px;
  border-radius: 9px;
  border: solid 1px #d4d4d4;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.themeItemLeft {
  min-width: 86px;
  max-width: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #fedfb6;
}

.themeItemRight {
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.themeItemLeft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.themeName {
  font-size: 1rem;
  color: #333333;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 2px;
}

.themeText {
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}
