.levelTasks {
  border-radius: 5px;
  border: 1px solid var(--grey-400);
  overflow: hidden;
  background-color: var(--grey-50);
}

.levelBoardsList {
  margin: 0;
  padding: 0;
}

.levelTasksList {
  margin: 0;
  padding: 0;
}

.listVerticalPadding {
  padding: 8px 0;
}

.listBottomPadding {
  padding-bottom: 8px;
}

.levelContentContainer {
  position: relative;
}
