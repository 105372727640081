.buttonLink {
  padding: 10px 24px;
  background: var(--gradient-primary-normal);
  border: 1px solid white;
  border-radius: 99px;
  font-weight: 500;
  line-height: 23px;
  min-width: fit-content;
  max-width: fit-content;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.06em;
}

.iconWrapper {
  font-size: 1.5rem;
  min-width: 24px;
  min-height: 24px;
  color: white;
}

.label {
  font-size: 1rem;
  color: white;
}

.buttonLink:hover {
  background: var(--gradient-primary-hover);
}

.buttonLink:active {
  background: var(--gradient-primary-active);
  color: rgba(242, 242, 242, 0.7);
}
