.relativeContainer {
  position: relative;
  overflow: visible;
  width: fit-content;
}

.popupButton {
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: transparent;
  background-color: transparent;
  color: var(--grey-600);
  cursor: pointer;
}
.popupButton:hover {
  color: var(--grey-400);
}

.popup {
  --offset: calc(1.25rem + 16px + 8px);
  position: absolute;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--grey-100);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: fit-content;
  z-index: 102;
  overflow: hidden;
}

.position_above-up.direction_left {
  bottom: var(--offset);
  right: 0;
}
.position_above-up.direction_right {
  bottom: var(--offset);
  left: 0;
}

.position_inline-up.direction_left {
  bottom: 0;
  right: var(--offset);
}
.position_inline-up.direction_right {
  bottom: 0;
  left: var(--offset);
}

.position_below-down.direction_left {
  top: var(--offset);
  right: 0;
}
.position_below-down.direction_right {
  top: var(--offset);
  left: 0;
}

.position_inline-down.direction_left {
  top: 0;
  right: var(--offset);
}
.position_inline-down.direction_right {
  top: 0;
  left: var(--offset);
}

.position_inline-center.direction_left {
  top: 0;
  transform: translateY(-50%);
  right: var(--offset);
}

.position_inline-center.direction_right {
  top: 0;
  transform: translateY(-50%);
  left: var(--offset);
}
