.inputLabel {
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  color: var(--grey-900);
}

.inputContainer {
  padding-left: calc(3.25rem + 8px);
  padding-top: 16px;
}

@media (min-width: 1024px) {
  .gpsProximitySelect {
    width: 50%;
  }

  .proximityError {
    right: calc(50%);
  }
}
