.membersTable {
  border-collapse: collapse;
}

.membersTable th {
  text-transform: uppercase;
  font-size: var(--small-font);
  line-height: var(--small-font-line-height);
  color: var(--grey-900);
  font-weight: 500;
  text-align: left;
  padding: 14px 12px;
}

.membersTable tbody {
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
}

.membersTable tbody td {
  padding: 14px 12px;
  background-color: white;
}

.membersTable tbody td:first-of-type {
  border-radius: 3px 0 0 3px;
}

.membersTable tbody td:last-of-type {
  border-radius: 0 3px 3px 0;
  max-width: fit-content;
}

.membersTable tbody tr {
  border: 1px solid var(--grey-250);
}

.memberActionsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.memberActionsContainer button {
  display: flex;
  align-items: center;
  max-width: fit-content;
  cursor: pointer;
  gap: 10px;
  background-color: transparent;
  border: none;
}

.memberActionsContainer button span:nth-of-type(2) {
  font-size: var(--tiny-font);
  color: var(--grey-900);
}

.memberActionsContainer button span:first-of-type {
  display: flex;
  align-items: center;
  font-size: 1.15rem;
  color: var(--grey-600);
}

.memberActionsContainer button:hover span:first-of-type {
  color: var(--grey-900);
}

.memberActionsContainer span {
  white-space: nowrap;
}

.memberEditingRoleActionsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.memberEditingRoleActionsContainer > button {
  display: inline-flex;
}

.memberActionsContainer,
.memberEditingRoleActionsContainer {
  min-height: 2.8rem;
}

.roleEditSelect {
  min-width: 100px;
}
