.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.headerButtonContainer {
  border: unset;
  background-color: unset;
  padding: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey-900);
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}

.stateIcon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
  color: var(--grey-600);
}
.stateIcon_ready {
  color: var(--grey-900);
}

.stateTitle {
  width: 100%;
  text-align: start;
}

.chevron {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.rotateChevron {
  transform: rotateZ(180deg);
}

.taskGroupContainer {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  gap: 4px;
  margin: 0;
}
