.button {
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 13px;
  background-color: var(--grey-50);
  border: none;
  color: var(--primary-normal);
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--grey-100);
}

.button:active {
  background: var(--primary-dark);
  color: #fff;
}

.iconContainer {
  background-color: #fff;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px 0px #0000001a;
}

.button:hover > .iconContainer {
  background: var(--gradient-primary-normal);
  color: #fff;
}

.button:active > .iconContainer {
  background-color: #fff;
  color: var(--primary-normal);
}
