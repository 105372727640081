.missingWordContainer {
  text-align: center;
  text-transform: uppercase;
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  border-radius: 9px;
  padding: 7px 1rem 7px 12px;
  position: relative;
}

.missingWordSuccess {
  background-color: var(--green-25);
  border: 1px solid var(--green-100);
  color: var(--grey-900);
}

.missingWordWarning {
  background-color: var(--grey-100);
  border: 1px solid var(--green-50);
  color: var(--grey-700);
}

.missingWordError {
  background-color: var(--grey-100);
  border: 1px solid var(--validation-error);
  color: var(--red-800);
}

.missingWordIncorrectContent {
  display: flex;
  gap: 1rem;
}

.missingWordIncorrectColumn {
  flex: 1;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.missingWordNoAnswer {
  color: var(--grey-700);
}

.answerOptionContainer {
  display: table-cell;
  width: 100%;
}

.answerOptionContainer p {
  width: 100%;
  min-width: 100%;
}

.answerOptionsTable {
  display: table;
  border-spacing: 0 8px;
}

.answerOptionsTable img {
  max-width: 100%;
  height: auto;
}

.answerOptionRow {
  display: table-row;
}

.answerOptionLeftColumn {
  display: table-cell;
  width: 100%;
}

.answerOptionRightColumn {
  display: table-cell;
  padding-inline-start: 16px;
  vertical-align: middle;
}

.answerOptionRightColumn_lessPadding {
  padding-inline-start: 8px;
}

.timeExceededFadeout {
  opacity: 0.5;
}

.answerOptionRowContent {
  word-break: break-all;
}
