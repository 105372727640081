.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: calc(100% - 5.25rem);
  position: absolute;
  z-index: 1;
  top: 5.25rem;
  width: 100%;
  background-color: var(--grey-200);
}

.spinnerContainer {
  margin-top: 100px;
}

.reportsView {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.reportLink {
  align-self: flex-end;
  margin-bottom: 8px;
  color: var(--primary-normal);
  text-decoration: none;
}

.reportLink:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

.reportContainer {
  width: 100%;
  height: 100%;
  border: 0;
}

.notAvailableInfo {
  color: var(--grey-900);
}
