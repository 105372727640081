.gameName {
  font-weight: var(--bold);
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.usersContainer {
  display: flex;
  align-items: center;
}

.usersContainer > p {
  display: flex;
  flex-direction: column;
}

.lastModifiedContainer {
  display: flex;
  align-items: center;
}

.infoContainer {
  gap: 4px;
}

.infoItem {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.infoItem svg {
  font-size: 1rem;
}

.contextMenuButton {
  font-size: 1.25rem;
}

@media (max-width: 1200px) {
  .lastModifiedColumn {
    display: none;
  }

  .infoColumn {
    display: none;
  }
}
