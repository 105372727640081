.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1000px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: space-between;
  gap: 16px;
}

.compareHeaderRow {
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.welcomeBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 120px;
  border-radius: 15px;
  justify-content: space-between;
  padding: 20px;
  border-width: 1px;
  border: 1px solid var(--primary-normal);
  background-color: white;
  overflow: hidden;
}

.welcomeLeft {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.welcomeRight {
  position: relative;
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: center;
}

.pinkBubbleSmall {
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  border-radius: 170px;
  background: linear-gradient(0deg, #ffebf5, #ffebf5),
    linear-gradient(140.96deg, rgba(255, 255, 255, 0.5) 3.68%, rgba(255, 255, 255, 0) 100%);
}

.pinkBubbleBig {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 200px;
  border-radius: 170px;
  background: linear-gradient(0deg, #ffebf5, #ffebf5),
    linear-gradient(140.96deg, rgba(255, 255, 255, 0.5) 3.68%, rgba(255, 255, 255, 0) 100%);
}

.pinkTrophy {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 10px;
  left: 0px;
}

.welcomeTitle {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 24px;
  letter-spacing: 2%;
  color: var(--primary-normal);
}

.basicGreyText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--grey-900);
}

.pinkBoldText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--primary-normal);
}

.closeIcon {
  cursor: pointer;
  color: var(--primary-normal);
}

.tableTitleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkbox {
  cursor: pointer;
  color: var(--primary-normal);
  display: flex;
  width: 24px;
  height: 24px;
}

.checkbox svg {
  width: 24px;
  height: 24px;
}

.dimmed {
  opacity: 0.1;
}

.dimmedButton {
  opacity: 0.5;
}

.hiddenButton {
  display: none;
}

.topComparisonButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: -10px;
  margin-top: 10px;
}

.comparisonButtons {
  margin-top: -40px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-self: center;
}

.unselectButton {
  width: max-content;
}

.compareButton {
  width: max-content;
}

.compareBack {
  cursor: pointer;
}

.compareBack svg {
  height: 32px;
  width: 32px;
}

.compareView {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.downloadPdfButton {
  margin-left: auto;
}

.hideShadows div {
  box-shadow: none !important;
}

.hideShadows table {
  box-shadow: none !important;
}

.hideShadows .compareBack {
  display: none;
}

.hideShadows .downloadPdfButton {
  display: none;
}

.creator {
  color: var(--grey-700);
}
