.badgePickerModal {
  --bpm-header-height: 4rem;
  --bpm-footer-height: 4.875rem;
  --bpm-width: calc(100vw - 16px);
  --bpm-height: calc(100vh - 32px);
  --bpm-padding: 16px;

  min-width: var(--bpm-width);
  max-width: var(--bpm-width);
  max-height: var(--bpm-height);

  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 550px) {
  .badgePickerModal {
    --bpm-width: min(90vw, 500px);
  }
}

.badgePickerHeader,
.badgePickerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--bpm-padding);
}

.badgePickerHeader {
  height: var(--bpm-header-height);
}

.badgePickerHeader {
  height: var(--bpm-header-height);
}

.badgePickerHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.badgePickerHeaderTitle > span:first-of-type {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.badgePickerHeaderTitle > span:last-of-type {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.badgePickerFooter {
  height: var(--bpm-footer-height);
  background-color: var(--grey-50);
}

.badgePickerMainSection {
  max-height: calc(var(--bpm-height) - var(--bpm-header-height) - var(--bpm-footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  align-items: center;
}

.badgesList {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.badgeListItem {
  list-style: none;
}
