.profileContainer {
  display: flex;
  flex-direction: column;
}

.rowContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
}

.firstColumn {
  min-width: min(25%, 11rem);
  font-weight: 700;
}

.secondColumn {
  flex: 1;
}

.inputField {
  min-width: 20rem;
}

.logOutButtonContainer {
  margin-top: 2.5rem;
}

.textContent {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.emailTooltipIcon {
  font-size: 1.5rem;
  cursor: pointer;
}

.emailTooltip {
  min-width: 120px;
}
