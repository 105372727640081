.formContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.titleRow {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 6px;
}

.titleTooltip {
  max-width: 200px;
}
