.container {
  padding: 18px 16px;
  background-color: var(--grey-50);
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.toggleSwitchContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
