.backgroundContainer {
  width: 100vw;
  height: 100vh;
  background: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
}

.contentContainer {
  max-width: 60vw;
  width: fit-content;
  height: fit-content;
  border-radius: 19px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  align-items: center;
  margin: 3rem;
}

.logoContainer {
  color: var(--primary-normal);
  font-size: 2rem;
}

.bottomBorder {
  width: 100%;
  height: 25px;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
}
.bottomBorder:after {
  content: '';
  width: 100%;
  height: 8px;
  background: var(--primary-normal);
  display: block;
  position: absolute;
  bottom: 0;
}

.pinPicture {
  position: absolute;
  top: -40px;
  right: -40px;
  color: var(--primary-normal);
  font-size: 175px;
}

.pinPicture svg {
  -webkit-filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
}

.pinTitle {
  border-radius: 3px;
  padding: 4px;
}
.pinTitle > span {
  text-align: center;
  color: var(--grey-900);
  font-weight: 700;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 3rem;
  line-height: 150%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.pinContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1 1 100%;
  width: 100%;
}
.pinContent {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.pinIcon {
  color: var(--primary-normal);
  font-size: 4rem;
  margin-right: 4px;
  margin-top: 4px;
}
.playSeppo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 500;
}
.pinCode {
  font-weight: 700;
  color: var(--primary-normal);
  font-size: 4rem;
}
.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 3rem;
  background-color: white;
}
