.answerOption {
  background-color: var(--grey-50);
  border: 1px solid var(--grey-400);
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.answerOptionRowWithMenu,
.answerOptionRow {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.answerOptionRowWithMenu {
  align-items: center;
}

.answerOptionRow {
  align-items: flex-start;
}
