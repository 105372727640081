.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  overflow-y: auto;
  margin: 0;
}

.searchField {
  height: 2.75rem;
}

.iconColor {
  color: var(--primary-normal);
}

.peopleListContainer {
  list-style: none;
  padding: 16px 16px 16px 32px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.directChatListContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  gap: 16px;
  overflow-y: auto;
  margin: 0;
}

.chatNameContainer {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.backButton {
  display: flex;
  border: unset;
  background-color: transparent;
  color: var(--primary-normal);
  font-size: 1.25rem;
  width: fit-content;
  padding: 8px;
  margin-left: 6px;
  cursor: pointer;
  border-radius: 2rem;
}

.isolationShadow {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
  isolation: isolate;
}
