.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1000px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: space-between;
  gap: 16px;
}

.insightsBox {
  display: flex;
  flex-direction: row;
  height: 120px;
  border-radius: 15px;
  justify-content: space-between;
  padding: 20px;
  border-width: 1px;
  border: 1px solid var(--primary-normal);
  background-color: white;
}

.insightsLeft {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.insightsRight {
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: flex-end;
}

.insightTitle {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 24px;
  letter-spacing: 2%;
  color: var(--primary-normal);
}

.basicGreyText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--grey-900);
}

.pinkBoldText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--primary-normal);
}

.closeIcon {
  cursor: pointer;
  color: var(--primary-normal);
}

.activityBox {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 784px;
  border-radius: 15px;
  justify-content: flex-end;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px #0000001a;
  gap: 24px;
}

.activityItem {
  display: flex;
  height: 116;
  gap: 38px;
  border-radius: 15px;
  padding: 20px;
  background-color: var(--reporting-green-light);
}

.activityItemPink {
  background-color: var(--primary-light);
}

.activityText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--grey-900);
}

.activityHighlightText {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--reporting-green-normal);
}

.activityHighlightTextPink {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
  color: var(--primary-normal);
}

.activityIcon {
  color: var(--reporting-green-normal);
}

.activityIconPink {
  color: var(--primary-normal);
}

.activityIcon > svg {
  height: 24px;
  width: 24px;
}

.activityIconPink > svg {
  height: 24px;
  width: 24px;
}

.orgUserNameCol {
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.orgUserNameColBold {
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold !important;
}

.hideShadows div {
  box-shadow: none !important;
}

.hideShadows table {
  box-shadow: none !important;
}

.hideShadows .compareBack {
  display: none;
}

.hideShadows .downloadPdfButton {
  display: none;
}
