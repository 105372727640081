.errorPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.image {
  width: 300px;
  max-width: min(300px, calc(100vw - 40px));
  height: auto;
  margin-bottom: 12px;
}

.title {
  text-align: center;
  color: var(--primary-normal);
}

.description {
  max-width: min(670px, calc(100vw - 40px));
  margin: auto;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--grey-900);
}
