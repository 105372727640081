.audioArea {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  height: 56px;
}

.audioAreaPreview {
  height: 100% !important;
}

.recorderAndInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.recorder {
  display: flex;
  align-items: center;
}

.recordingText {
  font-size: 12px;
  font-style: italic;
  color: var(--grey-700);
  animation: blinker 2s linear infinite;
}

.recordInfoText {
  font-size: 12px;
  font-style: italic;
  color: var(--grey-700);
  margin-left: 10px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.audioArea img {
  display: none;
}

.audioArea canvas {
  width: 240px;
  height: 30px;
}

.audioArea audio {
  height: 30px;
}

.closeAudioArea {
  cursor: pointer;
  align-self: flex-start;
  margin-top: -8px;
}

.audioArea div[class*='audio-recorder'] {
  width: 320px;
}

.audioNotRecording div[class*='audio-recorder'] {
  display: none;
}

.audioStopButton {
  width: 12px;
  height: 12px;
  background-color: var(--primary-normal);
  cursor: pointer;
  border-radius: 3px;
  margin-left: -22px;
}

.audioStopButton svg {
  height: 1.5rem;
  width: 1.5rem;
}

.recStartButton {
  width: 12px;
  height: 12px;
  background-color: var(--primary-normal);
  cursor: pointer;
  border-radius: 3px;
  margin-left: -22px;
}

.recStartButton svg {
  height: 1.5rem;
  width: 1.5rem;
}

.narrowMode audio {
  width: 250px;
}

.narrowMode div[class*='audio-recorder'] {
  max-width: 250px;
}

.narrowMode canvas {
  max-width: 170px;
}

.previewArea {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  width: 100%;
}

.buttonsRow {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
