.title {
  display: flex;
  flex-grow: 1;
}

.filterAndSortContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sortByWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sortByLabel {
  color: var(--grey-600);
}

.flashTaskInstructions {
  color: var(--grey-700);
  text-align: center;
}

.itemsList {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0;
  margin: 0;
}
