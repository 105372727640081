.pointsPill {
  display: flex;
  background: var(--gradient-secondary-normal);
  color: white;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 4rem;
  white-space: nowrap;
  border: solid 2px white;
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
}

.pointsPill_small {
  padding: 0.125rem 0.75rem;
  gap: 0.25rem;
  height: 1.25rem;
}

.pointsPill_medium {
  padding: 0.125rem 0.75rem;
  gap: 0.25rem;
  height: 1.5rem;
}

.pointsPill_large {
  padding: 0.625rem 1.5rem;
  gap: 0.32rem;
  height: 2.25rem;
}

.text {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
}

/* COLOUR CODING */
.pointsPill_graded {
  background: var(--cyan-800);
}

.pointsPill_ready {
  background: var(--grey-900);
}

.pointsPill_time_finished {
  background: var(--grey-500);
}
