.loaderBackgroundOverlay {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 18px;
  background-color: var(--grey-700);
  z-index: 100;
  opacity: 0.2;
}

.loaderContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
