.topBar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.searchForm {
  flex: 1;
  max-width: 736px;
}

.searchInput {
  width: 100%;
}

.userMenuDropdown {
  position: relative;
  padding: 8px 12px;
  font-size: 1.5rem;
  color: white;
  width: fit-content;
  display: flex;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  justify-content: space-between;
  margin-left: auto;
  cursor: pointer;
}

.trialButton {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  min-width: 200px;
  padding: 16px 20px;
}

.relativeParent {
  position: relative;
}

.inlineErrorMessage {
  position: absolute;
  top: 4.75rem;
  left: 1rem;
}

.overrideDefaultOutline:not(:focus) {
  outline: 2px solid var(--validation-error-secondary);
}

.warningBadge {
  position: absolute;
  background-color: var(--validation-error-secondary);
  color: black;
  border-radius: 4px;
  top: 3.375rem;
  left: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  min-height: 1.0625rem;
  z-index: 1;
}

.userInitials {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.125rem;
  color: white;
  background: var(--gradient-primary-normal);
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
}

.seppoLogo img {
  cursor: pointer;
}
