.container {
  width: 525px;
  border: 1px solid var(--grey-600);
  border-radius: 5px;
  position: fixed;
  top: calc(5.25rem - 6px);
  right: 54px;
  z-index: 200;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: calc(100vh - 5.25rem + 6px - 32px);
}

.header {
  display: flex;
  align-items: flex-start;
  font-size: 1.5rem;
  padding: 16px 16px 24px 16px;
  border-bottom: 1px solid var(--grey-150);
  background-color: var(--grey-50);
  flex-direction: column;
  gap: 10px;
}

.info {
  display: inherit;
  gap: 0.625rem;
  font-size: 18px;
  color: var(--grey-700);
  line-height: 24px;
}

.publishTitle {
  color: var(--grey-700);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 18px 16px;
  background-color: var(--grey-50);
  border-top: 1px solid var(--grey-200);
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding: 16px 16px 24px 16px;
}

.separator {
  border-bottom: 1px solid var(--grey-200);
}

.separatorWithMargins {
  margin-bottom: 10px;
  margin-top: 16px;
}

.generalSection {
  display: flex;
  flex-direction: column;
}

.fieldContainer {
  flex: 1;
  width: 100%;
  margin-top: 16px;
}

.fieldContainer label {
  width: 8.2rem;
}

.field {
  flex: 1;
  font-size: 1rem;
  width: 100%;
}

.keywordField div[class*='placeholder'] {
  max-width: 95%;
}

.keywordField div[class*='ValueContainer'] {
  padding: 8px 8px;
}

.togglerArea {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.togglerAreaHighlight {
  display: flex;
  flex-direction: column;
  gap: 8px;
  outline: 2px solid var(--validation-error);
  border-radius: 9px;
  outline-offset: 3px;
  padding-bottom: 13px;
}

.hiddenContainer {
  margin-top: -15px;
  margin-bottom: 15px;
}

.hiddenField {
  height: 0px;
  padding: 0;
  outline: initial !important;
  border: none;
}

.thinnerToggler > div {
  padding-bottom: 0px;
}
