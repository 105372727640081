.container {
  display: flex;
  padding: 16px;
  gap: 12px;
  background: var(--gradient-secondary-normal);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.container_flash {
  background: var(--gradient-primary-normal);
}

.leftContainer > svg {
  color: white;
  width: 2rem;
  height: 2rem;
}

.middleContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
}

.middleContainer > h5 {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  word-break: break-word;
}

.middleBottomRow {
  display: flex;
  gap: 16px;
}

.toggleSwitchContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.toggleSwitchContainer > p {
  color: white;
}

.closeButton {
  color: white;
}
.closeButton:hover {
  color: var(--grey-400);
}
