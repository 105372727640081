.controlWithLabelContainer {
  display: flex;
  align-items: center;
  padding: 8px;
}

.labelContainer {
  cursor: pointer;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.description {
  font-size: 0.75rem;
  color: var(--grey-700);
}

.error {
  top: calc(100% - 6px);
}

@media (max-width: 900px) {
  .controlWithLabelContainer {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1rem;
  }
  .labelContainer {
    padding-left: 0;
  }
  .toggle {
    margin-left: 8px;
  }
}
