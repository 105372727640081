.taskLibrarySearchContainer {
  margin: var(--tlm-padding);
  background-color: var(--grey-100);
  border-radius: 13px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.searchRowUnequalCols,
.searchRowEqualCols {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.searchRowUnequalCols > *,
.searchRowEqualCols > * {
  flex: 1;
}

@media (min-width: 680px) {
  .searchRowUnequalCols,
  .searchRowEqualCols {
    flex-direction: row;
  }

  .searchRowUnequalCols > *:first-child {
    max-width: 67%;
  }

  .searchRowUnequalCols > *:last-child {
    max-width: 33%;
  }

  .searchRowEqualCols > * {
    max-width: 50%;
  }
}
