.gamePreviewContainer {
  flex: 1;
  padding: 16px;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.gamePreviewContainer > * {
  flex: 1;
  width: 100%;
}

.gamePreviewContainer > *:not(:first-child) {
  margin-top: 32px;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1235px) {
  .gamePreviewContainer {
    flex-direction: row;
    gap: 16px;
    padding: 32px;
  }

  .gamePreviewContainer > * {
    width: 50%;
  }

  .gamePreviewContainer > *:not(:first-child) {
    margin-top: 0;
  }
}

@media (min-width: 1400px) {
  .gamePreviewContainer {
    padding: 80px;
  }
}

.mapPreviewContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mapSelectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.mapOptionButtonsGroup {
  display: flex;
}

.mapOptionButtonsGroup > button:not(:first-child) {
  margin-left: 8px;
}

.gameBoardOptionButton {
  width: 3.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background-color: white;
  color: var(--grey-900);
  border-radius: 8px;
  border: 1.5px solid var(--grey-500);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.gameBoardOptionButton:hover {
  background-color: var(--grey-200);
  color: var(--grey-900);
}

.gameBoardOptionButtonActiveOwn {
  background-color: var(--primary-normal);
  color: white;
}
.gameBoardOptionButtonActiveOwn:hover {
  background-color: var(--primary-normal);
  color: white;
}

.gameBoardOptionButtonActiveLibrary {
  background-color: var(--secondary-normal);
  color: white;
}
.gameBoardOptionButtonActiveLibrary:hover {
  background-color: var(--secondary-normal);
  color: white;
}

.outerMapContainer {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
}

.exercisePreviewContainer {
  height: 100%;
  overflow-y: auto;
  padding: 0 16px 20px 16px;
  max-width: calc(400px + 2 * 16px);
}

.exercisesList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.exercisesList > li:not(:first-child) {
  margin-top: 16px;
}
