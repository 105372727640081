.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 16px;
}

.reportButton {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background-color: white;
  color: var(--grey-900);
  border-radius: 8px;
  border: 1.5px solid var(--grey-500);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.reportButton:hover {
  background-color: var(--grey-200);
  color: var(--grey-900);
}

.reportButtonActive {
  background-color: var(--primary-normal);
  color: white;
}
.reportButtonActive:hover {
  background-color: var(--primary-normal);
  color: white;
}

.reportsView {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reportLink {
  align-self: flex-end;
  margin-bottom: 8px;
  color: var(--primary-normal);
  text-decoration: none;
}

.reportLink:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

.reportContainer {
  width: 100%;
  height: 100%;
  border: 0;
}
