.icon {
  border: 1px solid;
  border-color: var(--grey-600);
  background-color: var(--primary-normal);
  cursor: pointer;
}

.icon_inactive {
  background-color: var(--primary-light);
}
