.playersAddManuallyModal {
  --pamm-header-height: 4rem;
  --pamm-footer-height: 4.875rem;
  --pamm-width: calc(100vw - 16px);
  --pamm-height: calc(100vh - 32px);
  --pamm-padding: 16px;

  min-width: var(--pamm-width);
  max-width: var(--pamm-width);
  max-height: var(--pamm-height);

  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .playersAddManuallyModal {
    --pamm-width: min(90vw, 640px);
  }
}

.playersAddManuallyModalStartingTask {
  --pamm-width: min(90vw, 440px);
}

.infoText {
  color: var(--grey-700);
  line-height: 24px;
}

.playersAddManuallyHeader,
.playersAddManuallyFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--pamm-padding);
}

.playersAddManuallyHeader {
  height: var(--pamm-header-height);
}

.playersAddManuallyHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.playersAddManuallyHeaderTitle > span:first-of-type {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.playersAddManuallyHeaderTitle > span:last-of-type {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.playersAddManuallyFooter {
  height: var(--lm-footer-height);
  background-color: var(--grey-50);
}

.playersAddManuallyMainSection {
  height: calc(var(--lm-height) - var(--lm-header-height) - var(--lm-footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 24px 16px;
}
@media (min-width: 600px) {
  .nameInputContainer {
    max-width: 75%;
  }
}

.requirementsWarning {
  background-color: var(--validation-error-light);
  padding: 12px;
  border-radius: 8px;
  color: var(--red-800);
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  max-width: fit-content;
}

.separator {
  border-bottom: 1px solid var(--grey-200);
}
