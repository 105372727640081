.gradeAnswersPanel {
  border: 2px solid var(--blue-100);
}

.header {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-200);
}

.header h1 {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-900);
  padding: 12px 0;
}

.body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding: 0 12px;
  scroll-behavior: smooth;
}

.playerCardContainer {
  padding: 12px 0;
}

.taskName {
  color: var(--grey-900);
  text-align: center;
}

.listWithGap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtaskCount {
  color: var(--grey-700);
}

.collapseEvaluatedToggle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.collapseEvaluatedLabel {
  color: var(--grey-900);
  cursor: pointer;
}

.textButton {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--primary-normal);
  text-transform: uppercase;
  cursor: pointer;
}

.textButton:hover {
  color: var(--primary-light);
}

.subtasksList {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.scrollPadding {
  min-height: 16px;
}

.expandableAddTime {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.addTimeLabel {
  color: var(--grey-900);
  cursor: pointer;
}

.addTimeContainer {
  justify-content: center;
  padding: 10px;
}
