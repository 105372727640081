.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.titleAndDescription > span {
  display: block;
}

.titleAndDescription > span:first-child {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-900);
}

.titleAndDescription > span:last-child {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--grey-700);
}

.uncheckedTitle {
  font-weight: 500;
}

.checkedTitle {
  font-weight: 700;
}
