.toolTipArea {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 1px;
  height: 1px;
  margin-left: 30px;
  pointer-events: none;
}

.hoverMenuBase {
  border-radius: 999px;
  width: 16px;
  height: 16px;
  box-shadow: 0px 5.231px 10.462px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  margin-top: -2px;
  pointer-events: all;
}

.alertRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  gap: 4px;
}

.alertIcon {
  font-size: 1rem;
  font-weight: bold;
  color: var(--yellow-100);
}

.moreMenuButton {
  padding: 0px;
}

.moreMenuButton svg {
  width: 12px;
  height: 12px;
}

.moreMenuButton:hover svg {
  width: 12px;
  height: 12px;
  color: var(--grey-900);
}

.moreMenuButton + div {
  margin-top: -5px;
}

.pinTooltipOffset {
  margin-left: -20px;
}

.lineTooltipOffset {
  margin-left: -7px;
}

.connectingInfo {
  border: solid 1px;
  position: fixed;
  color: var(--grey-700);
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 999;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
