.originalCheckbox {
  appearance: none;
  margin: 0;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
  border-radius: 9px;
  border: 2px solid;
  border-color: var(--grey-400);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  color: var(--grey-900);
}

.labelSmall {
  width: 8rem;
  height: 6.25rem;
}

.labelMedium {
  width: 9.375rem;
  height: 6.25rem;
}

.labelLarge {
  width: 15rem;
  height: 10rem;
  padding: 1rem;
  text-align: center;
}

.label:not(.labelActive) .uncheckedIcon {
  color: var(--primary-normal);
}

.label:has(.originalCheckbox:focus-visible) {
  outline: 2px solid -webkit-focus-ring-color;
}
.label:not(.labelDisabled):hover {
  background: var(--primary-background);
  color: var(--primary-normal);
  border-color: transparent;
  box-shadow: 0px 8px 8px -6px var(--primary-dark);
}
.label:not(.labelDisabled):hover > span > * {
  color: var(--primary-dark);
}
.labelActive {
  background: var(--gradient-primary-normal);
  color: white;
  border-color: transparent;
  background-origin: border-box;
  box-shadow: 0px 12px 12px -6px var(--primary-dark);
}
.labelActive:hover {
  background: var(--gradient-primary-normal);
  color: white;
  border-color: transparent;
  background-origin: border-box;
  box-shadow: 0px 12px 12px -6px var(--primary-dark);
}
.labelActive:hover > span > * {
  color: white;
}
.label:not(.labelDisabled):active {
  background: var(--primary-dark);
  color: white;
}
.label:not(.labelDisabled):active > span * {
  color: white;
}
.iconContainer {
  font-size: 2.5rem;
  color: var(--gradient-primary-normal);
}
.uncheckedIcon {
  font-size: 2.5rem;
}
.labelDisabled {
  color: var(--grey-600);
  opacity: 40%;
}
.labelDisabled * {
  color: var(--grey-600);
}

.tooltipWrapper {
  position: absolute;
  right: 8px;
  top: 8px;
  color: var(--grey-900);
}
