.icon {
  color: var(--primary-normal);
}

.icon_highlighted {
  filter: brightness(2);
}

.icon_taskHovered {
  filter: brightness(1.75);
}

.icon_highlighted.icon_taskHovered {
  filter: brightness(2.5);
}

@keyframes pulsate {
  from {
    filter: brightness(2);
  }
  50% {
    filter: brightness(1);
  }
  to {
    filter: brightness(2);
  }
}

.icon_taskNew {
  filter: brightness(2);
  animation: pulsate 1s ease-in-out 3;
}

.notReachable {
  color: var(--grey-500);
}
