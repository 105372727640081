.modalContainerSticky {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

.modalContainer {
  width: 80vw;
  max-width: 850px;
  height: fit-content;
  position: relative;
  z-index: 200;
  top: 10px;
  display: flex;
  background-color: var(--grey-200);
  flex-direction: column;
}

.modalTop {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  word-break: break-all;
  overflow-y: auto;
  gap: 1rem;
  border-bottom: 1px solid var(--grey-600);
}

.modalTopText {
  font-weight: 700;
  font-size: 18px;
}

.modalTopIcon {
  svg {
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-normal) !important;
    cursor: pointer;
    background-color: transparent;
  }
}
.modalContent {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: var(--grey-900);
  background-color: white;
  padding: 2rem;
  gap: 1rem;
}

.closeButton {
  position: absolute;
  top: 6px;
  right: 6px;
}

.modalDescriptionBox {
  background-color: var(--beige-50);
  width: 100%;
  padding: 1.5rem;
  line-height: 24px;
}

.modalDescriptionBoxBroughtBy {
  text-transform: uppercase;
  font-size: 14px;
}

@media (max-width: 900px) {
  .modalTop {
    padding: 1.25rem;
    padding-top: 2rem;
  }
  .modalContent {
    padding: 1.25rem;
  }
  .modalContainer {
    width: 100vw;
  }
}
