.container {
  width: min(84%, 1200px);
  min-width: 720px;
  height: 100%;
  min-height: 732px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 19px;
  display: flex;
}

.leftSideContainer {
  background: var(--gradient-primary-normal);
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  max-width: 336px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.leftSideContainer > .firstRow {
  color: white;
  padding: 40px;
  display: flex;
  flex: 0;
}

.leftSideContainer > .secondRow {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
  display: flex;
  flex: 1;
}

.secondRow > p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.rightSideContainer {
  padding: 40px 40px 48px 58px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rightSideContainer > .logoContainer {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-bottom: 40px;
}

.rightSideContainer > .backButtonContainer {
  display: flex;
  align-items: flex-end;
  height: 40px;
}

.mainContentWrapper {
  max-width: 528px;
}

.mainContentWrapper > h1 {
  margin-bottom: 20px;
}

.paragraphContainer {
  margin-bottom: 40px;
}

.paragraphContainer {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mainContentWrapper > div p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.mainContentWrapper > .loginOptionsRow {
  display: flex;
  align-items: center;
  gap: 4px;
}

.mainContentWrapper > .separatorWrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.separator {
  display: flex;
  flex: 4;
  border-top: 1px solid var(--grey-500);
}

.separatorWrapper > p {
  padding: 0 36px;
}

.lostPasswordInstructions {
  font-size: 0.75rem;
  color: var(--grey-700);
  margin-bottom: 40px;
}

.submitError {
  text-align: center;
  margin-top: 10px;
  color: var(--red-500);
}

.marginForButtons {
  margin-left: 8px;
  display: flex;
}

.loginOrTrialContainer {
  display: flex;
  justify-content: center;
  max-width: 528px;
  margin-top: auto;
}

.loginOrTrialContainer > :last-child {
  color: var(--grey-600);
}

.loginOrTrialFont {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.linkLikeButton {
  text-decoration: underline;
  color: var(--primary-normal);
  background-color: unset;
  border: unset;
  cursor: pointer;
}

.imageWrapper {
  max-width: 336px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.imageWrapper > img {
  height: auto;
  /* Made the image a bit smaller so it has room to be responsive on smaller screens, otherwise
     it goes out of bounds at the bottom if it's e.g. set to 100% width
  */
  width: 85%;
  object-fit: contain;
  display: block;
}

.formLabelAndButtonContainer {
  max-width: 528px;
  padding-top: 24px;
}

.email {
  color: var(--primary-normal);
}

.paragraphContainer > button {
  margin: 0 auto;
}

.resetPasswordText {
  font-size: 0.75rem;
  color: var(--grey-700);
  padding-top: 12px;
}

.resetPasswordText > a {
  color: var(--primary-normal);
}

.trialPasswordBackButton {
  color: black;
  margin-left: -22px;
  margin-bottom: 24px;
  gap: 20px;
  font-size: 1.125rem;
}
.trialPasswordBackButton:hover {
  color: black;
}

.trialPasswordBackButton > span {
  font-size: 1.25rem;
  color: var(--primary-normal);
}

.passwordResetFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* MIRRORING CLASSES */
.leftSideContainerMirroring {
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
/* ***************** */

@media (max-width: 1200px) {
  .container {
    width: min(92%, 1024px);
  }
  .leftSideContainer {
    max-width: 280px;
  }
  .leftSideContainer > .firstRow {
    padding: 40px 35px 40px 35px;
  }
  .leftSideContainer > .secondRow {
    padding: 0 35px;
  }
  .rightSideContainer {
    padding: 35px 30px;
  }
  .mainContentWrapper > .separatorWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .paragraphContainer {
    margin-bottom: 30px;
  }
}
