.datePickerControlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey-400);
  border-radius: 9px;
  width: 100%;
  padding: 0 0.5rem;
  height: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  background-color: #fff;
  cursor: pointer;
}

.datePickerControlContainer:hover {
  border-color: var(--grey-600);
}

.datePickerControlContainer:focus {
  outline: none;
  border-color: var(--primary-normal);
}

.valueContainer {
  display: flex;
  align-items: flex-start;
}

.calendarIcon {
  margin-right: 0.5rem;
  color: var(--grey-400);
  display: flex;
  align-items: center;
}

.calendarIconWithValue {
  color: var(--primary-normal);
}

.value {
  color: var(--grey-900);
}

.placeholder {
  color: var(--grey-600);
}

.clearButton {
  border-color: transparent;
  background-color: transparent;
  color: var(--grey-700);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  font-size: 0.75rem;
  cursor: pointer;
  color: var(--grey-400);
}

.clearButton:hover {
  color: var(--grey-600);
}

/* NOTE: deliberately global, mainly color overrides  */
:global(.react-datepicker__day--selected) {
  background-color: var(--primary-normal);
}

:global(.react-datepicker__day--selected:hover) {
  background-color: var(--primary-normal);
}

:global(.react-datepicker__day--keyboard-selected) {
  background-color: var(--primary-background);
}

:global(.react-datepicker__day--keyboard-selected:hover) {
  background-color: var(--primary-light);
}

:global(.react-datepicker__header) {
  background-color: #fff;
}

:global(.react-datepicker__triangle) {
  display: none;
}
