.pointsView {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
  color: var(--grey-600);
  margin-left: auto;
}

.scoreIcon {
  display: flex;
  align-items: center;
}

.pointsActive {
  color: var(--blue-100);
}

.pointsActive .scoreIcon {
  color: #007acf;
}
