.pillsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.statusPill {
  text-transform: uppercase;
  box-shadow: 0px 4px 8px 0px #0000001a;
  background-color: white;
  border-radius: 2rem;
  padding: 0.125rem 0.5rem;
  width: fit-content;
}

.listItemStateWrapper {
  width: fit-content;
  white-space: nowrap;
}

.cardStateWrapper {
  display: flex;
}

.IN_PROGRESS,
.PAUSED {
  background: var(--gradient-primary-normal);
  color: white;
}

.ARCHIVED,
.EXPIRED {
  background-color: var(--grey-400);
  color: var(--grey-700);
}

.COMMUNITY,
.LIBRARY,
.PUBLISHED,
.TEMPLATE {
  background-color: var(--secondary-normal);
  color: white;
}

.DOWNLOADED {
  background: var(--grey-900);
  color: white;
}

.SPONSORED {
  background: var(--secondary-normal);
  color: white;
}
