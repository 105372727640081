.modalContainerSticky {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  --pamm-header-height: 4rem;
  --pamm-footer-height: 4.875rem;
  --pamm-width: calc(100vw - 16px);
  --pamm-height: calc(100vh - 32px);
  --pamm-padding: 16px;

  min-width: var(--pamm-width);
  max-width: var(--pamm-width);
  max-height: var(--pamm-height);

  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.modalTop {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--pamm-padding);
  word-break: break-all;
  gap: 1rem;
  position: relative;
  height: var(--pamm-header-height);
}

.modalTopInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modalTopText {
  font-weight: 700;
  font-size: 18px;
}

.modalTopIcon {
  svg {
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-normal) !important;
    cursor: pointer;
    background-color: transparent;
  }
}
.modalContent {
  height: calc(var(--lm-height) - var(--lm-header-height) - var(--lm-footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  --pamm-width: min(90vw, 440px);
}

@media (min-width: 768px) {
  .modalContainer {
    --pamm-width: min(90vw, 640px);
  }
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--pamm-padding);
  height: var(--lm-footer-height);
  background-color: var(--grey-50);
}

.noCancelButton {
  justify-content: flex-end;
}
