.container {
  background-color: white;
  min-width: var(--nav-sidebar-width);
  max-width: var(--nav-sidebar-width);
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
  position: relative;
}

.createButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.seppoAiIcon {
  font-size: 1.5rem !important;
  margin-top: -2px;
  margin-bottom: -2px;
}

.stickyContainer {
  position: sticky;
  top: 0;
}

.topBorderContainer {
  height: 13px;
  background-color: var(--grey-100);
}

.topBorder {
  background-color: #fff;
  height: 13px;
  border-top-right-radius: 13px;
}

.sidebarContent {
  padding: 48px 20px;
  overflow-y: auto;
  max-height: calc(100vh - var(--global-notification-height));
}

.seppoImg {
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
  cursor: pointer;
}

.sidebarLinksContainer {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

.linksList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.linksList li {
  margin-top: 2.25rem;
}

.sidebarLink {
  text-decoration: none;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.sidebarLink .iconWrapper {
  color: var(--grey-600);
}

.sidebarLink .linkTitle {
  color: var(--grey-700);
  white-space: balance;
}

.activeSidebarLink {
  font-weight: 700;
}

.iconWrapper {
  font-size: 1.75rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.linkTitle {
  font-size: 1.125rem;
}

.activeSidebarLink .iconWrapper,
.activeSidebarLink .linkTitle {
  color: var(--primary-normal);
}

.subLinksList {
  list-style: none;
  margin: 0;
}

.subLinksList li {
  list-style: none;
  text-transform: uppercase;
  margin-top: 1rem;
}

.subLink {
  text-decoration: none;
  color: var(--grey-700);
  font-size: 0.7rem;
  line-height: 1rem;
}

.activeSubLink {
  color: var(--grey-900);
  font-weight: 700;
}

.sidebarHelpButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.splitButtonLeftSide {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding: 8px 14px;
  padding-right: 8px;
}

.splitButtonRightSide {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 8px 3px;
  background-color: var(--primary-normal);
}

.whiteIcon {
  color: white;
}

.moreMenuContainer {
  background-color: white;
  position: absolute;
  top: 42px;
  left: 6px;
  z-index: 102;
  display: block;
  min-width: fit-content;
  border-radius: 5px;
  border: 1px solid var(--grey-100);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.moreMenuContainer > button {
  color: var(--primary-normal);
  padding: 8px 20px;
  font-size: var(--small-font);
  line-height: var(--small-font-line-height);
  text-transform: uppercase;
  letter-spacing: 0.066625rem;
}

.moreMenuContainer span {
  color: var(--primary-normal) !important;
}
