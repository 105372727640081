.choiceAnswerContainer {
  border: 1px solid var(--grey-400);
  border-radius: 9px;
  padding: 7px 12px;
  color: var(--grey-700);
  position: relative;
}

.answerTextAndImage {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.answerTextAndImage > img {
  border-radius: 5px;
  margin-top: 4px;
}

.choiceAnswerContainer_SUCCESS {
  border-color: var(--green-50);
  background-color: var(--green-50);
  color: white;
}

.choiceAnswerContainer_ERROR {
  border-color: var(--validation-error);
  background-color: var(--validation-error);
  color: white;
}
