.modalContainer {
  --preview-modal-sidebar-width: 25rem;
  --preview-modal-sidebar-padding: 20px;

  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  display: flex;
  background-color: var(--grey-200);
}

.modalLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalTop {
  width: 100%;
  max-width: calc(100vw - var(--preview-modal-sidebar-width));
  background-color: var(--grey-100);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  padding: 0 16px;
  word-break: break-all;
  overflow-y: auto;
}

.modalContent {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: calc(100vh - 5rem);
}

.modalRight {
  min-width: var(--preview-modal-sidebar-width);
  max-width: var(--preview-modal-sidebar-width);
  height: 100%;
  padding: var(--preview-modal-sidebar-padding) 0;
  padding-top: 3rem;
  position: relative;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
}

@media (max-width: 900px) {
  .modalContainer {
    flex-direction: column-reverse;
    overflow-y: scroll;
  }

  .modalLeft {
    flex: 1 1 100%;
    max-width: 100vw;
  }

  .modalRight {
    flex: 1 1 100%;
    max-width: 100vw;
    padding-top: 3rem;
  }

  .modalContainer {
    --preview-modal-sidebar-width: 90vw;
    --preview-modal-sidebar-padding: 5vw;
  }

  .modalTop {
    max-width: 90vw;
  }
}
