.genericTable {
  min-width: 784;
  gap: 10px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px #0000001a;
  padding: 20px;
  padding-bottom: 80px;
}

.genericTable th {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 13.33px;
  line-height: 20px;
  letter-spacing: 8%;
  text-transform: uppercase;
}

.genericTable tr {
  height: 86px;
}

.headerCell {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: left;
}

.headerSorter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerSorter div:first-child {
  rotate: 180deg;
}

.headerSorter div {
  cursor: pointer;
}

.headerSorter svg {
  width: 16px;
  height: 16px;
  color: var(--grey-600);
}

.activeDir svg {
  color: black;
}

.headerLabel {
  text-align: left;
}

.genericTable td {
  text-align: left;
  border-bottom: 1px solid var(--grey-300);
  border-collapse: collapse;
  gap: 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-left: 26px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
}

.genericTable td:first-of-type {
  text-align: left;
}

.genericTable tr:last-of-type td {
  border-bottom: none;
}

.genericTablePagination {
}

.genericTablePagination > div {
  margin-top: -86px;
  padding: 0px 20px;
}

.bold {
  font-weight: bold !important;
}

.noPagination {
  padding-bottom: 0px !important;
}

.noSort td {
  padding-left: 10px !important;
}

.checkbox {
  cursor: pointer;
  color: var(--primary-normal);
  display: flex;
  width: 24px;
  height: 24px;
}

.checkbox svg {
  width: 24px;
  height: 24px;
}

.dimmed {
  opacity: 0.1;
}
