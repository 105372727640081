.levelHeader {
  background: var(--gradient-primary-light);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey-900);
  justify-content: space-between;
}

.levelHeader > div {
  display: flex;
  gap: 8px;
  align-items: center;
}

.levelHeaderIcon {
  color: var(--primary-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.levelHeaderTitleContainer {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.levelHeaderTitle {
  font-weight: 700;
  line-height: 1.5rem;
}

.levelHeaderSubtitle {
  font-weight: 700;
  opacity: 0.8;
}

.levelHeaderCriteria {
  flex: 1;
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  align-items: center;
}

.levelHeaderCriteriaIcon {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.levelHeaderMoreMenuButton {
  padding: 0;
}

.levelRequirementWarningTooltip {
  max-width: 250px;
}
