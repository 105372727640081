.helpMenuItem {
  display: flex;
  padding: 16px 20px;
  cursor: pointer;
  gap: 16px;
}

.helpMenuItem:hover {
  background: var(--gradient-primary-background-horizontal);
}

.icon {
  font-size: 1.3125rem;
  color: var(--grey-600);
}

.label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 1rem;
  color: var(--grey-900);
}

.subLabel {
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.75rem;
  color: var(--grey-700);
}
