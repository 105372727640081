.backdrop {
  position: fixed;
  top: 0;
  z-index: 300;
  width: 100vw;
  height: 100vh;
  background-color: var(--backdrop-500);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  min-width: min(700px, calc(100vw - 64px));
  max-width: min(700px, calc(100vw - 64px));
  z-index: 301;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.modalHeader {
  height: 108px;
  display: flex;
  border-bottom: 1px solid var(--grey-200);
  padding: 16px;
}

.modalHeaderTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.modalHeaderTitle > span:first-of-type {
  font-size: 1.2rem;
  color: var(--primary-normal);
}

.modalHeaderTitle > span:last-of-type {
  color: var(--grey-900);
}

.modalContent {
  max-height: calc(100vh - 64px - 108px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.closeButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-normal);
}
.closeButton:hover {
  color: var(--primary-background);
}

.modalContainer h5 {
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--grey-700);
  font-weight: 700;
  margin-top: 1rem;
}

.modalContainer label {
  font-weight: 700;
  margin-left: 1rem;
  color: var(--primary-normal);
}

.themeDebug {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.themeDebug > div {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.colorPickingContainer {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.colorPickingContainer > div:first-of-type {
  display: flex;
  align-items: center;
}

.colorPreviewContainer {
  display: flex;
  gap: 0.5rem;
}

.colorPreviewContainer > div {
  width: 1rem;
  height: 1rem;
  border-radius: 8px;
}

.debugSection {
  background-color: var(--grey-100);
  border-radius: 5px;
  padding: 12px;
}

.debugSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.debugSectionHeader span {
  color: var(--grey-900);
  font-weight: bold;
  flex: 1;
  cursor: pointer;
}

.debugSectionHeader button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-600);
  cursor: pointer;
}

.iconExpanded {
  transform: rotate(180deg);
}

.debugSectionContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.debugHalfWidth {
  max-width: 50%;
}

.allowedOptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.allowedOptionsItem {
  display: flex;
  align-items: center;
  gap: 4px;
}

.allowedOptionsItem > span:first-of-type {
  margin-bottom: -4px;
}
