.radioGroup {
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
}

.radioInput {
  margin: 0;
  appearance: none;
  position: fixed;
}

.radioInput:focus-visible + .radioContent {
  outline: 1px solid var(--primary-normal);
}

.radioContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 9px;
  cursor: pointer;
  color: var(--primary-normal);
  width: 3rem;
  height: 3rem;
  padding-top: 2px;
}

.rawSvg {
  width: 100%;
  height: 100%;
}

.radioContent:hover {
  color: var(--primary-light);
}

.radioInput:checked + .radioContent,
.radioInput:checked + .radioContent:hover {
  color: var(--primary-light);
}
