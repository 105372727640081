.playerTasksHeader {
  display: flex;
  padding: 16px;
  gap: 12px;
  background: var(--gradient-primary-dark);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.closeButton {
  margin-left: auto;
  color: white;
}

.closeButton:hover {
  color: var(--grey-400);
}

.playerInitials {
  display: flex;
  padding: 8px;
  border-radius: 1.25rem;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  color: var(--primary-normal);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
}

.playerNameAndDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: white;
}

.playerName {
  word-break: break-word;
}

.playerDetails {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pinIcon {
  display: flex;
  align-items: center;
  color: var(--primary-light);
}

.badges > span:first-of-type {
  color: var(--primary-light);
}

.badges > span:last-of-type {
  color: white;
}
