.wrapper {
  padding: 8px;
  border-radius: 9px;
}

.wrapperExpanded {
  background-color: var(--grey-100);
}
.wrapperExpanded:not(:last-of-type) {
  margin-bottom: 8px;
}

.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  display: flex;
  gap: 8px;
  align-items: center;
}

.titleAndDescription {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-900);
  font-weight: 500;
}

.label_checked .title {
  font-weight: 700;
}

.description {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--grey-700);
}

.icon {
  font-size: 2rem;
  color: var(--grey-600);
  display: flex;
  align-items: center;
  justify-content: center;
}

.label_checked .icon {
  color: var(--primary-normal);
}
