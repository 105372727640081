.badgesList {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.badgeListItem {
  list-style: none;
}

.noBadgesText {
  padding: 12px 0;
  text-align: center;
}
