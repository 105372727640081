.badgeManagerModal {
  --bmm-header-height: 4rem;
  --bmm-width: calc(100vw - 16px);
  --bmm-height: calc(100vh - 32px);
  --bmm-padding: 16px;

  min-width: var(--bmm-width);
  max-width: var(--bmm-width);
  max-height: var(--bmm-height);

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 800px) {
  .badgeManagerModal {
    --bmm-width: min(90vw, 767px);
  }
}

.badgeManagerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--bmm-padding);
  height: var(--bmm-header-height);
}

.badgeManagerHeaderIcon {
  color: var(--primary-normal);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.badgeManagerHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.badgeManagerHeaderTitle > span:first-of-type {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.badgeManagerHeaderTitle > span:last-of-type {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.badgeManagerDescription {
  padding: var(--bmm-padding);
  border-top: 1px solid var(--grey-400);
  background-color: var(--grey-50);
  color: var(--grey-700);
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.badgeManagerMainSection {
  max-height: calc(var(--bmm-height) - var(--bmm-header-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  align-items: center;
}
