.container {
  --input-gap: 12px;
  --input-icon-size: 1.75rem;
  --input-button-size: 2.5rem;
  --input-char-count-width: 3rem;
  --input-padding-x: 16px;
  --input-padding-y: 12px;

  position: relative;
  display: flex;
  align-items: center;
}

.container_disabled {
  opacity: 0.7;
}

.input_field {
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid var(--grey-400);
  border-radius: 9px;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 80px;
  padding-top: var(--input-padding-y);
  padding-bottom: var(--input-padding-y);
  padding-left: var(--input-padding-x);
  padding-right: var(--input-padding-x);
}

.input_big {
  --input-padding-x: 12px;
  --input-padding-y: 14px;
  font-size: 1.125rem;
}

.inputLessInitialPadding {
  --input-padding-x: 12px;
  --input-padding-y: 8px;
}

.input_field:focus {
  outline: 2px solid var(--primary-normal);
}

.input_field::placeholder {
  color: var(--grey-600);
  opacity: 1;
}

.inputElement_icon {
  position: absolute;
  left: var(--input-padding-x);
  width: var(--input-icon-size);
  height: var(--input-icon-size);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--grey-500);
}

.withIcon {
  font-size: 1.125rem;
  padding-left: calc(var(--input-padding-x) + var(--input-icon-size) + var(--input-gap));
}

.inputElement_icon_withValue {
  color: var(--primary-normal);
}

.inputElement_charCount {
  text-align: end;
  position: absolute;
  right: var(--input-padding-x);
  width: var(--input-char-count-width);
  font-size: 0.75rem;
  color: var(--grey-600);
}

.withCharCount {
  padding-right: calc(var(--input-padding-x) + var(--input-char-count-width) + var(--input-gap));
}

.inputElement_button {
  position: absolute;
  right: var(--input-padding-x);
  width: var(--input-button-size);
  height: var(--input-button-size);
}

.inputElement_button_withCharCount {
  right: calc(var(--input-padding-x) + var(--input-char-count-width) + var(--input-gap));
}

.withButton {
  font-size: 1.125rem;
  min-height: calc(var(--input-button-size) + var(--input-padding-y) * 2);
  padding-right: calc(var(--input-padding-x) + var(--input-button-size) + var(--input-gap));
}

.withButton.withCharCount {
  padding-right: calc(
    var(--input-padding-x) + var(--input-char-count-width) + var(--input-gap) + var(--input-button-size) +
      var(--input-gap)
  );
}

.withBlueDetail:focus {
  outline: 2px solid var(--blue-100);
}
