.settingsContainer {
  margin-bottom: 24px;
}

.toggleSwitchContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.toggleSwitchLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--grey-900);
}

.toggleSwitchLabelEnabled {
  font-weight: 700;
}

.toggleSwitchDescription {
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: var(--grey-700);
}

.dropdownContainer {
  margin: 32px 0;
  display: flex;
  gap: 16px;
}

.dropdownField {
  flex: 1;
}

.dropdownLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 0.83rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  color: var(--grey-900);
  margin-bottom: 8px;
}

.dropdownLabelIcon {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  color: var(--primary-normal);
  margin-right: 8px;
}

.sectionContainer {
  margin-bottom: 24px;
}

.sectionTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 0.83rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  color: var(--grey-700);
  padding-bottom: 8px;
}

.sectionDescription {
  margin-top: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: var(--grey-900);
}

.inputContainer {
  margin: 16px 0 24px 0;
}

.sendSummarySelectWrapper {
  max-width: 30%;
  margin-left: calc(3.25rem + 16px + 8px);
}

@media (max-width: 600px) {
  .dropdownContainer {
    flex-direction: column;
  }
  .sendSummarySelectWrapper {
    max-width: unset;
  }
}
