.sectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sectionTitle {
  font-size: 2rem;
  font-weight: 700;
}

.sectionCardContainer {
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.divider {
  height: 0.0625rem;
  width: 100%;
  background-color: var(--grey-200);
  margin: 1rem 0;
}
