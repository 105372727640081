.libraryTasksContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: var(--tlm-padding);
}

.libraryTasksCount {
  display: flex;
  gap: 8px;
}

.searchTooltipIcon {
  color: var(--grey-500);
  cursor: pointer;
}

.searchTooltip {
  max-width: 150px;
}

.searchList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
}
