.playerAccountModal {
  --papam-header-height: 4rem;
  --papam-footer-height: 4.875rem;
  --papam-padding: 16px;
  --papam-width: 780px;
  --papam-height: 6800px;

  min-width: var(--papam-width);
  max-width: var(--papam-width);
  max-height: var(--papam-height);

  display: flex;
  flex-direction: column;

  background-color: white;
  border-radius: 5px;
  border: solid 1px var(--grey-600);
  overflow: hidden;
}

.playersAddByPlayerAccountHeader,
.playersAddByPlayerAccountFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--papam-padding);
}

.playersAddByPlayerAccountHeader {
  height: var(--pamm-header-height);
}

.playersAddByPlayerAccountFooter {
  height: var(--lm-footer-height);
  background-color: var(--grey-50);
}

.playersAddByPlayerAccountHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.playersAddByPlayerAccountHeaderTitle > span:first-of-type {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.playersAddByPlayerAccountHeaderTitle > span:last-of-type {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.infoText {
  border-top: 1px solid var(--grey-400);
  color: var(--grey-700);
  line-height: 24px;
  padding: 16px;
}

.contentArea {
  border-bottom: 1px solid var(--grey-400);
  min-height: 400px;
  max-height: 400px;
  padding: var(--papam-padding);
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 16px;
}

.contentColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 33%;
  position: relative;
}

.contentTitle {
  font-size: 1rem;
  font-weight: bold;
}

.contentList {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  border-radius: 15px;
  border: solid 1px var(--grey-300);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  background-color: white;
}

.activeTagsArea {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  background-color: var(--grey-100);
  border-radius: 15px;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
}

.maxPlayerValidationText {
  display: flex;
  font-size: 14px;
  color: var(--grey-700);
}

.searchAreaContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.searchInputContainer {
  display: flex;
  flex: 1;
  position: relative;
  min-height: 2.5rem;
  outline: 0 !important;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 9px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 1rem;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  cursor: text;
  max-width: 100%;
}

.plainInputField {
  border: none;
  outline: none;
  min-width: 0; /*Firefox*/
  max-width: 165px;
  height: 32px;
}

.clearInpuFieldX {
  cursor: pointer;
  display: flex;
}

.clearInpuFieldX svg {
  height: 12px;
}

.searchPeopleHint {
  color: var(--grey-700);
  font-size: 13.3px;
  align-self: center;
  margin: 2px 0px;
}

.showAllPlayerAccountsOption {
  color: var(--primary-normal);
  font-size: 13.3px;
  align-self: center;
  margin: 2px 0px;
  cursor: pointer;
  margin-bottom: 16px;
}

.instantTagsTitle {
  color: var(--grey-700);
  font-size: 1rem;
  margin-bottom: 4px;
  font-weight: bold;
}

.noTagsTitle {
  font-size: 13.3px;
  align-self: center;
  margin: 2px 0px;
}

.prefixIcon {
  margin-left: 8px;
  color: var(--primary-normal);
  display: flex;
}

.activeTagsTitleRow {
  font-size: 13.33px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.activeTagsTitle {
  font-weight: bold;
}

.activeTagsClearAll {
  color: var(--primary-normal);
  cursor: pointer;
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.resultsAreaContainer {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  border-radius: 15px;
  border: solid 1px var(--grey-300);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  margin-top: 8px;
  width: calc(var(--papam-width) - 2 * var(--papam-padding));
  height: 360px;
  background-color: white;
}

.instantResultsList {
  position: absolute;
  max-width: 239px;
  top: 60px;
  border-radius: 8px;
  z-index: 1;
  height: initial;
  gap: 4px;
  padding-bottom: 16px;
}

.toBeAddedList .resultPlayerAccount {
  font-weight: bold;
}

.subResults {
  background-color: white;
  position: absolute;
  max-height: 384px;
  margin-top: 5px;
  padding: 16px;
  border-radius: 15px;
  border: solid 1px var(--grey-300);
  overflow-y: auto;
  z-index: 1;
  left: 230px;
}

.subResultTitle {
  font-size: 13.3px;
  font-weight: bold;
  display: flex;
  color: var(--primary-normal);
  cursor: pointer;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
}

.subResultTitle span {
  transform: rotate(90deg);
}

.titleLine {
  color: var(--grey-700);
  font-weight: bold;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.seeAll {
  color: var(--primary-normal);
  cursor: pointer;
}

.separator {
  border-bottom: 1px solid var(--grey-200);
  margin-bottom: 8px;
}

.separatorMoreSpace {
  margin-top: 8px;
}

.startingTaskArea {
  max-height: 108px;
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
}
