.filtersContainer {
  width: 100%;
  padding: 10px;
  background-color: var(--grey-200);
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
}

.toggleInput {
  padding: 0;
  color: var(--grey-900);
}

.filtersContainer > * {
  width: 100%;
}

.filtersTitle {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 24px;
  letter-spacing: 2%;
  color: var(--grey-600);
}

.filtersTitleActive {
  color: var(--primary-normal);
}

@media (min-width: 750px) {
  .filtersContainer > * {
    width: calc((100% - 8px) / 2);
  }
}

@media (min-width: 1120px) {
  .filtersContainer > * {
    width: calc((100% - 16px) / 3);
  }
}
