.toolTipArea {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 20px;
  height: 20px;
  z-index: 999999;
  cursor: pointer;
}

.toolTipNoEvents {
  cursor: move;
  pointer-events: none;
}

.toolTipAreaLarge {
  width: 36px;
  height: 36px;
}
