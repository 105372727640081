.outerContainer {
  --scoreboard-header-height: 3.2rem;
  --scoreboard-fixed-height: 620px;

  position: absolute;
  bottom: 0;
  width: var(--sidebar-1-width);
  height: min(var(--scoreboard-fixed-height), var(--sidebar-height));
  overflow: hidden;

  background-color: var(--grey-100);
  border-radius: 8px;
}

.header {
  height: var(--scoreboard-header-height);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  background: var(--gradient-secondary-normal);
}

.closeButton {
  color: white;
}
.closeButton:hover {
  color: var(--grey-400);
}

.middleContainer {
  height: calc(100% - var(--scoreboard-header-height));
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 42px 24px 42px;
}

.scoreboardContainer {
  background: var(--gradient-secondary-normal);
  border-radius: 9px;
  min-height: 100%;
  width: 100%;
  padding: 64px 10px 10px 10px;
  min-width: calc(var(--sidebar-1-width) - 42px - 42px);
}

.podiumContainer {
  position: relative;
  width: 100%;
  background-image: url('../../../../../../../assets/icons/podium_icon.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  min-height: 112px;
  overflow: visible;
}

.podiumRow {
  width: 100%;
  position: absolute;
  bottom: 14px;
  display: flex;
  align-items: flex-end;
}

.podiumColumn {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.podiumColumn:first-of-type {
  left: 0;
  width: 36%;
}

.podiumColumn:nth-of-type(2) {
  width: 100%;
}

.podiumColumn:last-of-type {
  right: 0;
  width: 36%;
}

.topThreeName {
  color: #fff;
  font-size: 0.6rem;
  line-height: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.topThreePosition {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 99px;
  background-color: white;
  color: var(--grey-900);
  font-size: 0.75rem;
  box-shadow: 0px 5.23077px 10.46154px 0px rgba(0, 0, 0, 0.1);
}

.podiumColumn:first-of-type .topThreePosition {
  margin: 18px 0 28px 0;
}

.podiumColumn:nth-of-type(2) .topThreePosition {
  margin: 36px 0 32px 0;
  font-size: 1rem;
  width: 2.375rem;
  height: 2.375rem;
}

.podiumColumn:last-of-type .topThreePosition {
  margin: 23px 0 24px 0;
}

.topThreePoints {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
}

.playersListContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: -6px;
  isolation: isolate;
  padding: 0 16px 0 16px;
}

.listItemContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: white;
  border-radius: 9px;
  padding: 10px 12px;
  box-shadow: 0px 1.613px 6.453px 0px rgba(0, 0, 0, 0.25);
}

.positionStyle {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: var(--primary-normal);
}

.nameStyle {
  flex: 1;
  text-align: center;
  font-size: 0.67rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}

.score {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-normal);
  gap: 2px;
}

.scoreIcon {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointsStyle {
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: 0.05rem;
}

.emptyCentredContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-700);
}
