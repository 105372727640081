.tableContainer {
  padding: 32px;
  min-height: 12.65rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2.4rem;
}

.container {
  padding: 32px;
  min-height: 12.65rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
}

.viewAllLicenseHolders {
  align-self: center;
  margin-top: 20px;
}

.viewPlayersMessage {
  color: var(--grey-700);
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.licenceUserSection {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.licenceUserSectionTitle {
  color: black;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  gap: 48px;
  align-self: stretch;
}

.licenceGraphSection {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;

  /* Seppo Big Shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.circleProgressSection {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6.5vw;
  align-self: stretch;
}

.circleProgressContainer {
  width: 25vw;
  max-width: 400px;
  height: 25vw;
  max-height: 400px;
}

.licenceCircleProgressMainText {
  color: var(--Grey-700, #6f6f6f);
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 100% */
  text-transform: uppercase;
}

.licenceCircleProgressSecondaryText {
  color: var(--Grey-700, #6f6f6f);
  font-family: Montserrat;
  font-size: 11.11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 144.014% */
  text-transform: uppercase;
}

.typeTextContainer {
  width: calc(25vw - 5vw - 48px);
  max-width: 360px;
}

.typeCircularInfoRow {
  display: flex;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
}

.typeCircleProgressSecondaryText {
  color: var(--Grey-700, #6f6f6f);
  font-family: Montserrat;
  font-size: 11.11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 144.014% */
  text-transform: uppercase;
}

.firstColor {
  width: 24px;
  min-width: 24px;
  height: 24px;
  max-height: 24px;
  border-radius: 3px;
  background: var(--Pink-50, #e8a3c4);
  flex: 1 1 24px;
}

.secondColor {
  width: 24px;
  min-width: 24px;
  height: 24px;
  max-height: 24px;
  border-radius: 3px;
  background: var(--Pink-100, #d64d8a);
  flex: 1 1 24px;
}
