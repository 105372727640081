.boardsOverviewModal {
  --bo-header-height: 4rem;
  --bo-footer-height: 4.875rem;
  --bo-width: calc(100vw - 16px);
  --bo-height: calc(100vh - 32px);
  --bo-padding: 16px;

  min-width: var(--bo-width);
  max-width: var(--bo-width);
  max-height: var(--bo-height);

  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .boardsOverviewModal {
    --bo-width: min(90vw, 767px);
  }
}

.infoText {
  color: var(--grey-700);
  line-height: 24px;
}

.boardsOverviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--bo-padding);
}

.boardsOverviewHeader {
  height: var(--bo-header-height);
}

.boardsOverviewHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.boardBackground {
  padding: 4px !important;
  background-color: var(--grey-100);
}

.boardBackgroundSelected {
  outline: 2px;
  outline-color: var(--primary-normal);
}

.boardsOverviewHeaderTitle > span:first-of-type {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.boardsOverviewHeaderTitle > span:last-of-type {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.boardsOverviewMainSection {
  height: calc(var(--bo-height) - var(--bo-header-height) - var(--bo-footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 24px 16px;
}

.boardsArea {
  height: 450px;
  align-self: stretch;
  border-radius: 9px;
  border: 1.5px solid var(--Grey-400, #d4d4d4);
}
