.defineAnswersInfo {
  margin: 8px;
}

.defineAnswersInfo p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--grey-700);
  margin-top: 8px;
}

.noMissingWordsContainer {
  padding: 40px 0;
  color: var(--grey-700);
  text-align: center;
}

.inputContainer {
  flex: 1;
}

.inputContainer input {
  flex: 1;
}
