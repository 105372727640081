.backButton {
  border: none;
  cursor: pointer;
  max-width: fit-content;
  background-color: transparent;
  margin-top: 16px;
  .backButtonContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    svg {
      border: none;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-normal) !important;
      cursor: pointer;
      background-color: transparent;
    }
    .backButtonText {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.4375rem;
      letter-spacing: 0.02rem;
    }
  }
}

.backButton:hover {
  svg {
    color: var(--welcome-block-light) !important;
  }
  .backButtonText {
    color: var(--grey-600);
  }
}

.backButton:disabled svg {
  color: var(--gray-700) !important;
  cursor: not-allowed;
}
