.playerSummaryContainer {
  display: flex;
  align-items: center;
  border-radius: 9px;
  background-color: var(--grey-50);
  padding: 8px;
  gap: 10px;
  margin: 0;
  border: 1px solid transparent;
  cursor: pointer;
}

.playerSummaryContainer:not(.playerSummaryContainer_selected):hover {
  border-color: var(--grey-500);
}

.playerSummaryPosition {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-900);
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  min-width: 2rem;
  padding: 0 4px 0 8px;
}

.playerInitials {
  display: flex;
  padding: 8px;
  border-radius: 1.25rem;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  background: var(--gradient-primary-normal);
}

.playerSummaryNameAndDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.playerSummaryName {
  flex: 1;
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  word-break: break-word;
}

.playerSummaryContainer:not(.playerSummaryContainer_selected):hover .playerSummaryName {
  color: var(--grey-900);
}

.playerSummaryDetails {
  display: flex;
  align-items: center;
  gap: 8px;
}

.playerSummaryPinIcon {
  color: var(--primary-normal);
  display: flex;
  align-items: center;
}

.details {
  color: var(--grey-600);
}

.playerSummaryContainer_review .playerInitials {
  background: var(--gradient-secondary-normal);
}

.playerSummaryContainer_compact {
  gap: 8px;
  padding: 8px 12px;
}

.playerSummaryContainer_compact .playerSummaryPosition {
  font-size: 0.75rem;
  line-height: 1rem;
  min-width: 1.5rem;
  padding: 0 2px 0 4px;
}

.playerSummaryContainer_compact .playerInitials {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.5625rem;
  line-height: 0.75rem;
}

.playerSummaryContainer_compact .playerSummaryNameAndDetails {
  flex-direction: row;
}

.playerSummaryContainer_compact .playerSummaryName {
  font-size: 0.75rem;
  line-height: 1rem;
}

.playerSummaryContainer_compact .playerSummaryDetails {
  gap: 4px;
}

.compactIconAndPoints {
  display: flex;
  align-items: center;
  gap: 4px;
}

.compactPointsIcon {
  color: var(--secondary-normal);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.compactPoints {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.playerSummaryContainer_selected {
  background-color: var(--primary-dark);
}

.playerSummaryContainer_selected .playerSummaryName,
.playerSummaryContainer_selected .playerSummaryPosition,
.playerSummaryContainer_selected .details,
.playerSummaryContainer_selected .compactPoints,
.playerSummaryContainer_selected .compactPointsIcon {
  color: white;
}

.playerSummaryContainer_selected .playerInitials {
  background-color: white;
  color: var(--primary-normal);
}

.playerSummaryContainer_selected .playerSummaryPinIcon {
  color: var(--primary-light);
}

.badgesContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.tooltipBadgesList {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tooltipBadgesList > span {
  padding-bottom: 4px;
}
