.errorWrapper {
  max-width: fit-content;
}

.pointsError {
  top: calc(100% - 10px);
}

.pointsWidget_big {
  --widget-height: 2.25rem;
  --widget-icon-size: 1.5rem;
}
.pointsWidget_medium {
  --widget-height: 1.5rem;
  --widget-icon-size: 1.1rem;
}
.pointsWidget_small {
  --widget-height: 1.25rem;
  --widget-icon-size: 0.85rem;
}

.pointsInputContainer {
  background-color: var(--secondary-normal);
  border: 2px solid var(--secondary-normal);
  border-radius: 99px;
  display: flex;
  overflow: hidden;
  align-items: center;
  height: var(--widget-height);
}

.pointsInputContainer:focus-within {
  outline: 2px solid var(--primary-normal);
}

.pointsIcon {
  color: white;
  display: flex;
  padding: 0 6px;
  align-items: center;
  font-size: var(--widget-icon-size);
  height: var(--widget-height);
}

.pointsInput {
  border: none;
  padding: 0 8px;
  color: var(--secondary-normal);
  font-weight: 700;
  border-radius: 0 99px 99px 0;
  height: var(--widget-height);
  outline: none;
  width: 120px;
}

.pointsInput::placeholder {
  color: var(--grey-600);
  opacity: 1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
}

.pointsInputContainer_disabled {
  background-color: var(--grey-400);
  border-color: var(--grey-400);
}

.pointsInputContainer_disabled .pointsInput {
  background-color: white;
  color: var(--grey-400);
}

.pointsInputContainer_disabled .pointsInput::placeholder {
  color: var(--grey-400);
}

.pointsPreviewContainer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--secondary-normal);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
  border: 2px solid white;
  border-radius: 99px;
  display: flex;
  gap: 8px;
  max-width: fit-content;
  align-items: center;
  color: white;
  padding: 6px 20px 6px 12px;
  height: var(--widget-height);
}

.pointsPreviewIcon {
  display: flex;
  align-items: center;
  font-size: var(--widget-icon-size);
}

.pointsPreviewText {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}

.pointsPreviewContainer_noPoints {
  background-color: white;
  border-color: var(--grey-500);
  color: var(--grey-600);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
}

.pointsPreviewContainer_addPoints {
  background-color: white;
  border-color: var(--secondary-normal);
  color: var(--blue-100);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
}

.pointsPreviewContainer_noPoints .pointsPreviewText,
.pointsPreviewContainer_addPoints .pointsPreviewText {
  text-shadow: none;
}

.pointsInputContainer_preview {
  max-width: fit-content;
}
