.taskPreviewContainer {
  border-radius: 9px;
  overflow: hidden;
  --tp-padding-x: 12px;
  --tp-padding-y: 12px;
}

.taskPreviewContainer_big {
  --tp-padding-x: 40px;
  --tp-padding-y: 32px;
}

.taskPreviewContainer_shadowExpanded {
  box-shadow: 0px 8px 16px 0px #0000001a;
}

.taskPreviewHeader {
  border-radius: 13px;
  background-color: var(--grey-50);
  display: flex;
  gap: 10px;
  position: relative;
  z-index: 1;
  padding: 0 12px;
  align-items: center;
}

.taskPreviewName {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.selectableTaskPreviewCheckboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  border: 2px solid var(--grey-500);
  border-radius: 6px;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  position: relative;
  outline-offset: 2px;
}

.checkbox:checked {
  background-color: var(--primary-normal);
  border-color: white;
  outline-offset: 0;
  outline: 2px solid var(--primary-normal);
}

.checkbox:focus-visible {
  outline: 2px solid -moz-mac-focusring;
  outline: 2px solid -webkit-focus-ring-color;
}

.taskPreviewHeaderContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  padding: 10px 0;
}

.toggleCollapseButton {
  background-color: transparent;
  border: none;
  border-radius: 99px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-600);
  cursor: pointer;
}

.toggleCollapseButton:hover {
  color: var(--grey-400);
}

.toggleCollapseButton.iconExpanded {
  transform: rotate(180deg);
}

.taskPreviewExpandedContainer {
  padding: calc(var(--tp-padding-y) + 12px) var(--tp-padding-x) var(--tp-padding-y) var(--tp-padding-x);
  margin-top: -12px;
  background: var(--grey-200);
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtaskContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subtaskDescription {
  font-weight: 500;
  max-width: 460px;
}

.subtaskDescription img {
  max-width: 100%;
  height: auto;
}

.subtaskDescription video {
  max-width: 100%;
}

.subtasksDivider {
  height: 1px;
  background-color: var(--grey-500);
  width: 100%;
}

.taskIconContainer {
  color: var(--primary-normal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.taskIconContainer > svg {
  width: 2rem;
  height: 2rem;
}
