.tabGroup {
  display: flex;
}

.tab {
  height: 5.25rem;
  position: relative;
  display: flex;
}

.originalRadio:checked + .tabContent {
  box-shadow: inset 0px -4px 0px var(--primary-normal);
  background: #ffffff;
}

.originalRadio {
  margin: 0;
  appearance: none;
}

.originalRadio:focus-visible + .tabContent {
  outline: 2px solid -moz-mac-focusring;
  outline: 2px solid -webkit-focus-ring-color;
}

.tabContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 100px;
  cursor: pointer;
  padding: 0 8px;
}

@media (min-width: 1440px) {
  .tabGroup {
    margin: 0 20px;
  }
  .tabContent {
    min-width: 128px;
    padding: 0 24px;
  }
}

.title {
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: var(--grey-600);
  text-transform: uppercase;
}

.label {
  display: flex;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: var(--grey-600);
}
