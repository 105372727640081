body {
  /* NOTE: primary and secondary colors can be changed by user settings */
  /* custom color variants are calculated from a single value in src/util/colors.ts */
  /* use primary & secondary variables instead of assigning hex values directly */

  /* PRIMARY COLOR */
  --primary-background: #ffebf5;
  --primary-light: #ffd6eb;
  --primary-normal: #cc3e76;
  --primary-dark: #9f385d;

  --welcome-block-dark: #e83e8c;
  --welcome-block-light: #f49fc6;

  --gradient-primary-background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--primary-background);
  --gradient-primary-light: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%),
    var(--primary-light);
  --gradient-primary-normal: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--primary-normal);
  --gradient-primary-dark: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%),
    var(--primary-dark);

  --gradient-primary-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), var(--primary-normal);
  --gradient-primary-active: linear-gradient(0deg, rgba(51, 51, 51, 0.2), rgba(51, 51, 51, 0.2)), var(--primary-dark);

  --gradient-primary-background-horizontal: linear-gradient(
      140.96deg,
      rgba(255, 255, 255, 0.5) 3.68%,
      rgba(255, 255, 255, 0) 102.13%
    ),
    var(--primary-background);

  /* SECONDARY COLOR */
  --secondary-background: #9ed6ff;
  --secondary-light: #38acff;
  --secondary-normal: #007acf;
  --secondary-dark: #003e6b;

  --gradient-secondary-normal: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--secondary-normal);

  /* GREY VARIANTS */
  --grey-50: #f9f9f9;
  --grey-100: #f2f2f2;
  --grey-150: #eeeeee;
  --grey-200: #ebebeb;
  --grey-250: #e5e5e5;
  --grey-300: #e0e0e0;
  --grey-400: #d4d4d4;
  --grey-500: #c7c7c7;
  --grey-600: #a3a3a3;
  --grey-700: #6f6f6f;
  --grey-800: #6f6f6f;
  --grey-900: #333333;

  /* BLUE VARIANTS */
  --blue-50: #afbdeb;
  --blue-100: #607dd9;
  --blue-800: #271078;

  /* PINK VARIANTS */
  --pink-100: #d64d8a;
  --pink-75: #de75a7;
  --pink-50: #e8a3c4;

  /* DARK BLUE VARIANTS */
  --dark-blue-100: #2a2448;

  /* GREY GRADIENTS */
  --grey-gradient: linear-gradient(0deg, rgba(100, 100, 100, 0.1), rgba(100, 100, 100, 0.3) 90%);

  /* WHITE GRADIENTS */
  --white-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;

  /* GREEN VARIANTS */
  --reporting-green-light: #e8f3ef;
  --reporting-green-normal: #018831;
  --green-100: #009f3c;
  --green-25: #b1d6ca;
  --green-50: #69ae97;
  --green-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #009f3c;

  /* CYAN */
  --cyan-800: #008067;

  /* BEIGE VARIANTS */
  --beige-50: #f8f1ed;

  /* RED/ERROR VARIANTS */
  --error-normal: #e80000;
  --gradient-error-normal: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--error-normal);
  --validation-error: #b95843;
  --validation-error-light: #daaaa0;
  --red-800: #a52718;

  /* YELLOW/ERROR VARIANTS */
  --yellow-100: #f5c172;
  --validation-error-secondary: #f2c308;

  /* MODAL BACKDROP */
  --backdrop-500: rgba(0, 0, 0, 0.5);
}
