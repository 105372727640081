.boardsOverviewButton {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 93px;
  height: 84px;
  border-radius: 48px 0px 0px 48px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-normal);
  cursor: pointer;
  background-color: white;
}

.boardsOverviewButton svg {
  height: 46px;
  width: 46px;
}
