.cardContainer {
  border: 4px solid var(--primary-normal);
  border-radius: 19px;
  min-width: clamp(310px, 80vw, 360px);
  max-width: clamp(310px, 80vw, 360px);
  min-height: 429px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.cardContainerBig {
  min-width: clamp(600px, 80vw, 650px);
  max-width: clamp(600px, 80vw, 650px);
}

.cardContainerIgnoreResponsiveBig {
  min-width: clamp(600px, 80vw, 650px) !important;
  max-width: clamp(600px, 80vw, 650px) !important;
}

.cardContainerTwoCards {
  min-width: clamp(360px, 40vw, 410px) !important;
  max-width: clamp(360px, 40vw, 410px) !important;
}

.cardContainer > div > h1 {
  margin-bottom: 20px;
}

.iconRow {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.clickableRow {
  cursor: pointer;
}

.icon {
  font-size: 2.8125rem;
  color: var(--primary-normal);
}

.smallerIcon {
  font-size: 1.5rem;
}

.removeMinHeight {
  min-height: initial;
  height: fit-content;
}

.twoBoxesLoginLayoutTitle {
  margin-bottom: 12px !important;
}

@media (max-width: 1300px) {
  .cardContainerBig {
    min-width: clamp(500px, 80vw, 550px);
    max-width: clamp(500px, 80vw, 550px);
  }
  .cardContainer {
    min-height: initial;
  }
}

@media (max-width: 1100px) {
  .cardContainerBig {
    min-width: clamp(400px, 80vw, 450px);
    max-width: clamp(400px, 80vw, 450px);
  }
}

@media (max-width: 970px) {
  .cardContainerTwoCards {
    min-width: clamp(500px, 80vw, 550px) !important;
    max-width: clamp(500px, 80vw, 550px) !important;
  }
}

@media (max-width: 950px) {
  .cardContainerBig {
    min-width: clamp(310px, 80vw, 360px);
    max-width: clamp(310px, 80vw, 360px);
  }
}

@media (max-width: 768px) {
  .cardContainer {
    padding: 24px;
    min-height: unset;
    gap: 12px;
  }
  .cardContainerBig {
    min-width: clamp(310px, 80vw, 360px);
    max-width: clamp(310px, 80vw, 360px);
  }
  .cardContainerIgnoreResponsiveBig {
    min-width: clamp(310px, 80vw, 360px) !important;
    max-width: clamp(310px, 80vw, 360px) !important;
  }
  .cardContainerTwoCards {
    min-width: clamp(310px, 80vw, 360px) !important;
    max-width: clamp(310px, 80vw, 360px) !important;
  }
  .cardContainer > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .icon {
    font-size: 32px;
    max-height: 32px;
  }
  .cardContainer > div > h1 {
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin-bottom: 14px;
  }
  .cardContainer > p {
    font-size: 1rem;
  }

  .cardContainerSmallDevice {
    border: unset;
    background-color: var(--grey-200);
  }
  .titleSmalDevice {
    color: var(--grey-700);
  }
  .iconSmallDevice {
    color: var(--grey-500);
  }

  .twoBoxesLoginLayoutTitle {
    margin-bottom: 0 !important;
  }
}
