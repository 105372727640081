.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: var(--grey-50);
  border-radius: 5px;
  padding: 10px;
}

.loaderContainer span {
  font-size: 0.75rem;
  color: var(--grey-600);
}
