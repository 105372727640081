.notificationsWrapper {
  position: fixed;
  top: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  z-index: 601;
  max-height: 90vh;
  overflow-y: auto;
  width: min(100%, 32rem);
}

.notificationsWrapper_withContent {
  padding: 12px;
}

.notificationItem {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  box-shadow: 0px 4px 8px 0px #00000040;
}

.iconAndTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 16px;
  flex: 1;
  margin-right: 16px;
}

.notificationItemIcon {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5.230769634246826px 10.461539268493652px 0px #0000001a;
}

.notificationItemIcon_Success {
  background: var(--green-gradient);
  font-size: 1.75rem;
}

/* NOTE: not in design atm, update later if needed */
.notificationItemIcon_Info {
  background: var(--gradient-primary-normal);
  font-size: 1.75rem;
}

/* NOTE: not in design atm, update later if needed */
.notificationItemIcon_Warning {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), orange;
  font-size: 1.5rem;
  padding-bottom: 4px;
}

.notificationItemIcon_Error {
  background: var(--gradient-error-normal);
  font-size: 1.5rem;
  padding-bottom: 4px;
}

.notificationItemIcon_Position {
  background: var(--yellow-100);
  font-size: 1.5rem;
  color: var(--grey-900);
}

.notificationItemIcon_DoorsAdded {
  background: var(--gradient-primary-normal);
  font-size: 1.5rem;
  color: white;
}

.textContainer {
  flex: 1;
}

.textContainer > div:first-of-type {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: var(--grey-600);
  margin-bottom: 4px;
  text-transform: uppercase;
}

.textContainer > div:nth-child(2) {
  color: var(--grey-900);
}

.actionsContainer {
  padding: 12px;
  border-left: 1px solid var(--grey-200);
  display: flex;
  align-items: center;
}

.actionButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: var(--primary-normal);
  font-size: 1.125rem;
  font-weight: 700;
}
.actionButton:hover {
  color: var(--primary-light);
}
.actionButton:active {
  color: var(--primary-dark);
}

.slideIn {
  animation-duration: 0.35s;
  animation-name: slideIn;
}

@keyframes slideIn {
  from {
    margin-top: -16px;
  }
  to {
    margin-top: 0;
  }
}

/* MIRRORING */
.actionsContainerMirroring {
  border-left: unset;
  border-right: 1px solid var(--grey-200);
}
.iconAndTextContainerMirroring {
  margin-left: 16px;
  margin-right: unset;
}
