.matchPairsCorrectRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-inline-end: 0.5rem;
  gap: 4px;
}

.matchPairsContainer {
  position: relative;
  border-radius: 9px;
  padding: 7px 12px;
  color: white;
}

.matchPairsSuccess {
  background-color: var(--green-25);
}

.matchPairsWarning {
  background-color: var(--grey-200);
}

.matchPairsError {
  background-color: var(--validation-error-light);
}

.matchPairsIncorrectContent {
  display: flex;
  gap: 1rem;
}

.matchPairsIncorrectColumn {
  flex: 1;
  border-radius: 9px;
  padding: 4px 1rem 4px 4px;
  position: relative;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
}

.matchPairsCell {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  flex: 1;
}

.matchPairsCell span {
  max-height: 64px;
  overflow: hidden;
}

.matchPairsCell.imageOnly {
  padding: 0;
  background-color: transparent;
}

.matchPairsCell > img {
  border-radius: 5px;
}

.matchPairsCell > span {
  text-align: center;
}

.matchPairsIncorrectColumn .matchPairsCell {
  flex: 1;
}

.textOnly {
  color: var(--grey-900);
  padding: 12px;
}

.smallMatchPairsCell.textOnly {
  padding: 4px;
}

.imageAndText {
  color: var(--grey-900);
}

.imageAndText span {
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
  text-overflow: ellipsis;
}

.smallMatchPairsCell.imageAndText span {
  max-width: 55px;
}

.matchPairsNoAnswer {
  background-color: transparent;
}

.correctAnswersContainer {
  background-color: white;
}

.correctAnswersContainerTitle span {
  font-size: var(--tiny-font);
  line-height: 22px;
}

.divider {
  min-height: 2px;
  background-color: var(--grey-200);
  margin: 6px 0;
}

.answerOptionsTable {
  display: table;
  border-spacing: 0 8px;
}

.answerOptionRow {
  display: table-row;
}

.answerOptionLeftColumn {
  display: table-cell;
  width: 100%;
}

.answerOptionRightColumn {
  display: table-cell;
  padding-inline-start: 16px;
  vertical-align: middle;
}

.answerOptionRightColumn_lessPadding {
  padding-inline-start: 8px;
}

.timeExceededFadeout {
  opacity: 0.5;
}
