.dragCancelContainer {
  border: 3px dashed var(--grey-600);
  align-items: center;
  box-shadow: none;
  justify-content: center;
  background-color: rgb(249, 249, 249, 0.6);
  color: var(--grey-700);
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5rem;
  z-index: unset;
}
