.popupActionMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popupActionMenuItem {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: var(--grey-900);
  white-space: nowrap;
  width: 100%;
  padding: 8px 12px;
}

.popupActionMenuItem_disabled {
  background-color: var(--grey-100);
  color: var(--grey-700);
  cursor: auto;
}

.popupActionMenuItem:not(.popupActionMenuItem_disabled):hover {
  transition: all 100ms ease-in-out;
  background-color: var(--grey-200);
}

.popupActionMenuItem > .popupActionMenuItemIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: var(--grey-600);
}

.menuChangeItemTextContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: left;
  flex: 1;
}

.menuChangeItemSubtext {
  color: var(--grey-700);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 1rem;
}

.menuItemChevronIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: var(--grey-600);
}
