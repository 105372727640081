.answerStatusIcon {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 99px;
  position: absolute;
  right: -0.7rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.answerStatusIcon_ERROR {
  color: white;
  background-color: var(--error-normal);
  font-size: 0.75rem;
}

.answerStatusIcon_SUCCESS {
  color: white;
  background-color: var(--green-100);
}

.answerStatusIcon_WARNING {
  color: black;
  background-color: var(--yellow-100);
}
