.originalCheckbox {
  appearance: none;
}

.label {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 11px 20px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-900);
  border-radius: 9px;
  border: 1.5px solid var(--grey-500);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* Magic */
.label:has(.originalCheckbox:focus-visible) {
  outline: 2px solid -webkit-focus-ring-color;
}

.labelActive {
  background: border-box var(--gradient-primary-normal);
  color: white;
  border: none;
}

.labelDisabled {
  background: border-box var(--grey-50);
  color: var(--grey-600);
  border: 1.5px solid var(--grey-300);
}

.labelLarge {
  padding: 15px 20px;
}
