.modalContainerSticky {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

.modalContainer {
  width: 80vw;
  max-width: 850px;
  height: fit-content;
  position: relative;
  z-index: 200;
  top: 10px;
  display: flex;
  background-color: var(--grey-200);
  flex-direction: column;
}

.modalTop {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  word-break: break-all;
  overflow-y: auto;
  gap: 1rem;
  border-bottom: 1px solid var(--grey-600);
}

.modalTopText {
  font-weight: 700;
  font-size: 18px;
}

.modalTopIcon {
  svg {
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-normal) !important;
    cursor: pointer;
    background-color: transparent;
  }
}
.modalContent {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  color: var(--grey-900);
  background-color: white;
  gap: 1rem;
}

.closeButton {
  position: absolute;
  top: 6px;
  right: 6px;
}

.modalDescriptionBox {
  background-color: var(--beige-50);
  width: 100%;
  padding: 1.5rem;
  line-height: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.ratingContainer {
  display: flex;
  padding: 16px 0;
  align-items: center;
  gap: 32px;
}

.ratingNumber {
  color: var(--primary-normal);
  font-weight: 800;
  font-size: 40px;
}

.ratingStars {
  display: flex;
  flex-direction: row-reverse;
  gap: 3px;
}

.star {
  font-size: 36px;
  width: 36px;
  height: 36px;
  border: none;
  padding: 0;
}

.starEmpty {
  color: var(--primary-normal);
  fill: var(--primary-normal);
  background: url('../../assets/icons/star_rating_outline.svg') no-repeat center;
  background-size: cover;
  transition-property: background-image;
  transition-duration: 100ms;
  transition-delay: 50ms;
}

.starFilled {
  color: var(--primary-normal);
  fill: var(--primary-normal);
  background: url('../../assets/icons/star_rating.svg') no-repeat center;
  background-size: cover;
  transition-property: background-image;
  transition-duration: 100ms;
  transition-delay: 50ms;
}

.star svg {
}

.ratingStars:hover * {
  background: url('../../assets/icons/star_rating_outline.svg') no-repeat center;
  background-size: cover;
}

.star:hover,
.star:hover ~ button {
  background: url('../../assets/icons/star_rating.svg') no-repeat center;
  background-size: cover;
}

@media (max-width: 900px) {
  .modalTop {
    padding: 1.25rem;
    padding-top: 2rem;
  }
  .modalContent {
    padding: 1.25rem;
  }
  .modalContainer {
    width: 100vw;
  }
}

.ratedStar {
  font-size: 36px;
  width: 36px;
  height: 36px;
  color: var(--primary-normal);
}

.ratedStars {
  display: flex;
  gap: 3px;
}

.allRatingsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.singleRatingContainer {
  background-color: var(--grey-50);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border: solid 1px var(--grey-400);
  width: 100%;
  flex: 1 1 100%;
  border-radius: 16px;
}

.singleRatingStars {
  display: flex;
  gap: 3px;
}

.singleRatingStar {
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: var(--primary-normal);
}

.ratingAmountText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  color: var(--grey-700);
}

.ratingsHeading {
  padding-top: 1rem;
}

.ratingUser {
  font-size: 12px;
  font-style: italic;
  color: var(--grey-700);
  margin-top: -10px;
}

.cardRatingStars {
  --webkit-appearance: none;
  display: flex;
  position: absolute;
  bottom: 4px;
  right: 0;
  padding: 8px;
  border: none;
  background-color: transparent;
  gap: 1px;
  z-index: 10;
  cursor: pointer;
  min-width: 70px;
  justify-content: flex-end;
}

.carStar {
  color: var(--primary-normal);
}

.rateGameFormFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}

.ratingTextArea {
  margin-top: 1rem;
}

.gameRatingFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;
}
