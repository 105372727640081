.facebookButton {
  height: 40px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.facebookButton > img {
  height: 40px;
}

.bodyText {
  font-family: 'montserrat';
  font-size: 1rem;
  line-height: 1rem;
}

.passwordLink {
  color: var(--primary-normal);
}
