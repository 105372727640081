.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  padding: 10px;
}

.container > h1 {
  font-size: 2.75rem;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.fieldContainer {
  flex: 1;
  width: 100%;
}

.fieldContainer label {
  width: 6.5rem;
}

.field {
  flex: 1;
  font-size: 1rem;
  width: 100%;
}

.selectableIcon {
  font-size: 1rem;
}
