@import-normalize;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* NOTE: Twemoji Country Flags is a polyfill for country flag emojis on Windows
  if a browser supports country flag emojis out-of-the-box, it will not use the font
  otherwise, only the country flags will be taken from the font, no other characters
  more info: https://github.com/talkjs/country-flag-emoji-polyfill */
html,
button,
input,
textarea,
::placeholder {
  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#map-root {
  position: fixed;
  left: -10000px;
  top: -10000px;
  visibility: hidden;
  pointer-events: none;
}
* {
  box-sizing: border-box;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.audio-reload-button {
  cursor: pointer;
}

.audio-reload-button > img,
button * {
  pointer-events: none;
}

/* Pannellum 360 renderer does not have option for controls placement, move to right manually */
.pnlm-controls-container {
  position: absolute;
  top: 3px;
  right: 10px;
  left: initial !important; /* 'important' needed to work on deployed version */
  z-index: 1;
}

svg {
  pointer-events: none;
}

.is-exercise {
  z-index: 10 !important;
}

.tox .tox-dialog {
  border-color: var(--primary-normal) !important;
  border-width: 1px !important;
}

.tox-button:not(.tox-button--secondary):not(.tox-button--icon) {
  background-color: var(--primary-normal) !important;
  border-color: var(--primary-normal) !important;
}

.tox-browse-url {
  display: none !important;
}

.tox-button--secondary {
  background-color: #f0f0f0;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border-color: #f0f0f0;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: #222f3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  outline: 0;
  padding: 4px 16px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
}

.tox-button {
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 24px;
  margin: 0;
  outline: 0;
  padding: 4px 16px;
  position: relative;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
}

.dotLoader {
  width: fit-content;
  display: flex;
  gap: 0.75rem;
}

.dotWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
}

.dotOdd {
  display: flex;
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50%;
  background-color: var(--primary-normal) !important;
  animation: animOdd 0.35s ease-out infinite alternate;
}
.dotEven {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50%;
  background-color: var(--primary-normal) !important;
  animation: animEven 0.35s ease-out infinite alternate;
}

@keyframes animOdd {
  0% {
    opacity: 1;
    width: 60%;
    height: 60%;
  }
  25% {
    opacity: 0.9;
    width: 70%;
    height: 70%;
  }
  50% {
    opacity: 0.8;
    width: 80%;
    height: 80%;
  }
  75% {
    opacity: 0.7;
    width: 90%;
    height: 90%;
  }
  100% {
    opacity: 0.6;
    width: 100%;
    height: 100%;
  }
}

@keyframes animEven {
  0% {
    opacity: 0.6;
    width: 100%;
    height: 100%;
  }
  25% {
    opacity: 0.7;
    width: 90%;
    height: 90%;
  }
  50% {
    opacity: 0.8;
    width: 80%;
    height: 80%;
  }
  75% {
    opacity: 0.9;
    width: 70%;
    height: 70%;
  }
  100% {
    opacity: 1;
    width: 60%;
    height: 60%;
  }
}

.mce-content-body [contentEditable='false'][data-mce-selected] {
  cursor: default !important;
}

/* NOTE: keep last in file in order for a debug feature to work */
.hide-outscoped-feature {
  display: none !important;
}
