.container {
  --gsm-header-height: 4.25rem;
  --gsm-footer-height: 5.25rem;
  --gsm-width: calc(100vw - 16px);
  --gsm-height: calc(100vh - 16px);

  min-width: var(--gsm-width);
  max-width: var(--gsm-width);
  min-height: var(--gsm-height);
  max-height: var(--gsm-height);

  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 500;
}

@media (min-width: 768px) {
  .container {
    --gsm-width: min(90vw, 776px);
    --gsm-height: calc(100vh - 80px);
  }
}

.header {
  height: var(--gsm-header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  padding: 16px 24px;
  border-bottom: 1px solid var(--grey-150);
}

.header > span {
  display: inherit;
  gap: 0.625rem;
}

.footer {
  height: var(--gsm-footer-height);
  display: flex;
  justify-content: space-between;
  padding: 18px 16px;
  background-color: var(--grey-50);
  border-top: 1px solid var(--grey-200);
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.content {
  height: calc(var(--task-modal-height) - var(--header-height) - var(--footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding-bottom: 5rem;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 24px 16px;
  gap: 12px;
  background-color: var(--grey-50);
  border-bottom: 1px solid var(--grey-150);
}

.descriptionContainer > p {
  color: var(--grey-700);
}

.linkButton {
  background: none;
  border: none;
  color: var(--primary-normal);
  font-size: 1rem;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}

.dropdownsContainer {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.dropdownsContainer > div {
  width: 50%;
}

.separator {
  border-bottom: 1px solid var(--grey-200);
}

.descriptionFieldContainer {
  flex: 1;
  width: 100%;
}

.fieldContainer label {
  width: 6.5rem;
}

.descriptionField {
  flex: 1;
  display: block;
  width: 100%;
}

.generalSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}
