.collapsibleCardContainer {
  padding: 12px;
  border-radius: 7px;
  border: 1px solid var(--grey-400);
  background: var(--grey-100);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.collapsibleCardHeader {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.collapsibleCardIcon {
  padding-top: 2px;
  color: var(--primary-normal);
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapsibleCardInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.collapsibleCardTitleAndCollapse {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.collapsibleCardTitle {
  color: var(--grey-900);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  flex: 1;
  cursor: pointer;
}

.toggleCollapseButton {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-600);
  cursor: pointer;
}

.toggleCollapseButton.iconExpanded {
  transform: rotate(180deg);
}

.collapsibleSection {
  display: none;
}

.collapsibleSection_visible {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
