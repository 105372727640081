/* shared and variant normal */
.button {
  --gradient-disabled: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--grey-600);
  --gradient-secondary-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), var(--secondary-normal);

  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--gradient-primary-normal);
  padding: 10px 28px;
  font-size: 1rem;
  line-height: 1.4375rem;
  letter-spacing: 0.02rem;
  border: 0;
}
.button:hover {
  background: var(--gradient-primary-hover);
}
.button:active {
  background: var(--gradient-primary-active);
  color: rgba(242, 242, 242, 0.7); /* grey-100 with opacity 0.7 */
}
.button:disabled {
  background: var(--grey-600);
  cursor: not-allowed;
  pointer-events: none;
}

/* big */
.button_big {
  font-weight: 400;
  border-radius: 23px;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.015rem;
  min-width: 270px;
  padding: 20px;
  border-bottom: 3px solid var(--primary-dark);
}
.button_big:disabled {
  border-bottom-color: var(--grey-700);
  background: var(--gradient-disabled);
}

/* secondary */
.button_secondary {
  background: var(--gradient-secondary-normal);
}
.button_secondary:hover {
  background: var(--gradient-secondary-hover);
}
.button_secondary:active {
  background-color: var(--secondary-dark);
}

/* outline */
.button_outline {
  color: var(--primary-normal);
  background-color: white;
  border: 1px solid var(--primary-normal);
}
.button_outline.button_secondary {
  color: var(--secondary-normal);
  border: 1px solid var(--secondary-normal);
}
.button_outline:hover {
  color: var(--grey-900);
  background: var(--gradient-primary-background);
  border-color: transparent;
}
.button_outline.button_secondary:hover {
  background: var(--secondary-light);
  border-color: var(--secondary-light);
}
.button_outline:active {
  color: white;
  background: var(--primary-normal);
}
.button_outline.button_secondary:active {
  color: white;
  background: var(--secondary-normal);
  border-color: var(--secondary-normal);
}
.button_outline:disabled {
  color: white;
  border: 1px solid var(--grey-600);
}

/* tiny */
.button_tiny {
  padding: 8px 20px;
  font-size: var(--small-font);
  line-height: var(--small-font-line-height);
  text-transform: uppercase;
  letter-spacing: 0.066625rem;
}

/* borderless */
.button_borderless {
  color: var(--primary-normal);
  background: transparent;
}
.button_borderless:hover {
  color: var(--primary-normal);
  background: transparent;
}
.button_borderless:active {
  color: var(--primary-normal);
  background: transparent;
}
