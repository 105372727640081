.editorSidebarContainer {
  --sidebar-base-spacing: 16px;
  --sidebar-top-spacing: 98px;
  --sidebar-bottom-spacing: 48px;

  --sidebar-1-width: 420px;
  --sidebar-2-width: 440px;
  --sidebar-3-width: 480px;
  --sidebar-offset-top: calc(5.25rem + 16px);
  --sidebar-height: calc(100vh - var(--sidebar-offset-top) - 32px);

  position: fixed;
  z-index: 3;
  top: var(--sidebar-offset-top);
  height: var(--sidebar-height);
}

.editorSidebarPanelContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: var(--layout-spacing);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  top: var(--sidebar-top-spacing);
  height: var(--sidebar-height);
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.editorSidebarPanelContainer:first-of-type {
  left: 16px;
  min-width: var(--sidebar-1-width);
  max-width: var(--sidebar-1-width);
}

.editorSidebarPanelContainer:nth-of-type(2) {
  left: calc(16px + var(--sidebar-1-width) + 12px);
  min-width: var(--sidebar-2-width);
  max-width: var(--sidebar-2-width);
}

.editorSidebarPanelContainer:nth-of-type(3) {
  left: calc(16px + var(--sidebar-1-width) + 12px + var(--sidebar-2-width) + 12px);
  min-width: var(--sidebar-3-width);
  max-width: var(--sidebar-3-width);
}

@media (max-width: 1396px) {
  .editorSidebarPanelContainer:nth-of-type(3) {
    left: unset;
    right: 16px;
  }
}

@media (max-width: 1144px) {
  .editorSidebarPanelContainer:nth-of-type(2).secondPanelWithThirdOpen {
    left: unset;
    right: 256px;
  }
}

@media (max-width: 750px) {
  .editorSidebarPanelContainer:nth-of-type(2).secondPanelWithThirdOpen {
    right: unset;
    left: 104px;
  }
}

@media (max-width: 904px) {
  .editorSidebarPanelContainer:nth-of-type(2) {
    left: unset;
    right: 16px;
  }
}

.sidebarLoader {
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.sidebarLoader span {
  font-size: 1.25rem;
  color: var(--grey-600);
}

.outerContainer {
  --sidebar-base-width: 400px;

  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  width: var(--sidebar-base-width);
  overflow: hidden;
}

.outerContainer > * {
  color: var(--grey-900);
}

.sidebarTopContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background-color: white;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
  isolation: isolate;
}

.sidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.sidebarHeader > h3 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.sidebarHeader > div {
  display: flex;
  align-items: center;
}

.sidebarTabSwitchContainer {
  display: flex;
  gap: 8px;
}
.sidebarTabSwitchContainer > label {
  display: flex;
  flex: 1;
  justify-content: center;
}

.sidebarMainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
}

.sidebarBottomContainer {
  background-color: white;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: 0 -6px 8px 0 rgba(0, 0, 0, 0.1);
}

.emptyStateInfo {
  color: var(--grey-700);
  text-align: center;
  margin-top: 32px;
  padding: 0 16px;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sidebarCentredButton {
  max-width: fit-content;
  align-self: center;
}
