.container {
  position: relative;
  height: 100%;
}

.EmptyContent {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  color: var(--grey-800);
  text-align: center;
  line-height: 5rem;
}

.noContentButtons {
  display: flex;
  justify-content: center;
}
