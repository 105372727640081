.container {
  display: flex;
  align-items: center;
  border-radius: 9px;
  background-color: var(--grey-100);
  padding: 8px;
  gap: 10px;
  margin: 0;
  border: 1px solid transparent;
  cursor: pointer;
}

.playerInitials {
  display: flex;
  padding: 8px;
  border-radius: 1.25rem;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--blue-100);
}

.playerAndTaskInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.playerName {
  color: var(--grey-700);
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
}

.pointsAndState {
  display: flex;
  align-items: center;
  gap: 8px;
}

.taskStateLabel {
  color: var(--grey-900);
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
}

.stateIcon {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: var(--blue-100);
}

/* HOVER */
.container:not(.container_selected):hover {
  border: 1px solid var(--grey-400);
  background-color: var(--grey-50);
}

.container:not(.container_selected):hover .playerName {
  color: var(--grey-900);
}

/* COMPACT */
.container_compact {
  gap: 4px;
}

.container_compact .playerInitials {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.5625rem;
  line-height: 0.75rem;
}

.container_compact .playerAndTaskInfo {
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.container_compact .playerName {
  font-size: 0.75rem;
  line-height: 1rem;
}

.container_compact .taskStateLabel {
  display: none;
}

.container_compact .stateIcon {
  font-size: 1rem;
}

/* READY */
.container_state_ready .playerName {
  color: var(--grey-900);
}

/* GRADED */
.container_state_graded .playerInitials {
  background-color: var(--cyan-800);
}
.container_state_graded .stateIcon {
  color: var(--green-100);
}

/* TIME FINISHED */
.container_state_time_finished .playerInitials {
  background-color: var(--grey-500);
}
.container_state_time_finished .stateIcon {
  display: none;
}
.container_state_time_finished .taskStateLabel {
  color: var(--grey-700);
}
.container_state_time_finished:not(.container_selected):hover .taskStateLabel {
  color: var(--grey-900);
}

/* SELECTED */
.container_selected {
  background-color: var(--dark-blue-100);
}
.container_selected .playerInitials {
  background-color: white;
  color: var(--dark-blue-100);
}
.container_selected .playerName,
.container_selected .stateIcon,
.container_selected .taskStateLabel {
  color: white;
}
