.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1000px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.goBack {
  cursor: pointer;
}

.goBack svg {
  height: 32px;
  width: 32px;
}

.insightsBox {
  display: flex;
  flex-direction: row;
  height: 120px;
  border-radius: 15px;
  justify-content: space-between;
  padding: 20px;
  border-width: 1px;
  border: 1px solid var(--primary-normal);
  background-color: white;
}

.insightsLeft {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.insightsRight {
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: flex-end;
}

.insightTitle {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 24px;
  letter-spacing: 2%;
  color: var(--primary-normal);
}

.gamesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;
  gap: 16px;
  height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px;
  flex: 1;
}

.reviewArea {
  box-shadow: 0px 8px 16px 0px #0000001a;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.ratingContainer {
  display: flex;
  padding: 16px 0;
  align-items: center;
  gap: 32px;
}

.ratingNumber {
  color: var(--yellow-100);
  font-weight: 800;
  font-size: 40px;
}

.ratingStars {
  display: flex;
  flex-direction: row-reverse;
  gap: 3px;
}

.star {
  font-size: 36px;
  width: 36px;
  height: 36px;
  border: none;
  padding: 0;
}

.ratedStar {
  font-size: 36px;
  width: 36px;
  height: 36px;
  color: var(--yellow-100);
}

.ratedStars {
  display: flex;
  gap: 3px;
}

.starEmpty {
  color: var(--primary-normal);
  fill: var(--primary-normal);
  background: url('../../../assets/icons/star_rating_outline.svg') no-repeat center;
  background-size: cover;
  transition-property: background-image;
  transition-duration: 100ms;
  transition-delay: 50ms;
}

.starFilled {
  color: var(--primary-normal);
  fill: var(--primary-normal);
  background: url('../../../assets/icons/star_rating.svg') no-repeat center;
  background-size: cover;
  transition-property: background-image;
  transition-duration: 100ms;
  transition-delay: 50ms;
}

.ratingAmountText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  color: var(--grey-700);
}

.allRatingsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.singleRatingContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: solid 1px var(--grey-400);
  width: 100%;
  flex: 1 1 100%;
  padding-left: 6px;
  padding-top: 10px;
}

.singleRatingStars {
  display: flex;
  gap: 3px;
}

.singleRatingStar {
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: var(--yellow-100);
}

.ratingSortArea {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.ratingsSortInput {
  min-width: 140px;
}

.downloadPdfButton {
  margin-left: auto;
}

.hideShadows div {
  box-shadow: none !important;
}

.hideShadows table {
  box-shadow: none !important;
}

.hideShadows .compareBack {
  display: none;
}

.hideShadows .downloadPdfButton {
  display: none;
}
