.container {
  width: min(calc(100vw - 16px), 780px);
  border: 1px solid var(--grey-600);
  border-radius: 5px;
  position: fixed;
  top: calc(5.25rem - 6px);
  right: 8px;
  z-index: 200;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  overflow-y: auto;
  max-height: min(calc(100vh - 5.25rem + 6px - 32px), 680px);
}

.container_singleCol {
  width: min(calc(100vw - 16px), 400px);
}

.noGameContainer {
  min-height: min(90vh, 300px);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px;
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.headerText {
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--grey-900);
}
.userIcon {
  font-size: 1.5rem;
  color: var(--primary-normal);
  margin-top: 5px;
}
.selectableContainer {
  display: flex;
  gap: 16px;
}
.selectableContainer > label {
  flex: 1;
  display: flex;
  justify-content: center;
}
.manageButton {
  margin-top: 16px;
  width: 100%;
}

.playersContainer {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.playersContainerColumn {
  min-width: calc(50% - 8px);
}

.playersContainerFull {
  width: 100%;
}

.playersInviteContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--grey-100);
  border-radius: 8px;
  padding: 8px;
}

.playersList {
  flex: 1;
  list-style-type: none;
  margin: 16px 0 16px 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 16px;
  background: var(--grey-200);
}

.buttonHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--grey-700);
  text-transform: uppercase;
  gap: 8px;
}

.licenceCount {
  padding: 0px 16px;
  font-size: 0.8rem;
  display: flex;
  background-color: var(--grey-100);
  line-height: 1.2rem;
  border-radius: 9px;
  color: white;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.licenceCount:hover {
  opacity: 0.85;
}
.licenceCount:active {
  opacity: 0.7;
}

.licenceCountCapital {
  padding: 0px 16px;
  font-size: 0.8rem;
  display: flex;
  background-color: var(--grey-100);
  line-height: 1.2rem;
  border-radius: 9px;
  color: white;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  width: fit-content;
  text-transform: uppercase;
  font-weight: bold;
}

.licenceCountCapital:hover {
  opacity: 0.85;
}
.licenceCountCapital:active {
  opacity: 0.7;
}

.green {
  background-color: var(--green-50);
}

.yellow {
  background-color: var(--yellow-100);
  color: var(--grey-700);
}

.red {
  background-color: var(--error-normal);
}

.maxTeams {
  font-size: 0.8rem;
  color: var(--grey-700);
}

.buttonArea {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.firstButtons {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
}
.secondaryButtons {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}
.secondaryButtons button {
  flex: 1;
}

.linkArea {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.pinAreaTitle {
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--grey-700);
  margin-top: 16px;
  text-transform: uppercase;
}

.pinArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  height: fit-content;
  margin-top: 18px;
  margin-bottom: 18px;
}

.pinCodeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pinAreaText {
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
}

.extraInfoRow {
  display: flex;
  margin-bottom: 18px;
  padding: 0px 30px;
}

.instructorsList {
  flex: 1;
  list-style-type: none;
  margin: 16px 0 16px 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.emptyList {
  display: flex;
  margin: 32px;
  justify-content: center;
  align-items: center;
  color: var(--grey-700);
  font-weight: 500;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 4px;
}

.listContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.listItemTitle {
  font-weight: 700;
  color: var(--grey-700);
  word-break: break-word;
}
.validText {
  font-weight: 700;
  color: var(--grey-700);
  font-size: 0.7rem;
  text-transform: uppercase;
}
.listItemSubtitle {
  font-size: 0.75rem;
  color: var(--grey-600);
  max-width: 400px;
  word-break: break-word;
}
.startingTaskRow {
  display: flex;
  gap: 5px;
}
.listItemMidtitle {
  font-size: 0.75rem;
  color: var(--grey-600);
  max-width: 400px;
  word-break: break-word;
  font-weight: 700;
}
.trustedEmail {
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--grey-600);
  max-width: 400px;
  word-break: break-word;
}
.notTrustedEmail {
  font-size: 0.75rem;
  color: var(--grey-600);
  max-width: 400px;
  word-break: break-word;
}
.indexStyle {
  font-weight: 700;
  font-size: x-large;
  color: var(--primary-normal);
  min-width: 24px;
  margin-right: 8px;
}
.playerIndex {
  align-self: flex-start;
}
.addInstructorButton {
  display: flex;
  justify-content: center;
}
.listContainer {
  background-color: var(--grey-100);
  justify-content: flex-start;
  padding: 8px;
  border-radius: 5px;
}

.inputFieldContainer {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.maxPlayerValidationText {
  display: flex;
  font-size: 14px;
  color: var(--grey-700);
}
.emailInput {
  flex: 1;
  display: flex;
}
.emailInput textarea {
  flex: 1;
}
.emailInputError {
  top: calc(100% - 14px);
}

.pinTitle {
  background: var(--gradient-primary-normal);
  border-radius: 3px;
  padding: 4px;
  max-width: 13rem;
  min-width: 13rem;
}
.pinTitle > span {
  text-align: center;
  color: white;
  font-weight: 700;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 0.75rem;
  line-height: 1rem;
}

.pinContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.pinContent {
  width: 13rem;
  height: 4rem;
  display: flex;
  justify-content: center;
}
.pinIcon {
  color: var(--primary-normal);
  font-size: 1.5rem;
  margin-right: 4px;
  margin-top: 4px;
}
.playSeppo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
}
.pinCode {
  font-weight: 700;
  color: var(--primary-normal);
  font-size: 1.2rem;
}
.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 4px;
  background-color: white;
  flex: 1;
}

.instructorMenuHeader {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--grey-100);
  border-radius: 5px;
}

.instructorMenuHeader span {
  padding: 8px;
  flex: 1;
  color: var(--grey-900);
  font-weight: bold;
  text-transform: uppercase;
}

.instructorMenuHeader button {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 1.5rem;
  color: var(--grey-500);
  border-radius: 50%;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
}

.instructorMenuHeader_open {
  background-color: transparent;
}

.instructorMenuHeader_open button {
  color: var(--grey-700);
  transform: unset;
}

.gameNotStartedInfo {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  color: var(--grey-700);
}

.playerEmailForm {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.addPlayerButton {
  flex: 1;
  padding: 10px 12px;
}

.settingsButton {
  max-width: fit-content;
  align-self: center;
}

.addPeopleSubtitle {
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--grey-700);
  text-transform: uppercase;
}

.playersListContainer {
  max-height: 452px;
  overflow-y: auto;
}

.addPlayerByEmailButton {
  padding: 8px 16px;
}

.addPlayersInfo {
  margin-bottom: 16px;
  color: var(--grey-800);
  text-align: center;
}

.moreOptions {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-direction: column;

  button {
    flex: 1;
  }
}

.scormArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: wheat; */
  border: solid 1px lightgrey;
  padding: 10px;
  border-radius: 10px;
}

.scormTitle {
  align-self: flex-start;
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--grey-700);
  text-transform: uppercase;
}

.scormForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shareButtons {
  border: none;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  flex-direction: row-reverse;
  background-color: transparent;
  padding-left: 8px;
  margin-left: -8px;
  margin-right: -8px;

  &:hover {
    background-color: var(--primary-background);
  }

  a {
    border-radius: 50%;
    background-color: var(--primary-light);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
  }

  div {
    font-size: 16px;
  }
}

.shareToGoogle {
  border-radius: 50%;
  background-color: var(--primary-light);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
