.evaluationInfoAndSort {
  padding: 12px 16px;
  background-color: var(--grey-50);
  display: flex;
  gap: 16px;
  align-items: center;
}

.evaluationInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--grey-900);
}

.evaluationSort {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  max-width: 55%;
  margin-left: auto;
}

.evaluationSortInput {
  width: 100%;
}
