.answerContainer {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid var(--primary-normal);
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.previousAnswerContainer {
  border: initial;
  background-color: var(--grey-50);
  border: 1px solid var(--grey-400);
}

.answerHeader {
  display: flex;
  gap: 4px;
}

.headerSecondRow {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.answerTimestamp {
  margin-left: auto;
}

.divider {
  min-height: 2px;
  background-color: var(--grey-200);
  margin: 6px 0;
}

.clickable {
  cursor: pointer;
}

.previousAnswerFeedbackPreview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
