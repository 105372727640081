.container {
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
}

.titleRow > h4 {
  color: var(--primary-normal);
}

.contextSwitchRow {
  display: flex;
  background-color: var(--grey-50);
  border-radius: 4px;
  gap: 8px;
  padding: 8px 16px;
  margin: 0 8px;
}

.contextSwitchRow > * {
  flex: 1;
  justify-content: center;
}

.contextSwitchRow > label > :last-child {
  position: unset;
}
