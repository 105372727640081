.sidebarContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebarCardContainer {
  padding: 0 var(--preview-modal-sidebar-padding);
}

.sidebarActionsContainer {
  padding: 0 var(--preview-modal-sidebar-padding);
  margin-top: 16px;
  display: flex;
  width: 100%;
  gap: 8px;
  position: relative;
  flex-wrap: wrap;
}

.sidebarActionButton {
  white-space: nowrap;
  flex: 1;
}

.sidebarActionButton:last-of-type {
  padding: 10px;
}

.sidebarInfoList {
  padding: 0 var(--preview-modal-sidebar-padding);
}

.sidebarTopicList,
.sidebarAgesList,
.sidebarTagsList {
  list-style: none;
  padding-left: 0;
}

.sidebarTopicList,
.sidebarAgesList,
.sidebarTagsList {
  margin: 0;
}

.sidebarInfoList > li,
.sidebarTopicList > li {
  color: var(--grey-900);
  margin-bottom: 8px;
}

.sidebarInfoList > li {
  display: flex;
  align-items: center;
}

.sidebarInfoList > li > span {
  font-size: 1.5rem;
}

.sidebarShowMoreContainer {
  padding: 0 var(--preview-modal-sidebar-padding);
  color: var(--grey-900);
  display: flex;
  flex-direction: column;
}

.showMoreButton {
  align-self: center;
  border: none;
  background-color: transparent;
  color: var(--grey-700);
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  padding: 8px 20px;
  gap: 8px;
}

.showMoreSection,
.showMoreSectionSplit {
  padding-bottom: 16px;
}

.showMoreSectionSplit {
  display: flex;
}

.showMoreSectionSplit > div {
  flex: 1;
}

.sidebarShowMoreTitle {
  color: var(--grey-700);
  margin-bottom: 4px;
}

.sidebarTagsList {
  display: flex;
  overflow-x: auto;
  padding: 5px 0;
  margin-right: -20px;
}

.sidebarTagsList > li {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.sidebarDivider {
  margin-top: 16px;
  border-bottom: 1px solid var(--grey-200);
}

.sidebarTabList {
  display: flex;
  overflow-x: auto;
  padding-bottom: 16px;
}

.sidebarTabButton {
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  border-bottom: 4px solid transparent;
  color: var(--grey-600);
  cursor: pointer;
  padding: 15px 32px;
  font-size: var(--medium-font);
  font-weight: var(--bold);
}

.sidebarTabContainer {
  padding: 0 var(--preview-modal-sidebar-padding);
}

.sidebarTabTitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: var(--grey-900);
  margin: 24px 0 12px 0;
}

.sidebarTabContent {
  color: var(--grey-700);
  font-size: 0.75rem;
  line-height: 1rem;
}

.sidebarTabContent img {
  max-width: 100%;
  height: auto;
}

.moreMenuContainer {
  background-color: white;
  position: absolute;
  top: calc(100% + 8px);
  right: var(--preview-modal-sidebar-padding);
  z-index: 102;
  display: block;
  min-width: fit-content;
  border-radius: 5px;
  border: 1px solid var(--grey-100);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.rateGameButton {
  background-color: transparent;
  border: none;
  color: var(--primary-normal);
  animation: rate 3s infinite linear;
  font-size: 16px;
  padding: 0 56px;
  line-height: 1.5rem;
  cursor: pointer;
}

.viewGameRatingsButton {
  background-color: transparent;
  border: none;
  color: var(--secondary-normal);
  font-size: 16px;
  padding: 0 56px;
  line-height: 1.5rem;
  cursor: pointer;
  transform: translate(0, -8px);
}

.rateGameButtonHidden {
  display: none;
}

@keyframes rate {
  0% {
    scale: 1;
    transform: translate(0, -8px);
  }
  5% {
    scale: 1.05;
    transform: translate(0, -10px);
  }
  10% {
    scale: 1;
    transform: translate(0, -8px);
  }
  15% {
    scale: 1.05;
    transform: translate(0, -10px);
  }
  20% {
    scale: 1;
    transform: translate(0, -8px);
  }
  100% {
    scale: 1;
    transform: translate(0, -8px);
  }
}
