.answerOptionsTable {
  display: table;
  border-spacing: 0 8px;
}

.answerOptionRow {
  display: table-row;
}

.answerOptionLeftColumn {
  display: table-cell;
  width: 100%;
}

.answerOptionRightColumn {
  display: table-cell;
  padding-inline-start: 16px;
  vertical-align: middle;
}

.answerOptionRightColumn_lessPadding {
  padding-inline-start: 8px;
}

.timeExceededFadeout {
  opacity: 0.5;
}
