.cannedMessagesContainer {
  margin: 2px;
  background-color: white;
  border-radius: 5px;
  padding: 8px;
  color: var(--grey-900);
  word-break: break-all;
}

.cannedMessagesHeader {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: bold;
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  color: black;
}

.cannedMessagesHeader span {
  cursor: pointer;
}

.cannedMessagesHeader button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-800);
  cursor: pointer;
}

.iconExpanded {
  transform: rotate(180deg);
}

.cannedMessagesContent {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
}

.contentRow {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.optionButtonContainer {
  display: flex;
  gap: 12px;
}

.optionButton {
  padding: 0;
  color: var(--grey-800);
}

.tabsContainer {
  display: flex;
}

.tab {
  color: var(--grey-900);
  background-color: var(--grey-100);
  -webkit-appearance: none;
  border: 1px solid var(--grey-300);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.tab:nth-child(2) {
  margin-left: -1px;
}

.selectedTab {
  background-color: white;
  border-bottom-color: white;
  z-index: 10;
}

.selectedTabContainer {
  border: 1px solid var(--grey-300);
  padding: 8px;
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.saveButton {
  width: fit-content;
  align-self: flex-end;
}

.cannedMessageList {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* shared and variant normal */
.button {
  --gradient-disabled: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--grey-600);
  --gradient-secondary-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), var(--secondary-normal);

  font-weight: 500;
  border-radius: 1.5rem;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--gradient-primary-normal);
  font-size: 1rem;
  line-height: 1.4375rem;
  letter-spacing: 0.02rem;
  border: 0;
  text-align: left;
}
.button:hover {
  background: var(--gradient-primary-hover);
}
.button:active {
  background-color: white;
  color: rgba(242, 242, 242, 0.7); /* grey-100 with opacity 0.7 */
}
.button:disabled {
  background: var(--grey-600);
  cursor: not-allowed;
  pointer-events: none;
}

/* outline */
.buttonOutline {
  background-color: white;
  border: 1px solid var(--grey-800);
  padding: 0.5rem;
  word-break: break-word;
  width: 100%;
  justify-content: flex-start;
  color: var(--grey-800);
  font-size: 0.85rem;
}
.buttonOutline:hover {
  color: var(--grey-900);
  background: var(--gradient-primary-background);
  border-color: transparent;
}

.buttonOutline:focus {
  color: var(--primary-normal);
  border-color: var(--primary-normal);
}

.infoMessage {
  word-break: break-word;
}

.infoIcon {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoContainer {
  display: flex;
  gap: 0.5rem;
  padding: 8px 0;
}

.imgOnlyArea {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-style: italic;
  margin-left: auto;
  margin-right: auto;
}

.cannedMessageEditorContainer {
  padding: 2rem;
  background-color: white;
}

.editorHeading {
  font-size: 1rem;
}
