.container {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--grey-50);
  border-radius: 13px;
  padding: 12px 8px;
  border: 1px solid transparent;
  cursor: pointer;
}

.leftContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftContainer > svg {
  color: var(--primary-normal);
  width: 2rem;
  height: 2rem;
}

.middleContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}

.taskName {
  flex: 1;
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  word-break: break-word;
}

.middleBottomRow {
  display: flex;
  gap: 12px;
  align-items: center;
}

.statusAndTypePill {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--grey-900);
  padding: 2px 12px;
  border-radius: 99px;
  background-color: var(--primary-light);
  text-transform: uppercase;
}

.iconAndPointsContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.iconAndPointsContainer > svg {
  color: var(--secondary-normal);
  width: 1rem;
  height: 1rem;
}

.answerCount svg {
  color: var(--primary-normal);
}

/* COMPACT OVERRIDES */
.containerCompact {
  padding: 8px 12px;
}

.containerCompact .leftContainer > svg {
  width: 1rem;
  height: 1rem;
}

.containerCompact .middleContainer {
  flex-direction: row;
  justify-content: space-between;
}

.containerCompact .middleContainer h5 {
  font-size: 0.75rem;
  line-height: 1rem;
}

.containerCompact .statusAndTypePill {
  display: none;
}

.containerCompact .middleBottomRow {
  gap: 4px;
}

/* COLOUR CODING - HOVER */
.container:not(.isSelected_container):hover {
  background-color: var(--grey-50);
  border-color: var(--grey-500);
}

.container:not(.isSelected_container):hover .taskName {
  color: var(--grey-900);
}

/* COLOUR CODING - NEEDS GRADING */
.needsReview_container {
  background-color: var(--grey-100);
  border: 1px solid var(--secondary-normal);
}

.needsReview_container .taskName {
  color: var(--grey-900);
}

.needsReview_container .statusAndTypePill {
  color: white;
  background-color: var(--secondary-normal);
}

.needsReview_container .leftContainer > svg,
.needsReview_container .answerCount svg {
  color: var(--secondary-normal);
}

/* COLOUR CODING - SELECTED */
.isSelected_container {
  background-color: var(--secondary-normal);
}

.isSelected_container *:not(.rightContainer *),
.isSelected_middleContainer > h5 {
  color: white;
}

.isSelected_container .statusAndTypePill {
  background-color: var(--grey-900);
}
