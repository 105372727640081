.roundButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  box-shadow: 0px 5.23077px 10.46154px 0px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0;
}

.roundButton_pink {
  color: white;
  background: var(--gradient-primary-normal);
}

.roundButton_blue {
  color: white;
  background: var(--blue-50);
}

.roundButton span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.roundButton:hover {
  transition: all 50ms ease-in;
  background: var(--gradient-primary-hover);
}
.roundButton:active {
  background: var(--gradient-primary-active);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.roundButton_blue:hover {
  transition: all 50ms ease-in;
  background: var(--blue-100);
}
.roundButton_blue:active {
  background: var(--blue-100);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.roundButton:not(.roundButton_noHoverShadow):hover {
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
}

.roundButton:disabled {
  color: white;
  background: var(--grey-600);
  pointer-events: none;
}

.rotate_left span {
  transform: rotate(180deg);
}
.rotate_up span {
  transform: rotate(-90deg);
}
.rotate_down span {
  transform: rotate(90deg);
}

.roundButton_white {
  color: var(--primary-normal);
  background: white;
}

.roundButton_white:hover {
  transition: all 50ms ease-in;
  color: var(--grey-900);
  background: var(--gradient-primary-background);
}

.roundButton_white:active {
  color: var(--grey-900);
  background: var(--gradient-primary-background);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.roundButton_opaque {
  color: var(--primary-normal);
  background: white;
  opacity: 0.7;
}

.roundButton_opaque:hover {
  transition: all 50ms ease-in;
  color: var(--grey-900);
  background: var(--gradient-primary-background);
  opacity: 0.7;
}

.roundButton_opaque:active {
  color: var(--grey-900);
  background: var(--gradient-primary-background);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  opacity: 0.7;
}
