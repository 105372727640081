.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  font-size: 3rem;
  color: var(--grey-900);
  gap: 24px;
  padding: 0 8px;
}

.container > h2 {
  text-align: center;
}
