.backgroundContainer {
  border-radius: 19px;
  height: fit-content;
  min-height: 80vh;
  width: min(84%, 1600px);
  min-width: 720px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
}

.loginWithIdButton {
  background: white;
  padding: 4px;
  color: var(--primary-normal);
  font-size: 0.833125rem;
  font-weight: bolder;
  border: none;
  min-height: unset;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 12px;
}
.loginWithIdButon:hover {
  opacity: 0.8;
}
.loginWithIdButon:active {
  color: var(--primary-dark);
}

.seppoLogo {
  display: flex;
  margin: 50px 0 50px 0;
  max-width: 200px;
  max-height: 200px;
}

.bottomLogo {
  max-width: 100px;
  max-height: 100px;
}

.backgroundContainer > h1 {
  font-size: 2.75rem;
  margin-bottom: 45px;
}

.cardsContainer {
  display: flex;
  gap: min(42px, 4%);
  height: min-content;
  justify-content: center;
  margin-bottom: 30px;
}

.cardBodyText {
  font-size: 1.125rem;
  margin-bottom: 20px;
}

.cardBodyTextNoMargin {
  font-size: 1.125rem;
  font-weight: bold;
}

/* This style is here only to align the left and right cards input with the button */
.layoutAlignmentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.layoutAlignmentWrapperRowMode {
  flex-direction: row;
  gap: 20px;
}

.layoutAlignmentWrapperRowMode .enterCodeRow {
  flex-direction: column;
}

.layoutAlignmentWrapperRowMode .codeInputField {
  width: initial;
}

.loginWithGameCode {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dividerLine {
  height: 1px;
  border-bottom: solid 2px var(--grey-200);
  width: 250px;
}

.dividerLineVertical {
  width: 1px;
  border-left: solid 2px var(--grey-200);
  height: 100px;
}

.enterCodeRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.alwaysRow {
  flex-direction: row !important;
}

.enterCodeColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.codeInputFieldContainer {
  height: 100%;
}

.codeInputField {
  width: 350px;
}

.previousGameCard {
  display: flex;
  flex-direction: column;
  min-width: 260px;
  min-height: 100px;
  border-radius: 17px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  /* TODO: check if we want equal or unequal heights (current design - longer title more height) */
  height: min-content;
}

.previousGameCardTop {
  display: flex;
  min-height: 93px;
  align-items: flex-start;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.previousGameCardBottom {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.previousGameCardBottom .gameTitle {
  margin-bottom: 7px;
  font-weight: var(--bold);
  font-size: var(--medium-font);
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: var(--grey-900);
}

.playerAccountLoginIcon {
  display: flex;
}

.playerAccountLoginIcon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.instructorLogins {
  display: flex;
  gap: 40px;
}

.bottomCodeArea {
  padding-bottom: 20px;
}

.minTwoLinesHeight {
  min-height: 42px;
}

.showContinueGameBlockSmallcardBodyText {
  margin-bottom: 1rem;
}

.previousGameCardWithBorder {
  border: 1px solid var(--primary-normal);
  border-radius: 15px;
}

.statusPillRow {
  position: absolute;
  margin: 10px;
}

/* MIRRORING */
.inputElementMirroring {
  right: unset;
  left: 13px;
}
/* ********* */

@media (max-width: 1400px) {
  .instructorLogins {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 1300px) {
  .codeInputField {
    width: 250px;
  }
}

@media (max-width: 1200px) {
  .backgroundContainer {
    width: min(92%, 1024px);
  }
}

@media (max-width: 1100px) {
  .enterCodeRow {
    flex-direction: column;
  }
  .codeInputField {
    width: 100%;
  }
  .backgroundContainer {
    height: fit-content;
  }
}

@media (max-width: 970px) {
  .cardsContainershowContinueGameBlockSmall {
    flex-direction: column;
    min-height: -moz-fit-content;
    min-height: fit-content;
    gap: 24px;
  }
}

@media (max-width: 900px) {
  .backgroundContainer {
    width: 96%;
  }
}

/* MOBILE PHONES BREAKPOINT */
@media (max-width: 768px) {
  .centerOnSmallScreen {
    text-align: center;
  }
  .backgroundContainer {
    padding: 0 12px;
    min-height: fit-content;
    height: initial;
    border-radius: 0;
  }
  .narrowSwitchToRow {
    flex-direction: row;
    margin-bottom: 15px;
  }
  .dividerLine {
    width: 45%;
  }
  .dividerLineVertical {
    border-left: initial;
    border-bottom: solid 2px var(--grey-200);
    width: 45%;
    height: 1px;
  }
  .enterCodeRow {
    flex-direction: column;
  }
  .codeInputField {
    width: 100%;
  }
  .seppoLogo {
    margin-top: 0;
    margin-bottom: 12px;
    max-width: 200px;
    max-height: 200px;
  }
  .cardsContainer {
    flex-direction: column;
    min-height: fit-content;
    gap: 24px;
  }
  .backgroundContainer {
    padding-top: 32px;
    min-width: unset;
    width: 100%;
    gap: 24px;
  }
  .backgroundContainer > h1 {
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin-bottom: 12px;
  }
  .cardBodyText {
    margin-bottom: 0;
  }
  .layoutAlignmentWrapper {
    height: unset;
  }
  .hideOnSmallScreen {
    display: none;
  }
  .layoutAlignmentWrapper > p {
    margin-bottom: 14px;
  }
}

@media (min-width: 769px) {
  .hideOnLargeScreen {
    display: none;
  }
}
