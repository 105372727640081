.noGamesHeading {
  color: var(--grey-800);
}

.noGamesText {
  font-size: 1.1rem;
  margin: 24px 0 48px 0;
}

.EmptyContent {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  color: var(--grey-800);
  text-align: center;
  justify-content: center;
  align-items: center;
}

.seppoLogo img {
  cursor: pointer;
}

.seppoLogo {
  margin-bottom: 48px;
}
