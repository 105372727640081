.radioGroup {
  display: flex;
  width: 100%;
  gap: 24px;
}

.radioLabel {
  flex: 1;
}

.radioInput {
  margin: 0;
  appearance: none;
  position: fixed;
}

.radioInput:focus-visible + .radioContent {
  outline: 1px solid -moz-mac-focusring;
  outline: 1px solid -webkit-focus-ring-color;
}

.radioContent {
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 9px;
  background-color: #fff;
  border: 1.75px solid var(--grey-500);
  box-shadow: 0px 4px 8px 0px #0000001a;
  cursor: pointer;
}

.radioContent:hover {
  color: #fff;
  background-color: var(--secondary-light);
  border: 1.75px solid var(--secondary-light);
}

.radioInput:checked + .radioContent,
.radioInput:checked + .radioContent:hover {
  color: #fff;
  background-color: var(--secondary-normal);
  border: 1.75px solid var(--secondary-normal);
}
