.pageContainer {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--grey-200);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.pageContainer > * {
  color: var(--grey-900);
}

.languageContainer {
  display: flex;
  justify-content: flex-end;
  width: min(84%, 1600px);
  min-width: 45rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: min(5%, 2rem);
  margin-bottom: 1.5rem;
}

.languageSelect {
  min-width: 200px;
}

.pageContainer > p {
  font-size: 1.125rem;
  margin-top: 36px;
  text-align: center;
}

.pageContainer > p > a:visited {
  color: var(--primary-normal);
}

.loginWizard {
  height: 75%;
  min-height: 732px;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
}

.invertedHelpButton {
  background: white;
  color: var(--primary-normal);
}

.hideOnLargeScreen {
  display: none;
}

@media (max-width: 1200px) {
  .languageContainer {
    width: min(92%, 1024px);
  }
  .learnMoreBottomOffset {
    margin-bottom: 96px;
    padding: 0 12px;
  }
}

@media (max-width: 768px) {
  .pageContainer {
    background-color: white;
    min-height: unset;
  }
  .learnMoreBottomOffset {
    margin-bottom: 16px;
    padding: 0 12px;
  }
  .loginWizard {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: unset;
    min-height: unset;
    filter: unset;
  }
  .loginWizard + p {
    padding: 24px;
    margin-top: 0;
  }
  .languageContainer {
    display: none;
  }
  .hideOnSmallScreen {
    display: none;
  }
}

.bigBlockButton {
  border-radius: 8px;
  width: 100%;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.backButton {
  color: white;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.5rem;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
}
.backButton span {
  font-size: 0.4rem;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
}
