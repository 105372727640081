.badgeModal {
  --bm-header-height: 4rem;
  --bm-footer-height: 4.875rem;
  --bm-width: calc(100vw - 16px);
  --bm-height: calc(100vh - 32px);
  --bm-padding: 16px;

  min-width: var(--bm-width);
  max-width: var(--bm-width);
  max-height: var(--bm-height);

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 780px) {
  .badgeModal {
    --bm-width: min(90vw, 770px);
  }
}

.badgeHeader,
.badgeFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--bm-padding);
}

.badgeHeader {
  height: var(--bm-header-height);
}

.badgeHeaderIcon {
  color: var(--primary-normal);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.badgeHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.badgeHeaderTitle > span:first-of-type {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.badgeHeaderTitle > span:last-of-type {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.badgeFooter {
  height: var(--bm-footer-height);
  background-color: var(--grey-50);
}

.badgeMainSection {
  max-height: calc(var(--bm-height) - var(--bm-header-height) - var(--bm-footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  align-items: center;
}

.nameInput {
  min-width: min(100%, 427px);
}

.addImageButton {
  max-width: fit-content;
}

.readyBadgesList {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 16px;
  margin: 0;
}

.readyBadgeListItem {
  list-style: none;
}
