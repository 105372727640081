.button {
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 13px;
  background-color: var(--grey-50);
  border: 2px solid var(--validation-error);
  box-sizing: border-box;
  color: var(--validation-error);
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.iconContainer {
  background-color: #fff;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px 0px #0000001a;
}
