.backdrop {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: var(--backdrop-500);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  z-index: 101;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 32px);
  overflow-y: auto;
  overflow-x: hidden;
  width: min(90vw, 50rem);
}

.containerSaige {
  width: min(90vw, 55rem);
}

.content {
  position: relative;
}

.header,
.description,
.mainFormContainer,
.footer {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  display: flex;
  gap: 8px;
  align-items: center;
}

.headerTitle > span:first-of-type {
  color: var(--primary-normal);
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.headerTitle > span:nth-of-type(2) {
  color: var(--grey-900);
  font-weight: 700;
}

.description {
  background-color: var(--grey-50);
  color: var(--grey-700);
  border-top: 1px solid var(--grey-200);
  border-bottom: 1px solid var(--grey-200);
}

.rowsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}

.addMemberRow {
  display: flex;
  gap: 16px;
}

.addMemberRow > div:nth-of-type(2) {
  flex: 2;
}

.addMemberRow > div:nth-of-type(1),
.addMemberRow > div:nth-of-type(3) {
  flex: 2;
}

.addMemberRow > div:nth-of-type(4) {
  flex: 100px;
}

.addMemberRowSaige > div:nth-of-type(4) {
  flex: 2 !important;
}

.addMemberRowSaige > div:nth-of-type(5) {
  flex: 100px;
}

.lastColumn {
  display: flex;
  gap: 4px;
  align-items: center;
}

.lastColumn > div {
  flex: 1;
}

.actionMenu {
  max-width: fit-content;
  align-self: flex-end;
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--grey-50);
  border-top: 1px solid var(--grey-200);
}

.footerRight {
  display: flex;
  align-items: center;
  gap: 16px;
}

.loaderBackgroundOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--grey-600);
  z-index: 102;
  opacity: 0.5;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 103;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.csvButtonContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.csvButton {
  --gradient-disabled: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%),
    var(--grey-600);
  --gradient-secondary-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), var(--secondary-normal);

  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--gradient-primary-normal);
  padding: 10px 28px;
  font-size: 1rem;
  line-height: 1.4375rem;
  letter-spacing: 0.02rem;
  border: 0;
}
.csvButton:hover {
  background: var(--gradient-primary-hover);
}
.csvButton:active {
  background: var(--gradient-primary-active);
  color: rgba(242, 242, 242, 0.7); /* grey-100 with opacity 0.7 */
}
.csvButton:disabled {
  background: var(--grey-600);
  cursor: not-allowed;
  pointer-events: none;
}

/* outline */
.csvButton {
  color: var(--primary-normal);
  background-color: white;
  border: 1px solid var(--primary-normal);
}
.csvButton:hover {
  color: var(--grey-900);
  background: var(--gradient-primary-background);
  border-color: transparent;
}
.csvButton:active {
  color: white;
  background: var(--primary-normal);
}
.csvButton:disabled {
  color: white;
  border: 1px solid var(--grey-600);
}

.previewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-bottom: 0;
}

.previewHeaderLeft {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.previewHeaderTitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.previewHeaderDescription {
  font-size: 12px;
  line-height: 16px;
}

.previewHeaderRight {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 1rem;
  height: 36px;
  justify-content: flex-end;
  align-items: center;
}

.errorRed {
  color: var(--validation-error);
}

.iconRow {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 16px;
  margin-top: 16px;
  cursor: pointer;
}

.icon {
  font-size: 1.5rem;
  color: var(--primary-normal);
}

.extraRow {
  display: none;
}

.noVisibleOutline1 {
  border: none !important;
  outline: none;
  field-sizing: content;
  padding: 1rem !important;
  padding-bottom: 0 !important;
}

.noVisibleOutline2 {
  border: none !important;
  outline: none;
  height: fit-content;
  overflow: visible;
  field-sizing: content;
  padding: 1rem !important;
}

.mainFormContainerpreview {
  border-radius: 9px;
  outline-color: var(--primary-normal);
  border: 1.5px solid var(--grey-400);
  margin: 20px;
  overflow: scroll;
  height: 300px;
  padding: 0;
}

.mainFormContainerpreview:focus-within {
  border-color: var(--primary-normal);
}

.userInfoContainer {
  font-size: 1.125rem;
  color: var(--grey-700);
  background-color: var(--grey-100);
  padding: 1rem;
}

.table {
  width: 100%;
  border-spacing: 0;
  margin-top: 20px;
}

.th {
  border: 1px solid var(--grey-600);
  padding: 6px;
  padding-left: 1rem;
  text-align: left;
  background-color: var(--primary-background);
}

.thLT {
  border-top-left-radius: 1rem;
}

.thRT {
  border-top-right-radius: 1rem;
}

.tdLB {
  border-bottom-left-radius: 1rem;
}

.tdRB {
  border-bottom-right-radius: 1rem;
}

.td {
  border: 1px solid var(--grey-600);
  padding: 6px;
  padding-left: 1rem;
  height: 36px;
}
