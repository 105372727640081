.inlineField {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.multilineInlineField {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.multilineInlineField .label {
  margin-top: 12px;
}

.label {
  color: var(--grey-700);
  display: inline-block;
  margin-bottom: 8px;
}

.inlineField .label {
  margin-bottom: unset;
}
