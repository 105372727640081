.mainContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.topbarSection {
  display: flex;
  align-items: center;
  gap: 12px;
}

.useNewEditorLink {
  color: var(--primary-normal);
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 20px;
}

.backButton {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--grey-500);
  font-size: 1.75rem;
}

.logo {
  cursor: pointer;
}

.editorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.editorIframe {
  flex: 1;
  border: 0;
}

.fabContainer {
  display: block;
}
