.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

/*  Helper classes for spacing
*   Applicable if element has display: flex property
*     
*   mAxis - main axis
*   sAxis - secondary axis
*/
.mAxisC {
  justify-content: center;
}

.sAxisC {
  align-items: center;
}

.mAxisSA {
  justify-content: space-around;
}

.gap_075 {
  gap: 0.75rem;
}

.m_0 {
  margin: 0;
}

.mx_0 {
  margin-left: 0;
  margin-right: 0;
}

.my_0 {
  margin-top: 0;
  margin-bottom: 0;
}

.ml_075 {
  margin-left: 0.75rem;
}

.mr_075 {
  margin-right: 0.75rem;
}

.mx_075 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.px_05 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.truncateText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smallShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.largeShadow {
  box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.2);
}

.py_05 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
