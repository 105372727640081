.taskLibraryModal {
  --tlm-header-height: 4rem;
  --tlm-footer-height: 4.875rem;
  --tlm-width: calc(100vw - 16px);
  --tlm-height: calc(100vh - 16px);
  --tlm-padding: 16px;

  min-width: var(--tlm-width);
  max-width: var(--tlm-width);
  min-height: var(--tlm-height);
  max-height: var(--tlm-height);

  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 600;
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  .taskLibraryModal {
    --tlm-width: min(90vw, 776px);
    --tlm-height: calc(100vh - 80px);
  }
}

.taskLibraryResultsLoaderOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.taskLibraryHeader {
  height: var(--tlm-header-height);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: var(--tlm-padding);
  border-bottom: 1px solid var(--grey-400);
}

.taskLibraryHeaderIcon {
  color: var(--primary-normal);
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

.taskLibraryTitle {
  flex: 1;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: var(--grey-900);
}

.taskLibraryFooter {
  height: var(--tlm-footer-height);
  display: flex;
  justify-content: space-between;
  background-color: var(--grey-50);
  border-top: 1px solid var(--grey-400);
  padding: var(--tlm-padding);
}

.taskLibraryMain {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.taskLibraryInfo {
  background-color: var(--grey-50);
  color: var(--grey-700);
  padding: var(--tlm-padding);
  border-bottom: 1px solid var(--grey-400);
}

.taskLibraryResultsContainer {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.loadMoreContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
