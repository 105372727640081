.creativeAnswer {
  word-break: break-word;
}

.creativeAnswer :global(.answer-image) {
  max-width: 100%;
}

.creativeEvaluation {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.evaluationTitle {
  color: var(--grey-900);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
}

.evaluationGuidelinesContainer {
  background-color: white;
  border-radius: 9px;
  padding: 12px;
}

.evaluationGuidelinesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.evaluationGuidelinesHeader span {
  font-weight: bold;
  flex: 1;
  cursor: pointer;
}

.evaluationGuidelinesHeader button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-600);
  cursor: pointer;
}

.iconExpanded {
  transform: rotate(180deg);
}

.evaluationGuidelinesContent {
  padding-top: 12px;
}

.evaluationGuidelinesContent img {
  max-width: 100%;
  height: auto;
}

.pointsInputHeader {
  display: flex;
  justify-content: space-between;
}

.maxPoints {
  color: var(--grey-600);
  display: flex;
  align-items: center;
  gap: 4px;
}

.scoreIcon {
  display: flex;
  align-items: center;
}

.pointsLabel,
.fieldContainer label {
  font-weight: bold;
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  color: black;
}

.pointsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.percentageButtonsContainer {
  display: flex;
  gap: 2px;
}

.percentageButton {
  background-color: white;
  border: 1px solid var(--grey-500);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 2px;
  color: var(--grey-900);
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 9px;
}

.percentageButton:hover:not(.percentageButton_active):not(.percentageButton_disabled) {
  background-color: var(--secondary-background);
}

.percentageButton_active:not(.percentageButton_disabled) {
  color: white;
  background-color: var(--secondary-normal);
}

.percentageButton_disabled {
  background-color: transparent;
  cursor: unset;
}

.gradedWarning {
  background-color: var(--validation-error-light);
  padding: 12px;
  border-radius: 8px;
  color: var(--red-800);
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
}
