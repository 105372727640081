.doorPopup {
  display: flex;
  flex-direction: column;
  width: 240px;
  padding: 6px;
  gap: 12px;
}

.doorPopupFirstRow {
  display: flex;
  justify-content: space-between;
  color: var(--grey-900);
  font-weight: bold;
  align-items: center;
  font-size: 1rem;
  line-height: 24px;
}

.doorPopupBottomText {
  color: var(--grey-700);
  font-size: 12px;
  text-align: left;
  padding: 0px 10px;
}

.doorMenuButton {
  margin-right: -10px;
}

.doorMenu {
  top: 15px !important;
  left: initial !important;
  right: 40px !important;
}

.doorMenu + div {
  margin-top: -5px;
  margin-left: 0px;
}
