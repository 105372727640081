.originalInput {
  appearance: none;
  margin-left: 0.5rem;
}

/* Magic */
label:has(.originalInput:focus-visible) {
  outline: 2px solid -webkit-focus-ring-color;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3.25rem;
  min-width: 3.25rem;
  height: 2rem;
  border-radius: 32px;
  box-sizing: border-box;
}

.switchSmall {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 1.5rem;
  border-radius: 32px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-400);
  transition: 0.4s;
  border-radius: 32px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.75rem;
  width: 1.75rem;
  left: 0.125rem;
  bottom: 0.125rem;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.sliderSmall:before {
  position: absolute;
  content: '';
  height: 1.25rem;
  width: 1.25rem;
  left: 0.125rem;
  bottom: 0.125rem;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--green-100);
}

input:checked + .slider:before {
  /* 
    transform translateX makes the dot move also sometimes up/down a pixel depending on window height
    margin-left with same value makes the same effect on X here and removes bouncing
   */
  margin-left: 1.25rem;
}

input:checked + .sliderSmall:before {
  margin-left: 1rem;
}

input:disabled + .slider:before {
  background-color: var(--grey-150);
}
