.emptyBoardsContainer {
  border-radius: 5px;
  border: 1px solid var(--grey-400);
  overflow: hidden;
}

.emptyBoardsHeader {
  background-color: var(--grey-100);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey-900);
}

.emptyBoardsHeaderIcon {
  color: var(--primary-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.emptyBoardsHeaderTitleContainer {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.emptyBoardsHeaderTitle {
  font-weight: 700;
  line-height: 1.5rem;
}

.emptyBoardsHeaderSubtitle {
  font-weight: 700;
  opacity: 0.8;
}

.emptyBoardsList {
  margin: 0;
  padding: 0;
}

.emptyBoardContainer {
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
}

.boardIconButton {
  display: flex;
  padding: 8px;
  border-radius: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.boardIconButton svg {
  color: var(--primary-normal);
  width: 1.1rem;
  height: 1.1rem;
}

.boardTitle {
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
}

.activePill {
  background-color: var(--primary-dark);
  border-radius: 10px;
  padding: 3px 8px;
  color: white;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.emptyBoardContainer_active .boardIconButton {
  background-color: var(--primary-normal);
}

.emptyBoardContainer_active .boardIconButton svg {
  color: white;
}

.emptyBoardContainer_active .boardTitle {
  color: var(--grey-900);
}
