.modalContainer {
  min-width: 25rem;
  max-width: 27rem;
  min-height: 8rem;
  z-index: 601;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: 24px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.modalContainer > h3 {
  color: var(--primary-normal);
  text-align: center;
}

.textContainer {
  color: var(--grey-900);
  line-height: 1.5rem;
  font-size: 1rem;
  overflow-wrap: break-word;
}

.actionsContainer {
  display: flex;
  gap: 16px;
}

.doubleButton {
  justify-content: space-between;
}

.singleButton {
  justify-content: center;
}
