.taskModalContainer {
  --header-height: 4rem;
  --footer-height: 4.875rem;
  --task-modal-width: calc(100vw - 16px);
  --task-modal-height: calc(100vh - 16px);
  --task-modal-padding: 16px;

  min-width: var(--task-modal-width);
  max-width: var(--task-modal-width);
  min-height: var(--task-modal-height);
  max-height: var(--task-modal-height);

  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 500;
}

@media (min-width: 768px) {
  .taskModalContainer {
    --task-modal-width: min(90vw, 776px);
    --task-modal-height: calc(100vh - 80px);
  }
}

.taskModalSection {
  padding: var(--task-modal-padding);
}

.taskModalHeader {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.taskModalHeaderTitle {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.taskModalHeaderIcon {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.taskModalHeaderButtons {
  display: flex;
  gap: 8px;
}

.taskModalMainSection {
  height: calc(var(--task-modal-height) - var(--header-height) - var(--footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  background-color: white;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskForm {
  display: flex;
  flex-direction: column;
}

.taskFormSection {
  padding: var(--task-modal-padding);
  border-bottom: 1px solid var(--grey-400);
  background-color: #fff;
}

.taskFormSection:last-of-type {
  border: none;
}

.taskMoreMenuButton {
  padding: 0;
}

.taskFormSectionHeader {
  display: flex;
}

.taskFormSectionHeader > button {
  display: flex;
  align-items: center;
  gap: 14px;
  flex-grow: 1;
  border: none;
  background-color: transparent;
  padding: 0;
}

.taskFormSectionHeader > button > h4 {
  text-align: left;
  color: var(--grey-900);
}

.taskFormSectionHeader > button > span {
  color: var(--grey-600);
  transition: all 200ms ease-out;
  line-height: 0;
}

.taskFormSectionHeader_expanded > button > span {
  color: var(--grey-900);
  transform: rotate(90deg);
}

.taskFormSectionChildren {
  display: none;
}

.taskFormSectionChildren_visible {
  display: block;
  padding-top: 16px;
}

.taskFormTasksContainer {
  background-color: var(--grey-50);
}

.subtasksHeader {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.noTasks {
  text-align: center;
  padding: 16px;
  color: var(--grey-700);
}

.subtaskContainer {
  border: 2px solid var(--primary-normal);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 1.5rem;
}

.subtaskFieldsContainer {
  display: none;
}

.subtaskFieldsContainer_visible {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
}

.subtaskFieldsContainer h5 {
  text-transform: uppercase;
  color: var(--grey-900);
  font-weight: 700;
  font-size: var(--small-font);
  line-height: var(--small-font-line-height);
  letter-spacing: 0.058rem;
}

.subtaskInputContainer {
  flex: 1;
}

.subtaskHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtaskHeaderTitle {
  color: var(--primary-normal);
  display: flex;
  align-items: center;
  gap: 8px;
}

.subtaskHeaderTitle > span:first-of-type {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.subtaskHeaderTitle > span:last-of-type {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.subtaskHeaderMoreButton {
  padding: 0;
}

.subtaskHeaderPointsAndActions {
  display: flex;
  align-items: center;
}

.subtaskHeaderActions {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.taskModalFooter {
  height: var(--footer-height);
  display: flex;
  justify-content: space-between;
  background-color: var(--grey-50);
  border-top: 1px solid var(--grey-400);
  border-radius: 0 0 8px 8px;
}

.addSubtaskSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
}

.addSubtaskButton {
  padding: 2rem 0;
  background-color: #fff;
  border: 1px solid var(--grey-400);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  position: relative;
}

.addSubtaskButton * {
  pointer-events: all;
}

.addSubtaskButtonIcon {
  color: var(--primary-normal);
  font-size: 3rem;
}

.addSubtaskButton:hover {
  background: var(--primary-background);
  color: var(--primary-normal);
  border-color: transparent;
  box-shadow: 0px 8px 8px -6px var(--primary-dark);
}

.addSubtaskButton:hover > .addSubtaskButtonIcon {
  color: var(--primary-dark);
}

.addSubtaskButton:hover > .addSubtaskButtonDescription {
  color: var(--primary-normal);
}

.addSubtaskButton:active {
  background: var(--primary-light);
  color: white;
}
.addSubtaskButton:active > .addSubtaskButtonIcon,
.addSubtaskButton:active > .addSubtaskButtonDescription {
  color: white;
}

.addSubtaskButtonDescription {
  color: var(--grey-700);
  font-weight: 400;
  margin-top: 8px;
  padding-left: 2px;
  padding-right: 2px;
}

.addSubtaskTooltip {
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--grey-900);
}

.addSubtaskNew {
  position: absolute;
  top: 8px;
  left: 8px;
}

.addSubtaskTitle {
  margin-bottom: 12px;
  color: var(--grey-700);
}

.addFromLibraryContainer {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.subtaskDescription {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--grey-700);
}

.descriptionTextFieldWrapper {
  flex: 1;
  display: flex;
}

.descriptionTextField {
  flex: 1;
}

.globalFormError {
  background-color: var(--validation-error);
  color: white;
  padding: 4px 24px;
}

.subtaskEntityError {
  margin-top: 16px;
}

.recDialog {
  border: none;
  padding: 0;
  left: 164px;
  top: 3px;
  overflow: hidden;
  height: 240px;
  padding-top: 70px;
  width: 300px;
}

.recDialog::after {
  background-color: transparent;
  pointer-events: none;
}

.recDialog::backdrop {
  background-color: transparent;
  pointer-events: none;
}

.notSupported {
  font-size: 12px;
  text-transform: uppercase;
  color: darkred;
}
