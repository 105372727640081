.container {
  display: flex;
  align-items: center;
  background-color: var(--grey-50);
  border-radius: 5px;
  gap: 8px;
  cursor: pointer;
  flex-direction: column;
  border: solid 2px var(--grey-200);
  overflow: hidden;
  position: relative;
}

.containerActive {
  border: solid 2px var(--primary-normal);
}

.leftContainer {
  display: flex;
  flex-grow: 0;
  padding: 8px;
  border-radius: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.leftContainer > svg {
  color: var(--primary-normal);
  width: 1.1rem;
  height: 1.1rem;
}

.leftContainerActive {
  background-color: var(--primary-normal);
}

.leftContainerActive > svg {
  color: white;
}

.middleContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-grow: 1;
  align-items: center;
}

.middleContainer > h5 {
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
}

.activeTitle {
  color: var(--grey-900) !important;
}

.boardHeader {
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  padding: 8px 8px 4px 8px;
}

.activePill {
  background-color: var(--primary-dark);
  border-radius: 10px;
  padding: 3px 8px;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
}

.innerList {
  margin: 0px;
  padding: 0px;
  align-self: flex-start;
  width: 100%;
  padding-bottom: 8px;
}

.collapseButton {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-700);
  cursor: pointer;
}

.collapseButton_expanded {
  transform: rotate(180deg);
}

.defaultBoardIcon {
  color: var(--primary-normal);
  display: flex;
  align-items: center;
}

.defaultBoardTooltip {
  max-width: 200px;
}
