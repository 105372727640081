.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.gamesAndPaginationContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gamesOverviewContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.librarySourceFiltersContainer {
  display: flex;
}

.librarySourceFiltersContainer > button:not(:first-child) {
  margin-left: 8px;
}

.librarySourceButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  color: var(--grey-900);
  border-radius: 8px;
  border: 1.5px solid transparent;
  cursor: pointer;
}

.librarySourceButton:hover {
  color: var(--grey-600);
}

.librarySourceButtonActive {
  background-color: var(--primary-normal);
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.librarySourceButtonActive:hover {
  background-color: var(--primary-normal);
  color: white;
}

.refineSearchContainer {
  max-width: 35rem;
  display: flex;
  justify-items: center;
}

.refineSearchContainer > button {
  margin-left: 16px;
}

.filtersContainer {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 6px;
  gap: 4px;
}

.filtersContainer > *:not(.addFilterContainer) {
  min-width: calc((100% - 8px) / 3);
  max-width: calc((100% - 8px) / 3);
  display: flex;
}

.addFilterContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.addFilterMenu {
  position: absolute;
  top: 48px;
  padding: 8px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--grey-600);
  z-index: 100;
  min-width: 224px;
}

.addFilterMenu > button {
  border: transparent;
  background-color: transparent;
  font-size: 1rem;
  color: var(--grey-900);
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 8px;
  display: flex;
  align-items: center;
}

.addFilterMenu > button:hover {
  color: var(--grey-600);
}

.addFilterMenu > button:not(:first-child) {
  margin-top: 0.2rem;
}

.addFilterMenu > button > span {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.singleFilterContainer {
  display: flex;
  align-items: center;
}

.singleFilterContainer > *:first-child {
  flex: 1;
}

.removeFilterButton {
  background-color: #fff;
  border: 1px solid var(--grey-500);
  cursor: pointer;
  width: 32px;
  height: 100%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-500);
  border-radius: 0 9px 9px 0;
  border-left: none;
}

.removeFilterButton:hover {
  color: var(--grey-600);
  border-left: 1px solid;
  border-color: var(--grey-600);
}

.removeFilterButton:focus {
  border-left: 1px solid;
}

.removableSelect > div:first-of-type {
  border-radius: 9px 0 0 9px;
}

.checkboxFilter {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  background-color: #fff;
  border: 1px solid var(--grey-500);
  border-radius: 9px 0 0 9px;
}

.checkboxFilter:hover {
  border-color: var(--grey-600);
}

.checkboxFilter > label {
  cursor: pointer;
  color: var(--grey-900);
}
