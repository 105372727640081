.imagePreview {
  width: 99px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--primary-normal);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.imagePreviewOverlay {
  position: absolute;
  width: 100%;
  height: 80%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 50%);
  display: flex;
  justify-content: flex-end;
}

.moreMenuButton {
  color: white;
}
