.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.subSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subSectionTitle {
  color: var(--grey-700);
  font-size: 1.44rem;
  line-height: 2rem;
}

.subSection p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.detailsList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cardExpireInfo {
  background-color: var(--grey-50);
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 9px;
  max-width: 15.5rem;
  border: 1px solid var(--grey-300);
  font-size: 1rem;
  line-height: 1.5rem;
}

.cardExpireInfo > span:first-of-type {
  font-weight: 700;
}

.subscriptionActions {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.infoEmail {
  color: var(--primary-normal);
}
