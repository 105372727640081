.menuContainer {
  max-width: 180px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu {
  width: 100%;
  position: relative;
  padding: 12px;
  color: white;
  display: flex;
  gap: 12px;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.userIcon {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--primary-normal);
  font-size: 2.2rem;
  display: flex;
  align-items: center;
}

.menuContent {
  display: none;
}

@media (min-width: 1024px) {
  .menuContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.name {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--primary-normal);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.organization {
  display: block;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 1rem;
  color: var(--grey-600);
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menuButton {
  border: none;
  border-radius: 8px;
  font-size: 1.125rem;
  background-color: transparent;
  color: var(--grey-600);
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}
