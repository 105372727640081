.container {
  display: flex;
  flex-direction: column;
  min-height: 160px;
  border-radius: 17px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  /* TODO: check if we want equal or unequal heights (current design - longer title more height) */
  height: 100%;
}

.top {
  display: flex;
  min-height: 93px;
  align-items: flex-start;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.reportingGameTop {
  max-height: 93px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.reportingGameLargerTop {
  min-height: 180px;
  max-height: 180px;
}

.reportingGameTop > img {
  width: 110%;
  margin-left: -25px;
  margin-right: -20px;
}

.top > div {
  display: flex;
  flex: 1;
  gap: 6.4px;
  justify-content: flex-end;
  position: relative;
}

.splitCreateNew {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.splitContainer {
  flex-direction: row;
  min-height: min-content;
  gap: 8px;
}

.cardButton {
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  color: var(--grey-600) !important;
  background-color: white !important;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 5.23077px 10.46154px 0px rgba(0, 0, 0, 0.1);
}

.cardButton:hover {
  transition: all 50ms ease-in;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
}

.bot {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  flex: 1;
}

.reportingBottom {
  position: relative;
  padding: 12px 16px;
  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  flex: 1;
  height: 90px;
}

.sponsoredBottom {
  position: relative;
  padding: 12px 16px;
  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  flex: 1;
  height: 110px;
}

.sponsoredGameTitle {
  position: absolute;
  top: 12px;
  font-weight: var(--bold);
  font-size: var(--medium-font);
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: var(--grey-900);
}

.sponsoredInfoArea {
  position: absolute;
  bottom: 10px;
  width: 285px;
}

.reportingInfoArea {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding-right: 30px;
}

.ratingPill {
  position: absolute !important;
  top: 6px;
  left: 6px;
  width: 70px;
  height: 28px;
  gap: 4px;
  border-radius: 99px;
  padding-top: 4px;
  padding-right: 12px;
  padding-bottom: 4px;
  padding-left: 8px;
  background-color: var(--primary-normal);
  justify-content: flex-start !important;
  align-items: center;
}

.ratingPillLarger {
  color: white;
  width: max-content;
}

.ratingPill svg {
  color: #facc15;
}

.ratingNumber {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.activeLabel {
  display: flex;
  align-self: center;
  margin-top: auto;
  font-size: 0.667rem;
  background: var(--gradient-primary-normal);
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  border-radius: 5px;
}

.activateButton {
  margin-top: auto;
}

.bot .gameTitle {
  margin-bottom: 7px;
  font-weight: var(--bold);
  font-size: var(--medium-font);
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: var(--grey-900);
}

.bot > div {
  margin-bottom: 5px;
}

.container:hover {
  transition: all 150ms;
  box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.2);
}
.containerMenuOpened {
  box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.2);
}

.container:not(:hover) {
  transition: all 150ms ease-out;
}

.container.secondaryOutlined {
  border: 1.6px solid var(--secondary-normal);
}

.container.outlined {
  border: 1.6px solid var(--primary-normal);
}

.container.outlined:hover {
  cursor: pointer;
}

.container.outlined:active {
  opacity: 0.8;
}

.container.secondaryOutlined:hover {
  cursor: pointer;
}

.container.secondaryOutlined:active {
  opacity: 0.8;
}

.saigeDisabled {
  opacity: 0.5;
}

.iconWrapper {
  font-size: 2rem;
  color: var(--primary-normal);
}

.splitIconWrapper {
  font-size: 2rem;
  color: var(--primary-normal);
  display: flex;
  align-items: center;
}

.seppoAiIcon {
  font-size: 1.5rem !important;
  margin-top: 6px;
  scale: 1.35;
  color: var(--secondary-normal);
}

.text {
  font-size: var(--medium-font);
  color: var(--primary-normal);
}

.secondaryText {
  font-size: var(--medium-font);
  color: var(--secondary-normal);
}

.cardArchivedOverlay {
  position: absolute;
  background: var(--grey-gradient);
  border-radius: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.cardDisabledOverlay {
  position: absolute;
  opacity: 0.2;
  background-color: var(--grey-600);
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 13px;
}

.inProgressBorder {
  border: 2px solid;
  border-color: var(--primary-normal);
}

.simpleGameCardContainer {
  border-radius: 16px;
  border: 1px solid var(--grey-400);
  overflow: hidden;
  height: 100%;
  position: relative;
}

.reportingGameCardContainer {
  border-radius: 16px;
  border: 1px solid var(--grey-400);
  overflow: hidden;
  height: 100%;
  position: relative;
  flex: 1;
  background-color: white;
  box-shadow: 0px 8px 16px 0px #0000001a;
}

.topPlayedPositionCircle {
  position: absolute !important;
  left: 50%;
  top: 20px;
  background-color: grey;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 52px;
  height: 52px;
  border-radius: 26px;
  transform: translateX(-50%);
  color: white;
}

.topPlayedPositionCircle1 {
  background: #b8860b;
  border: 3px solid #dba827;
}

.topPlayedPositionCircle2 {
  background: #808080;
  border: 3px solid #c0c0c0;
}

.topPlayedPositionCircle3 {
  background: #8b4513;
  border: 3px solid #cd7f32;
}

.topPlayedInfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topPlayedInfoIcon {
  color: var(--primary-normal) !important;
}

.topPlayedInfoIcon > svg {
  height: 16px;
  width: 16px;
}

.simpleGameCardContainer .top {
  cursor: default;
}

.skeletonImg {
  display: flex;
  margin-bottom: 32px;
  text-align: center;
}

.gameInfo {
  display: flex;
  gap: 8px;
  max-width: fit-content;
}

.gameInfo > span {
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  color: var(--grey-900);
}

.browseGamesCard {
  text-decoration: none;
  text-align: center;
  padding: 0 16px;
}

.saigeDisabledModalBodyText {
  font-family: 'montserrat';
  font-size: 1rem;
  line-height: 1.5rem;
}

.saigeDisabledModalButtonRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.saigeButtonText {
  max-width: 200px;
}
