.answerTextContainer,
.feedbackField {
  flex: 1;
}

.choiceAnswerToggle label {
  white-space: nowrap;
}

.branchingIcon {
  color: var(--primary-normal);
  margin-top: 8px;
}

.branchingSelection {
  display: flex;
  gap: 12px;
  margin-left: auto;
  margin-right: 20px;
}

.branchingLabel {
  font-weight: bold;
  color: var(--grey-900);
}

.correctAndTaskLinkColumn {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
