:root {
  /* line height + vertical padding -> works for 1 line of text in info banner
  if > 1 line, body might have a useless scroll -> better than being too smart about this */
  --global-notification-height: calc(1.5rem + 24px);

  --nav-sidebar-width: min(14rem, 350px);

  --page-with-topbar-padding: 24px;
  --page-with-topbar-max-content-width: calc(100vw - var(--nav-sidebar-width) - var(--page-with-topbar-padding) * 2);

  /* Responsive breakpoints */
  --breakpoint-s: 900px;
}
