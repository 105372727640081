.wrapper {
  align-items: center;
  justify-content: center;
  background-color: #ebebeb;
  display: flex;
  padding: 80px;
  min-height: 1000px;
  flex-direction: column;
}

.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  /*background-image: url(https://ux3-staging.seppo.io/static/media/seppo_login_bkg.fe01240a8cfca6eb66b8.jpg);*/
  background-color: white;
  background-size: cover;
  max-width: 80%;
  border-radius: 19px;
  padding: 40px 80px;
  width: 1000px;
  min-width: 1000px;
}

.instructionText {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: var(--grey-900);
}

.errorText {
  margin: 20px;
  background-color: var(--secondary-normal);
  padding: 20px;
  border-radius: 13px;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 1000px;
  padding: 20px 0px;
}

.statusText {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--grey-700);
}

.language {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--grey-700);
  gap: 16px;
}

.fileWrapper {
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid var(--grey-400);
  border-radius: 9px;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 80px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.fileWrapperWithError {
  border: 2px solid #b95843;
}
.fileWrapper div {
  margin-left: auto;
  font-size: 0.75rem;
  padding: 0px 8px;
  border-radius: 4px;
  margin: 0 8px 0 24px;
  display: inline-block;
  position: absolute;
  right: 0;
  color: #fff;
  background-color: #b95843;
  text-align: right;
  top: calc(100% - 14px);
  z-index: 1;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.mainAreaContainer {
  display: flex;
  flex-direction: column;
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.stepTitle {
  font-family: Montserrat;
  font-size: 44px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.005em;
  text-align: center;
}

.welcomeImages {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.welcomeImageWrapper {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 250px;
  height: 200px;
  background-size: cover;
}

.welcomeImageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcomeImageWrapper div {
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: white;
  font-family: Montserrat;
  font-size: 19.2px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  text-shadow: 4px 4px #00000040;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 500px;
}

.continueButton {
  width: 300px;
  align-self: center;
}

.instructionsArea {
  background-color: var(--grey-200);
  border-radius: 16px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.collapseToggler {
  margin-left: auto;
  font-size: 24px;
  cursor: pointer;
}

.statusRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
  width: 500px;
  justify-content: space-between;
}

.statusIndicator {
  width: 57px;
  height: 52px;
}

.statusShortLabel {
  margin-left: auto;
  text-transform: uppercase;
  font-size: 13.33px;
}

.tasksDoneLabel {
  color: var(--grey-700);
  font-weight: bold;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.selectionContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.selectionRow {
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 300px;
  position: relative;
  border-radius: 15px;
  gap: 20px;
}

.gameMap {
  width: 300px;
  min-width: 300px;
  height: 300px;
  position: relative;
  border-radius: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
}

.height3D {
  height: 200px;
  background-size: cover;
}

.finalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.draftPill {
  display: flex;
  text-transform: uppercase;
  box-shadow: 0px 4px 8px 0px #0000001a;
  background-color: white;
  border-radius: 2rem;
  padding: 0.125rem 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: auto;
  margin-left: 10px;
  margin-top: 10px;
}

.gameName {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: var(--grey-900);
}

.gameInfo {
  display: flex;
  gap: 8px;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.gameCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 398px;
  height: 160px;
  gap: 0px;
  border-radius: 17px;
  border: 1px solid #d4d4d4;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.gameCardBottom {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 398px;
  height: 66px;
  padding: 8px 12px 12px 12px;
  gap: 8px;
}

.plotline {
  background-color: white;
  color: black;
  padding: 10px;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.stepDisplay {
  align-self: center;
  margin-top: 40px;
  color: var(--grey-700);
}

.notificationContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.spinWithIntervals {
  animation: spinner 3s ease-out infinite;
}

.fileInput {
  font-size: 18px;
}

.boldLabel {
  font-weight: bold;
}

.largerInput {
  height: 60px;
}

.formThemeSelector {
  display: flex;
  height: 124px;
  border-radius: 9px;
  border: 1.5px solid var(--grey-400);
  background-color: var(--grey50);
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

.themePlaceholder {
  color: var(--grey-700);
}

.themePlaceholderChevron {
  rotate: -90deg;
  color: var(--grey-700);
}

.hidden {
  display: none;
}

.themeLabel {
  color: var(--grey-700);
  margin-bottom: 8px;
  font-weight: bold;
}

.removeBottomSpace {
  margin-bottom: -28px;
}

@keyframes spinner {
  0% {
    rotate: 0deg;
  }
  12.5% {
    rotate: 0deg;
  }
  25% {
    rotate: 0deg;
  }
  37.5% {
    rotate: 135deg;
  }
  50% {
    rotate: 180deg;
  }
  62.5% {
    rotate: 225deg;
  }
  75% {
    rotate: 360deg;
  }
  87.5% {
    rotate: 360deg;
  }
  100% {
    rotate: 360deg;
  }
}
