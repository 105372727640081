.downloadSponsoredForm {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  gap: 1rem;
}

.downloadSponsoredFormFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--pamm-padding);
}

.downloadSponsoredFormMainSection {
  height: fit-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.requirementsWarning {
  background-color: var(--validation-error-light);
  padding: 12px;
  border-radius: 8px;
  color: var(--red-800);
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  max-width: fit-content;
}

.separator {
  border-bottom: 1px solid var(--grey-200);
}

.toggleSwitchDescription {
  font-size: 12px;
}

@media (max-width: 900px) {
  .downloadSponsoredForm {
    padding: 0.5rem;
  }
}
