.flashTaskContainer {
  display: flex;
  border-radius: 13px;
  background-color: var(--grey-50);
  overflow: hidden;
  border: 1px solid transparent;
  cursor: pointer;
}

.flashButton {
  color: var(--primary-normal);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.flashContent {
  flex: 1;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.flashInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.flashTaskDetails {
  display: flex;
  align-items: center;
  gap: 12px;
}

.flashTaskTitle {
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  word-break: break-word;
}

.flashTaskPill {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--grey-900);
  background-color: var(--primary-light);
  padding: 2px 12px;
  border-radius: 99px;
  text-transform: uppercase;
}

.flashTaskPoints {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.flashTaskPoints > svg {
  color: var(--secondary-normal);
  width: 1rem;
  height: 1rem;
}

.answerCount svg {
  color: var(--primary-normal);
}

/* HOVER */
.flashTaskContainer:not(.flashTaskContainer_selected):hover {
  background-color: var(--grey-50);
  border: 1px solid var(--grey-500);
}

.flashTaskContainer:not(.flashTaskContainer_selected):hover .flashTaskTitle {
  color: var(--grey-900);
}

/* NEEDS GRADING */
.flashTaskContainer_review .flashTaskTitle {
  color: var(--grey-900);
}

.flashTaskContainer_review {
  background-color: var(--grey-100);
  border: 1px solid var(--primary-normal);
}

.flashTaskContainer_review.flashTaskContainer_selected .flashTaskPill {
  color: white;
  background-color: black;
}

/* SELECTED */
.flashTaskContainer_selected {
  background-color: var(--primary-normal);
  border: 1px solid var(--primary-normal);
}

.flashTaskContainer_selected .flashTaskTitle,
.flashTaskContainer_selected .moreMenuButton,
.flashTaskContainer_selected .flashTaskPoints,
.flashTaskContainer_selected .flashTaskPoints > svg,
.flashTaskContainer_selected .answerCount,
.flashTaskContainer_selected .answerCount svg {
  color: white;
}

.flashTaskContainer_selected .flashTaskPill {
  color: black;
  background-color: white;
}

.flashTaskContainer_selected .flashButton {
  color: var(--primary-normal);
  background-color: var(--grey-50);
}

/* OPEN */
.flashTaskContainer_open .flashButton {
  color: white;
  background-color: var(--primary-normal);
}

.flashTaskContainer_open.flashTaskContainer_selected .flashTaskPill {
  color: white;
  background-color: black;
}

/* COMPACT */
.flashTaskContainer_compact .flashButton {
  padding: 8px 4px 8px 8px;
  font-size: 1.5rem;
}

.flashTaskContainer_compact .flashContent {
  padding: 8px 12px 8px 8px;
}

.flashTaskContainer_compact .flashInfo {
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.flashTaskContainer_compact .flashTaskTitle {
  font-size: 0.75rem;
  line-height: 1rem;
}

.flashTaskContainer_compact .flashTaskPill {
  display: none;
}

.flashTaskContainer_compact .moreMenuButton {
  padding: 0;
}

.flashTaskContainer_compact .flashTaskDetails {
  gap: 4px;
}
