.container {
  display: flex;
  justify-content: space-between;
}

.initialsAndMessageContainer {
  display: flex;
  gap: 12px;
  flex: 1;
}

.reverse {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.messageAndMenuContainer {
  display: flex;
  flex-direction: row;
}

.initialsContainer {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: white;
}

.avatar {
  width: 100%;
  height: 100%;
}

.primary {
  background: var(--gradient-primary-normal);
}

.secondary {
  background: var(--gradient-secondary-normal);
}

.messageBodyContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.nameRow {
  font-size: 0.8125rem;
  color: var(--grey-700);
  font-weight: bold;
  text-transform: uppercase;
}

.myMessageColor {
  color: var(--primary-normal);
}

.textRow {
  font-size: 0.75rem;
}

.textRow audio {
  width: 250px;
}
