.container {
  padding: 0 16px;
}

.headerContainer {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 6px;
  gap: 14px;
  border: none;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
}

.accordionChevronWrapper {
  color: var(--grey-600);
  transition: all 200ms ease-out;
  line-height: 0;
}

.accordionOpen {
  color: var(--grey-900);
  transform: rotate(90deg);
}

.accordionChildren {
  display: none;
}

.accordionChildren_visible {
  display: block;
  padding-top: 16px;
}
