.tooltipContent {
  position: absolute;
  background-color: var(--grey-50);
  padding: 6px;
  border-radius: 5px;
  border: 1.5px solid var(--grey-600);
  text-align: center;
  margin-bottom: 6px;
  z-index: 501;
}

.tooltip_up,
.tooltip_down {
  transform: translateX(-50%);
}

.tooltip_left,
.tooltip_right {
  transform: translateY(-50%);
}

.tooltip_up::before,
.tooltip_down::before,
.tooltip_left::before,
.tooltip_right::before {
  content: ' ';
  position: absolute;
  width: 5px;
  height: 5px;
  border-bottom: 1.5px solid var(--grey-600);
  border-right: 1.5px solid var(--grey-600);
  background-color: var(--grey-50);
}

.tooltip_up::before {
  transform: rotate(45deg);
  top: calc(100% - 2px);
  left: calc(50% - 2.5px);
}

.tooltip_down::before {
  transform: rotate(-135deg);
  bottom: calc(100% - 2px);
  left: calc(50% - 2.5px);
}

.tooltip_right::before {
  transform: rotate(135deg);
  top: calc(50% - 2px);
  left: -4px;
}

.tooltip_left::before {
  transform: rotate(-45deg);
  top: calc(50% - 2px);
  right: -4px;
}

.icon {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  max-width: fit-content;
}

.explicitClose {
  display: none;
}
