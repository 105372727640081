.pageContainer {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min(100%, 864px);
  gap: 28px;
}

.language {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--grey-700);
}

.languageSelect {
  min-width: 200px;
  margin-left: 8px;
}

.stepContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  padding: 48px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  min-height: 512px;
  width: 100%;
}

.wizardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.seppoLogo {
  align-self: center;
}
.firstStepImageSpacing {
  margin-left: auto;
}

.onboardingForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.buttonsContainer {
  display: flex;
  margin-top: 40px;
}

.inputField {
  max-width: 16rem;
  min-width: 10rem;
}

.countryInput {
  width: min(20rem, 100%);
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.countryInput > h4 {
  margin: auto;
}

.selectableOptionsContainer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  border-radius: 8px;
  padding: 8px;
}

.otherInputContainer {
  margin-top: 24px;
}

.stepContentWrapper > p {
  color: var(--grey-600);
  text-align: center;
  margin-top: 32px;
}

.stepContentWrapper h2,
.stepContentWrapper h3,
.stepContentWrapper h4 {
  text-align: center;
  color: var(--grey-900);
}

.organisationStepContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 40px;
}

.organisationStepContainer > h5 {
  color: var(--grey-700);
  margin-top: 16px;
  text-align: center;
}

.organisationStepContainer > h4 {
  margin-top: 40px;
}

.suggestionStepContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 40px;
}

.suggestionStepGridContainer {
  display: grid;
  margin-top: 32px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 10px;
}

.suggestionStepContainer > h5 {
  color: var(--grey-700);
  margin-top: 32px;
  text-align: center;
}

.suggestionStepContainerOr {
  color: var(--grey-700);
  margin-top: 32px;
  text-align: center;
  text-transform: uppercase;
}

.suggestionStepContainer > div:has(h4) {
  margin-top: 32px;
}

.suggestionStepButton {
  display: flex;
  margin-top: 20px;
}

.selectableOptionsWithOtherError {
  text-align: center;
}
.chooseText {
  color: var(--grey-700);
  margin-top: 16px;
}

.tosToggleContainer {
  margin: auto;
  padding: 16px 24px 0 24px;
}

.alignVertically {
  align-items: flex-start;
}

.signedInInfo {
  color: var(--grey-900);
  margin: 0 auto;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.logOutButton {
  border: none;
  background-color: transparent;
  padding: 0;
  color: var(--primary-normal);
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.logOutButton:hover {
  text-decoration: underline;
}

.SponsoredSpacer {
  margin-top: 30px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}
