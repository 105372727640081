.gamesSortContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

.gamesSortContainer > span {
  color: var(--grey-800);
}

.gamesSortInput {
  min-width: 240px;
}
