.container {
  position: absolute;
  padding: 2rem 2.5rem;
  background-color: white;
  margin: 10rem auto auto auto;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 55.5rem;
  line-height: 1.25rem;
  z-index: 201;
  top: 0;
  left: 0;
  right: 0;
}

.closeButton {
  position: absolute;
  top: -4rem;
  right: 0.125rem;
  font-size: 1.375rem;
  color: white;
}
.closeButton:hover {
  color: var(--grey-400);
}
.container > button:active {
  opacity: 0.8;
}

.backdrop {
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: var(--backdrop-500);
}

.circleWithIcons {
  position: absolute;
  top: -1.25rem;
  right: -2.625rem;
  font-size: 12.875rem;
  color: var(--primary-normal);
}

.middleContainer {
  width: min(80%, 37.5rem);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.title {
  color: var(--primary-normal);
}

.boldText {
  font-weight: 700;
}

.middleTextSpacing {
  margin-bottom: 1.5rem;
  margin-top: 0.75rem;
}

.buttonsContainer {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.buttonsContainer > button {
  line-height: 1rem;
  min-height: 2.25rem;
  font-size: 0.8rem;
  text-transform: uppercase;
}
