.chatContainer {
  --sidebar-base-width: 25rem;
  --sidebar-max-width: 440px;

  position: fixed;
  z-index: 4;
  top: calc(5.25rem + 16px);
  bottom: 32px;
  background-color: white;
  border-radius: 8px;
  right: 16px;
  width: var(--sidebar-base-width);
  max-width: var(--sidebar-max-width);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.noGameContainer {
  justify-content: center;
  text-align: center;
  padding: 0 16px;
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
}
