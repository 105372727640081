.backdrop {
  position: fixed;
  top: 0;
  z-index: 503;
  width: 100vw;
  height: 100vh;
  background-color: var(--backdrop-500);
  display: flex;
  align-items: center;
  justify-content: center;
}

.addGameBoardsModal {
  max-height: 90%;
  z-index: 504;
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 32px;
}

.addGameBoardsModal > h1,
.addGameBoardsModal > h4 {
  width: 100%;
}

.addGameBoardsModal > button:first-child {
  min-width: unset;
  min-height: unset;
  border-radius: unset;
  align-self: flex-start;
  padding: unset;
}

.boardsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
  gap: 16px;
  height: 480px;
  width: 715px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px;
  flex: 1;
}

.selectableBoard {
  border-radius: 9px;
  padding: 2px;
  background-color: var(--grey-100);
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  position: relative;
}

.gameBoard {
  height: 180px;
  width: 154px;
  background-color: white;
  border-radius: 5px;
  object-fit: contain;
}

.thumbnailImageCover {
  object-fit: cover;
}

.boardName {
  margin: auto;
  padding-top: 8px;
  padding-bottom: 4px;
  word-break: break-all;
}

.dimOverlay {
  position: absolute;
  background-color: var(--grey-200);
  border-radius: 8px;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.originalCheckbox {
  appearance: none;
}

.selectableBoardActive {
  border: 2px solid var(--primary-normal);
  color: var(--primary-normal);
  font-weight: 700;
}

.selectableBoard:has(.originalCheckbox:focus-visible) {
  outline: 2px solid -webkit-focus-ring-color;
}

/* NOTE: :has() does not work on FF */
.originalCheckboxFF:focus-visible {
  outline: none;
}
.originalCheckboxFF:focus-visible + img {
  outline: 2px solid -moz-mac-focusring;
  outline: 2px solid -webkit-focus-ring-color;
}
