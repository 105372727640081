.summaryDataBoxesRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.summaryDataBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 148px;
  border-radius: 15px;
  justify-content: space-between;
  padding: 20px;
  border-width: 1px;
  background-color: white;
  border: 1px solid var(--primary-light);
  box-shadow: 0px 8px 16px 0px #0000001a;
}

.summaryDataIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: var(--primary-normal);
  gap: 10px;
  padding-top: 7px;
  padding-right: 8px;
  padding-bottom: 7px;
  padding-left: 8px;
  background: linear-gradient(0deg, #ffd6eb, #ffd6eb),
    linear-gradient(140.96deg, rgba(255, 255, 255, 0.5) 3.68%, rgba(255, 255, 255, 0) 102.13%);
}

.summaryDataIconContainer > svg {
  height: 24px;
  width: 24px;
}

.summaryBoxLargeText {
  width: 150;
  height: 36;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 27.65px;
  line-height: 36px;
  letter-spacing: -0.5%;
  text-align: center;
  color: var(--grey-900);
}

.summaryBoxPinkText {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
  color: var(--primary-normal);
}

.loading {
  opacity: 0.5;
}

.playerGraphHolder {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 784px;
  min-height: 351px;
  border-radius: 15px;
  justify-content: flex-end;
  padding-top: 32px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px #0000001a;
}

.playerGraphLine {
  position: absolute;
  left: 35px;
  right: 20px;
  height: 1px;
  background-color: var(--grey-400);
}

.playerGraphLineValue {
  position: absolute;
  transform: translate(-100%, -50%);
  padding-right: 3px;
  font-size: 10px;
}

.barLabelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 20px;
  margin-bottom: -10px;
  min-height: 50px;
}

.barLabel {
  display: flex;
  flex: 1;
  width: 50px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--grey-900);
  writing-mode: vertical-rl;
  align-items: center;
}

.barRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
  height: 80%;
  z-index: 1;
}

.bar {
  display: flex;
  flex: 1;
  width: 50px;
  background-color: var(--primary-normal);
  margin-top: auto;
  text-align: center;
  color: var(--grey-700);
  padding-top: 2px;
}

.playerGraphLine:nth-of-type(1) {
  top: 40px;
}

.playerGraphLine:nth-of-type(2) {
  top: 100px;
}

.playerGraphLine:nth-of-type(3) {
  top: 160px;
}

.playerGraphLine:nth-of-type(4) {
  top: 220px;
}

.playerGraphLine:nth-of-type(5) {
  top: 280px;
}
