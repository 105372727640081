.bannerContainer {
  display: flex;
  gap: 14px;
  align-items: stretch;
  width: 100%;
}

.introBanner {
  flex: auto;
  max-width: min(100%, 888px);
}

.firstBannerBody {
  padding-top: 1rem;
  line-height: 20px;
  color: var(--grey-900);
  display: flex;
  flex-flow: column;
  gap: 2rem;
}

.searchBanner {
}

a {
  text-decoration: none;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.carouselBackground {
  background-size: cover;
  background-position: center;
  width: 500px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 8px;
}

.carouselTitle {
  width: 500px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  height: 70px;
  color: var(--grey-900);
}

.roundbuttonRight {
  position: absolute;
  right: calc(4% + 1px);
}

.roundbuttonLeft {
  position: absolute;
  left: calc(4% + 1px);
}

.customButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .headerContainer {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .headerContainer {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
