.container {
  transform: translateY(-64px);
  border-radius: 8px;
  background: var(--gradient-primary-normal);
  color: #ffffff;
  margin-top: 28px;
  gap: 48px;
  display: flex;
  flex-direction: column;
  width: min(100%, 888px);
  max-height: fit-content;
  padding: 24px;
}

@media (min-width: 1024px) {
  .container {
    flex-direction: row;
  }
}

.closeButton {
  position: absolute;
  top: 6px;
  right: 6px;
  color: white;
}
.closeButton:hover {
  color: var(--grey-400);
}

.textAndActions {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 700;
  font-size: 1.73rem;
  line-height: 2rem;
}

.text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.feedbackContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feedbackForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.feedbackInput {
  padding: 10px 12px;
  background: #ffffff;
  border: 1.5px solid var(--grey-400);
  border-radius: 9px;
  resize: none;
  flex: 1;
  font-size: 1rem;
}
