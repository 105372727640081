.subtaskContainer_ungraded {
  border: 2px solid var(--secondary-normal) !important;
}

.taskStatus_graded {
  color: var(--primary-normal);
}

.taskStatus_notGraded {
  color: var(--secondary-normal);
}
