.container {
  background-color: var(--grey-50);
  border: 1px solid var(--grey-200);
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
  padding: 0 0 0 8px;
  min-height: 2.5rem;
}

.container:hover {
  background-color: var(--grey-100);
  border-color: var(--grey-500);
}

.container:active {
  background-color: var(--primary-normal);
  color: white;
}

.newMessageBadge {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: var(--primary-normal);
}

.newMessagesCount {
  color: var(--grey-700);
}

.initialsContainer {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background: var(--gradient-secondary-normal);
  color: white;
  font-weight: bold;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nameContainer {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  font-weight: bold;
  font-size: 0.8125rem;
}
