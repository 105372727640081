.helpMenuContainer {
  position: fixed;
  right: 2rem;
  bottom: 166px;
  width: 19.9rem;
  z-index: 101;
}

.helpMenu {
  background-color: white;
  border-radius: 9px;
  padding: 8px 0;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.1);
}

.fabContainer {
  position: fixed;
  right: 32px;
  bottom: 72px;
  z-index: 100;
}

.fabButton {
  font-size: 2rem;
  width: 4.25rem;
  height: 4.25rem;
  background: var(--gradient-primary-normal);
  color: white;
}

.helpMenuContainerTempLegacyEditor {
  position: fixed;
  right: 12px;
  top: 130px;
  width: 19.9rem;
  z-index: 101;
}

.fabContainerTempLegacyEditor {
  display: block;
}

.fabButtonTempLegacyEditor {
  font-size: 1.5rem;
  padding: 12px;
  background: var(--gradient-primary-normal);
  color: white;
}

.helpMenuPosMirroring {
  left: 2rem;
  right: unset;
}
.fabContainerPosMirroring {
  right: unset;
  left: 32px;
}

.chatOffset {
  right: calc(25rem + 32px);
}
