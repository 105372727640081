.mainInfoContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: stretch;
}

.container {
  display: flex;
  gap: 40px;
  min-width: 500px;
  flex: 1 1 500px;
  align-items: center;
}

.saigeContainer {
  display: flex;
  gap: 40px;
  min-width: 400px;
  flex: 1 1 400px;
  align-items: center;
}

.organizationIconContainer {
  background-color: var(--primary-light);
  height: 9.3rem;
  width: 9.3rem;
  min-height: 9.3rem;
  min-width: 9.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.organizationIconContainer > span {
  height: 4.375rem;
  font-size: 4.375rem;
  color: var(--primary-normal);
}

.saigeIconContainer {
  height: 5rem;
  width: 5rem;
  min-height: 5rem;
  min-width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.saigeIconContainer > span {
  height: 5rem;
  font-size: 5rem;
  color: var(--secondary-normal);
}

.orgInfoList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.orgInfoListPart {
  display: flex;
  flex-direction: column;
}

.orgSaigeRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.licenceButton {
  margin-top: 0.5rem;
}
