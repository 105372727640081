.dotLoader {
  width: fit-content;
  display: flex;
  gap: 0.75rem;
}

.dotWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
}

.dotOdd {
  display: flex;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--primary-normal);
  animation: animOdd 0.35s ease-out infinite alternate;
}
.dotEven {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--primary-normal);
  animation: animEven 0.35s ease-out infinite alternate;
}

@keyframes animOdd {
  0% {
    opacity: 1;
    width: 60%;
    height: 60%;
  }
  25% {
    opacity: 0.9;
    width: 70%;
    height: 70%;
  }
  50% {
    opacity: 0.8;
    width: 80%;
    height: 80%;
  }
  75% {
    opacity: 0.7;
    width: 90%;
    height: 90%;
  }
  100% {
    opacity: 0.6;
    width: 100%;
    height: 100%;
  }
}

@keyframes animEven {
  0% {
    opacity: 0.6;
    width: 100%;
    height: 100%;
  }
  25% {
    opacity: 0.7;
    width: 90%;
    height: 90%;
  }
  50% {
    opacity: 0.8;
    width: 80%;
    height: 80%;
  }
  75% {
    opacity: 0.9;
    width: 70%;
    height: 70%;
  }
  100% {
    opacity: 1;
    width: 60%;
    height: 60%;
  }
}
