.contentContainer {
  background-color: #f2f2f2;
  padding: 24px;
  border-radius: 16px;
}

.contentContainerInstructor {
  background-color: transparent;
}

.bannerContainer {
  display: flex;
  gap: 14px;
  align-items: stretch;
}

.introBanner {
  flex: 60%;
}

.firstBannerBody {
  padding-top: 1rem;
  line-height: 20px;
}

.searchBanner {
  flex: 40%;
}

.displayStyleRadioContainer {
  display: flex;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 5px;
  height: 3rem;
  padding: 0 0.375rem;
}

.gamesAndPaginationContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  position: relative;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
  gap: 14px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}

.paginationControlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 64px 0;
}

.paginationButton,
.paginationButton:hover {
  font-size: 1rem;
  background-color: white;
  color: var(--primary-normal);
}

.paginationButton:active {
  background-color: var(--primary-normal);
  color: white;
}

.paginationButton:disabled {
  background: var(--white-gradient);
  color: var(--grey-500);
}

.leftColumnWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.rightColumnWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.noGamesHeading {
  color: var(--grey-800);
}

.noGamesText {
  font-size: 1.1rem;
  margin: 48px 0;
}

.EmptyContent {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  color: var(--grey-800);
  text-align: center;
}
.noContentButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.loaderBackgroundOverlay {
  position: absolute;
  top: -10;
  left: -10;
  right: -10;
  bottom: -10;
  border-radius: 18px;
  background-color: var(--grey400);
  z-index: 1;
  opacity: 0;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  /* Calculated in the offset of the pagination controls margin top/bottom 2x4rem,
     height 2.375rem and top spacing of 2rem
  */
  height: calc(100% - (2 * 4rem + 2.375rem + 2rem));
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  height: 100%;
}

.EmptyContent {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  color: var(--grey-800);
  text-align: center;
  line-height: 5rem;
}

.noContentButtons {
  display: flex;
  justify-content: center;
}

.headerFilters {
  display: flex;
  gap: 1rem;
}

.filterContainer {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: var(--grey-200);
  border-radius: 5px;
  margin-right: 1rem;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  gap: 1.5rem;
}

.headerActionsContainer {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.headerActionsContainer_headerFilters {
  align-items: flex-start;
  gap: 0;
}

.headerActionsContainer_headerFilters_instructor {
  justify-content: flex-end;
}

.filtersButton {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  background: var(--grey-200);
  border: none;
  cursor: pointer;
  height: 43px;
}

.filtersButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 0;
}

.filtersButton:hover {
  background-color: white;
}

.filtersButton > span:first-of-type {
  width: 28px;
  height: 28px;
  border-radius: 99px;
  background-color: white;
  color: var(--primary-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  box-shadow: 0px 5.231px 10.462px 0px rgba(0, 0, 0, 0.1);
}

.filtersButton > span:last-of-type {
  color: var(--primary-normal);
  text-transform: uppercase;
  font-size: 0.833125rem;
  line-height: 1.25rem;
  letter-spacing: 0.066rem;
  font-weight: 500;
}

.filtersButton_active > span:first-of-type {
  background-color: var(--primary-normal);
  color: white;
}

.positionContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: fit-content;
  max-width: 100vw;
  min-width: 100vw;
  max-height: fit-content;
  box-sizing: unset;
  margin: 0;
  padding: 0;
}

.container {
  position: relative;
  height: fit-content;
  width: 100vw;
  max-width: 100vw;
  --page-with-topbar-max-content-width: 100vw;
  overflow-x: hidden;
}

.menubar {
  position: relative;
  top: 0;
  left: 0;
  width: 90vw;
  margin: auto;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.logoContainer {
  color: var(--primary-normal);
  font-size: 3.5rem;
}

.languageContainer {
  width: 15rem;
}

.content {
  width: 80%;
  height: 80%;
  margin: auto;
  margin-top: 50px;
}

.content:before {
  display: block;
  position: absolute;
  top: 20vh;
  right: -200px;
  content: '';
  width: 400px;
  height: 400px;
  background-image: url('../../assets/images/gamification_pink.png');
  background-repeat: no-repeat;
  background-position: center;
}

.content:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 256px;
  height: 256px;
  background-image: url('../../assets/images/NGO_pink.png');
  background-repeat: no-repeat;
  background-position: top left;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.headerLeft {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.headerRight {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding-left: 48px;
}

.headerHeading {
  margin-bottom: 24px;
}

.headerIngress {
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 24px;
}

.headerText {
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}

.headerNumbering {
  display: flex;
}

.headerNumbering:not(:last-child) {
  margin-bottom: 24px;
}

.headerNumberingNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  background-color: var(--pink-100);
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 19px;
  color: white;
}

.headerNumberingText {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.EmptyContent {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  color: var(--grey-800);
  text-align: center;
  line-height: 5rem;
}

.noContentButtons {
  display: flex;
  justify-content: center;
}

.sponsoredSearchContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .headerContainer {
    flex-direction: column;
  }
  .headerLeft {
    width: 100%;
  }
  .headerRight {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 450px) {
  .content {
    width: 100%;
  }
  .headerContainer {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
