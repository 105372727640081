.wrapper {
  align-items: center;
  justify-content: center;
  background-color: #ebebeb;
  display: flex;
  padding: 80px;
  min-height: 100vh;
  flex-direction: column;
}

.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  background-image: url(https://ux3-staging.seppo.io/static/media/seppo_login_bkg.fe01240a8cfca6eb66b8.jpg);
  background-color: white;
  background-size: cover;
  max-width: 80%;
  border-radius: 19px;
  padding: 40px 80px;
  width: 800px;
  min-width: 800px;
}

.basicText {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin: 10px;
}

.boldText {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  font-weight: bold;
}

.title {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.005em;
  text-align: center;
}

.seppoLogo {
  width: 100px;
  align-self: center;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .container {
    width: 360px;
    min-width: 360px;
    padding: 10px;
  }
}
