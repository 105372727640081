.startingTaskArea {
  background: var(--grey-100);
  border-radius: 5px;
  padding: 0px 0px 8px 4px;
}

.startingTaskFieldContainer {
  margin-left: 70px;
}

.startingTaskField {
  width: 70%;
}

.startingTaskOnlyMode {
  margin-left: initial;
  width: 100%;
}

.startingTaskField div:first-child svg {
  height: 2em;
  width: 2em;
}
