.editorWrapper {
  width: 100%;
  border-radius: 8px;
}

.editorWrapper_active {
  outline: 2px solid var(--primary-normal);
}

.editorWrapper :global(.tox .tox-toolbar__primary) {
  /* prevents weird toolbar blur in Firefox */
  transform: unset;
  /* removes unneeded bottom line on Chrome after the fix above */
  background-image: none;
}

.editorError {
  right: 12px;
  max-width: fit-content;
}
