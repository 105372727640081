.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 14px 20px;
  border-radius: 13px;
  border: 2px solid var(--primary-normal);
  background-color: var(--grey-50);
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

.container:hover {
  box-shadow: 0 0px 8px 2px rgba(0, 0, 0, 0.1);
}

.container:active {
  opacity: 0.8;
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.iconContainer {
  padding: 14px;
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 1.625rem;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.iconContainer > svg {
  color: var(--primary-normal);
  width: 1.5rem;
  height: 1.5rem;
}

.labelContainer {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--primary-normal);
}
