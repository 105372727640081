.container {
  display: flex;
  align-items: center;
  padding: 12px 8px;
  background-color: var(--grey-50);
  border-radius: 13px;
  gap: 8px;
  border: 1px solid var(--grey-50);
  cursor: pointer;
}

.container:hover {
  border-color: var(--grey-500);
}

.forceHovering {
  border-color: var(--grey-500);
}

.containerCompact {
  padding: 8px 12px 8px 16px;
}

.containerCompact .moreMenuButton {
  padding: 0;
}

.containerDragged {
  opacity: 0.5;
}

.leftContainer {
  display: flex;
  flex-grow: 0;
}

.leftContainer > svg {
  color: var(--primary-normal);
  width: 2rem;
  height: 2rem;
}

.containerCompact .leftContainer > svg {
  width: 1rem;
  height: 1rem;
}

.middleContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.middleContainerCompact {
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.title {
  flex: 1;
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
}

.titleInnerText {
  word-break: break-word;
  max-width: fit-content;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.taskNameTooltip {
  max-width: 250px;
  word-break: break-word;
}

.container:hover .title {
  color: var(--grey-900);
}

.middleContainerCompact > .title {
  font-size: 0.75rem;
  line-height: 1rem;
}

.middleBottomRow {
  display: flex;
  gap: 12px;
  align-items: center;
}

.statusAndTypePill {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--grey-900);
  padding: 2px 12px;
  border-radius: 99px;
  background-color: var(--primary-light);
  text-transform: uppercase;
}

.iconAndPointsContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.iconAndPointsContainer > svg {
  color: var(--secondary-normal);
  width: 1rem;
  height: 1rem;
}

.hideIfCompact {
  display: none;
}

.moreMenuRowSeparator {
  width: calc(100% - 16px);
  margin: auto;
  height: 1px;
  background: var(--grey-200);
}

.simpleTaskCardTilt {
  transform: rotate(-2deg);
}

.taskOrder {
  min-width: 1.5rem;
  width: fit-content;
  padding: 0 4px;
  height: 1.5rem;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-normal);
  border: 2px solid var(--primary-normal);
  font-size: 0.9rem;
  line-height: 1.125rem;
  font-weight: 700;
  position: relative;
}

.taskOrder::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--primary-normal);
  bottom: -5px;
}

.taskOrder_compact {
  min-width: 1.2rem;
  height: 1.2rem;
  border: 2px solid var(--primary-normal);
  font-size: 0.7rem;
  line-height: 1rem;
}

.taskOrder__compact::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid var(--primary-normal);
  bottom: -4px;
}

.moreMenuContent {
  min-width: 180px;
}
