.licenceExpiredBanner {
  transform: translateY(-64px);
  border-radius: 8px;
  background: var(--gradient-primary-normal);
  color: #fff;
  margin-top: 28px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: min(100%, 736px);
  max-height: fit-content;
  padding: 24px;
}

.title {
  font-weight: 700;
  font-size: 1.73rem;
  line-height: 2rem;
}

.text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.buttonsContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
