.container {
  max-width: 100%;
  height: 5.25rem;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--grey-100);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.backButton {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--grey-500);
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  padding: 0;
}

.seppoLogoContainer {
  display: none;
}

@media (min-width: 1024px) {
  .seppoLogoContainer {
    display: flex;
    align-items: center;
    height: 84px;
    padding: 24px 0;
  }
}

.linkWrapper {
  height: 100%;
}

.seppoLogo {
  height: 100%;
  object-fit: cover;
}

.seppoLogo_noBackBtn {
  padding-left: 8px;
}

.gameNameAndSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background: white;
  border: 1px solid var(--grey-400);
  border-radius: 9px;
  padding: 14px;
  overflow: hidden;
  min-width: 245px;
  max-width: 245px;
}

@media (max-width: 1200px) {
  .gameNameAndSettings {
    min-width: unset;
  }
}

.gameNameAndSettings span {
  color: var(--grey-900);
  font-size: 1.125rem;
  line-height: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gameNameAndSettings button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--grey-600);
  background-color: transparent;
  font-size: 2rem;
  cursor: pointer;
  padding: 0;
}

.gameNameAndSettings button:hover {
  color: var(--grey-400);
}

.tabOptions {
  display: flex;
  flex-grow: 1;
}

.iconsWrapper {
  display: flex;
}

.buttonDefault {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 2.2rem;
  cursor: pointer;
  color: var(--primary-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 99px;
}

.buttonDefault > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonActive {
  color: #ffffff;
  background: var(--gradient-primary-normal);
}
