.subtaskDescriptionContainer {
  margin: -4px 0 0 calc(1.25rem + 8px);
  background-color: white;
  border-radius: 5px;
  padding: 8px;
  color: var(--grey-900);
  word-break: break-word;
}

.subtaskDescriptionHeader {
  display: flex;
  align-items: center;
  gap: 4px;
}

.subtaskDescriptionHeader span {
  cursor: pointer;
}

.subtaskDescriptionHeader button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-800);
  cursor: pointer;
}

.iconExpanded {
  transform: rotate(180deg);
}

.subtaskDescriptionContent {
  padding-top: 12px;
}

.subtaskDescriptionContent img {
  max-width: 100%;
  height: auto;
}

.subtaskDescriptionContent video {
  max-width: 100%;
}
