.noMessagesContainer {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  gap: 16px;
  overflow-y: auto;
  margin: 0;
}
