.title {
  font-weight: 700;
  flex: 1;
}

.sidesDivider {
  min-height: 100%;
  width: 2px;
  border-left: 2px solid var(--grey-400);
}

.sideContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
