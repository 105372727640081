.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.headerActionsContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}

.filterContainer {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: var(--grey-200);
  border-radius: 5px;
  margin-right: 1rem;
}

.headerActionsContainer_headerFilters {
  align-items: flex-end;
  gap: 0;
}

.filtersButton {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  background: var(--grey-200);
  border: none;
  cursor: pointer;
}

.filtersButton:hover {
  background-color: white;
}

.filtersButton > span:first-of-type {
  width: 28px;
  height: 28px;
  border-radius: 99px;
  background-color: white;
  color: var(--primary-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  box-shadow: 0px 5.231px 10.462px 0px rgba(0, 0, 0, 0.1);
}

.filtersButton > span:last-of-type {
  color: var(--primary-normal);
  text-transform: uppercase;
  font-size: 0.833125rem;
  line-height: 1.25rem;
  letter-spacing: 0.066rem;
  font-weight: 500;
}

.filtersButton_active > span:first-of-type {
  background-color: var(--primary-normal);
  color: white;
}

.displayStyleRadioContainer {
  display: flex;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 5px;
  height: 3rem;
  padding: 0 0.375rem;
}

.gamesAndPaginationContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  position: relative;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
  gap: 14px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}

.paginationControlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 64px 0;
}

.paginationButton,
.paginationButton:hover {
  font-size: 1rem;
  background-color: white;
  color: var(--primary-normal);
}

.paginationButton:active {
  background-color: var(--primary-normal);
  color: white;
}

.paginationButton:disabled {
  background: var(--white-gradient);
  color: var(--grey-500);
}

.leftColumnWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.rightColumnWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.noGamesHeading {
  color: var(--grey-800);
}

.noGamesText {
  font-size: 1.1rem;
  margin: 48px 0;
}

.EmptyContent {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  color: var(--grey-800);
  text-align: center;
}
.noContentButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.loaderBackgroundOverlay {
  position: absolute;
  top: -10;
  left: -10;
  right: -10;
  bottom: -10;
  border-radius: 18px;
  background-color: var(--grey400);
  z-index: 1;
  opacity: 0;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  /* Calculated in the offset of the pagination controls margin top/bottom 2x4rem,
     height 2.375rem and top spacing of 2rem
  */
  height: calc(100% - (2 * 4rem + 2.375rem + 2rem));
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .paginationControlsContainer {
    flex-direction: column;
  }
}
