.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  min-width: 80px;
  height: 36px;
  padding-right: 8px;
  color: var(--grey-800);
}

.wrapper + .wrapper {
  margin-left: 4px;
}

.wrapper:hover {
  transition: all 150ms ease-in-out;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.radioItem {
  cursor: pointer;
  font-size: 0.8rem;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  padding-left: 6px;
  gap: 10px;
  text-transform: uppercase;
}

.radioItem > input {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

.iconContainer {
  font-size: 24px;
  height: 24px;
  color: var(--grey-600);
}

.contentCentered {
  justify-content: center;
  gap: 8px;
}

.iconContainerChecked {
  color: var(--primary-normal);
}

.radioItem input:checked ~ .labelText {
  color: var(--grey-900);
}

.wrapperShadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.radioGroupWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 5px;
  width: fit-content;
  height: 48px;
  padding: 0 6px;
}
.wrapper:has(input:focus-visible) {
  outline: 2px solid -webkit-focus-ring-color;
}

.notificationBadge {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: var(--primary-normal);
  position: absolute;
  right: 12px;
}

/* Firefox keyboard focus outline workaround due to :has selector not working  */
.firefoxOutlineWorkaround {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Select a CSS class that comes after an input with keyboard focus that's inside a .wrapper class */
.wrapper > input:focus-visible ~ .firefoxOutlineWorkaround {
  outline: auto 2px;
}
