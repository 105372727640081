.levelModal {
  --lm-header-height: 4rem;
  --lm-footer-height: 4.875rem;
  --lm-width: calc(100vw - 16px);
  --lm-height: calc(100vh - 32px);
  --lm-padding: 16px;

  min-width: var(--lm-width);
  max-width: var(--lm-width);
  max-height: var(--lm-height);

  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .levelModal {
    --lm-width: min(90vw, 740px);
  }
}

.levelHeader,
.levelFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--lm-padding);
}

.levelHeader {
  height: var(--lm-header-height);
}

.levelHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.levelHeaderTitle > span:first-of-type {
  color: var(--primary-normal);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.levelHeaderTitle > span:last-of-type {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--grey-900);
}

.levelFooter {
  height: var(--lm-footer-height);
  background-color: var(--grey-50);
}

.levelMainSection {
  max-height: calc(var(--lm-height) - var(--lm-header-height) - var(--lm-footer-height));
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid var(--grey-400);
  border-bottom: 1px solid var(--grey-400);
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
}

.levelMainSection_morePadding {
  gap: 32px;
  padding: 32px 16px;
}

.unlockRequirementsInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tasksAndPointsContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 600px) {
  .nameInputContainer,
  .defaultBoardInputContainer {
    max-width: 50%;
  }
}

.tasksInputContainer {
  min-width: min(250px, 100%);
}

.requirementsWarning {
  background-color: var(--validation-error-light);
  padding: 12px;
  border-radius: 8px;
  color: var(--red-800);
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  max-width: fit-content;
}

.tasksInputError {
  top: calc(100% - 8px);
}
