.container {
  position: relative;
  transform: translateY(-64px);
  display: flex;
  border-radius: 8px;
  background: #ffffff;
  margin-top: 28px;
  width: min(100%, 888px);
  max-height: fit-content;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
}

.textAndActions {
  display: flex;
  flex-direction: column;
  z-index: 7;
  padding: 24px;
}

.title {
  font-weight: 700;
  font-size: 1.73rem;
  line-height: 2rem;
  display: flex;
  flex-direction: row;
}

.welcomeText {
  margin: 20px 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 386px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
}

.firstCircle {
  width: 21.25rem;
  height: 21.25rem;
  border-radius: 50%;
  background: var(--gradient-primary-background-horizontal);
  position: absolute;
  right: 6.25rem;
  top: -3.75rem;
  z-index: 5;
}

.secondCircle {
  width: 15.625rem;
  height: 15.625rem;
  border-radius: 50%;
  background: var(--gradient-primary-background-horizontal);
  opacity: 0.6;
  position: absolute;
  right: -5rem;
  top: 8.75rem;
  z-index: 4;
}

.welcomeBannerIcon {
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 6;
  height: min(242px, calc(100% - 32px));
  width: min(242px, calc(100% - 32px));
  text-align: right;
  opacity: 0.5;
}

.welcomeBannerIcon > svg {
  height: 100%;
  width: auto;
}

@media (min-width: 940px) {
  .welcomeBannerIcon {
    opacity: 1;
  }
}
