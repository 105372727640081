.progressContainer {
  background-color: var(--grey-200);
  border-radius: 8px;
  width: 100%;
}

.progress {
  background-color: var(--primary-normal);
  height: 0.5rem;
}
