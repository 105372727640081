.container {
  --wizard-padding: 8px;
  --max-wizard-height: calc(100vh - 2 * var(--wizard-padding));
  --max-wizard-width: calc(100vw - 2 * var(--wizard-padding));
  display: flex;
  flex-direction: column;
  width: min(var(--max-wizard-width), 852px);
  min-height: min(var(--max-wizard-height), 672px);
  height: fit-content;
  max-height: var(--max-wizard-height);
  background-color: white;
  overflow-y: auto;
  border-radius: 18px;
  position: absolute;
  z-index: 101;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .container {
    --wizard-padding: 24px;
  }
}

@media (min-width: 1280px) {
  .container {
    --wizard-padding: 40px;
  }
}

.largeContainer {
  min-height: min(var(--max-wizard-height), 832px);
}

.content {
  flex: 1;
  width: 100%;
  padding: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.seppoLogo {
  width: 90px;
}

.footer {
  margin-top: 16px;
  color: var(--grey-600);
  text-align: center;
}

.header > button {
  min-width: unset;
  min-height: unset;
  border-radius: unset;
  padding: 8px;
  margin-left: -8px;
}

.backdrop {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--backdrop-500);
}
