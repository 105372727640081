.awardBadgePanel {
  border: 2px solid var(--blue-100);
}

.awardBadgeHeader {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-200);
}

.awardBadgeHeader h1 {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-900);
  padding: 12px 0;
}

.awardBadgeMain {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  gap: 20px;
  padding: 16px 12px;
  scroll-behavior: smooth;
}

.playerBadgesTitle {
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: var(--grey-900);
  font-weight: 700;
  text-align: center;
}

.centredButton {
  max-width: fit-content;
  align-self: center;
}

.textButton {
  padding: 4px;
  border: 0;
  background-color: transparent;
  color: var(--primary-normal);
  text-transform: uppercase;
  cursor: pointer;
}

.textButton:hover {
  color: var(--primary-light);
}
