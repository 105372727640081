.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colContainer {
  display: flex;
  min-height: 2rem;
  gap: 4px;
}

.grayBGWrapper {
  background-color: var(--grey-200);
  border-radius: 5px;
  padding: 4px;
  margin-left: auto;
}

.radioButtonPinContainer {
  display: flex;
  align-items: center;
}

.radioButtonBarContainer {
  display: flex;
  align-items: center;
}

.radioButtonPinContainer > label:hover,
.radioButtonBarContainer > label:hover {
  cursor: pointer;
}

.originalInput {
  appearance: none;
}

.radioButtonPinContainer > .radioButtonPinLabel {
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 12px;
  gap: 8px;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: var(--grey-600);
  border: 1.5px solid transparent;
}

.radioButtonPinContainer > input:checked + .radioButtonPinLabel {
  border: 1px solid var(--primary-normal);
  border-radius: 1.0625rem;
  color: var(--primary-normal);
}

.radioButtonPinContainer > input:not(:checked) + .radioButtonPinLabel {
  color: var(--grey-600);
}

.radioButtonPinLabel > svg {
  font-size: 1rem;
}

.barsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
}

.radioButtonBarContainer > .radioButtonBarLabel {
  color: var(--grey-600);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
}

.radioButtonBarContainer > input:checked + .radioButtonBarLabel {
  color: var(--primary-normal);
  background-color: white;
}

.radioButtonBarContainer > input:hover + .radioButtonBarLabel {
  background-color: white;
}

.flashActiveIndicator {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: var(--primary-normal);
}

.radioContainer:has(.originalInput:focus-visible) label {
  outline: 2px solid -webkit-focus-ring-color;
}

.compactIconAndPoints {
  display: flex;
  align-items: center;
  gap: 4px;
}

.compactPointsIcon {
  color: var(--secondary-normal);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.compactPoints {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.totalPointsContainer {
  display: flex;
  gap: 0.5rem;
}

.totalPointsText {
  color: var(--grey-900);
  font-size: 12px;
  display: flex;
  align-items: center;
}
