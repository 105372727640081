.gamesTable {
  border-collapse: collapse;
  max-width: var(--page-with-topbar-max-content-width);
  display: table;
  table-layout: fixed;
}

.gamesTable th {
  text-transform: uppercase;
  font-size: var(--small-font);
  line-height: var(--small-font-line-height);
  color: var(--grey-900);
  font-weight: 500;
  text-align: left;
  padding: 14px 12px;
}

.gamesTable tbody {
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
}

.gamesTable tbody td {
  padding: 14px 12px;
  background-color: white;
}

.gamesTable tbody td:first-of-type {
  border-radius: 3px 0 0 3px;
}

.gamesTable tbody td:last-of-type {
  border-radius: 0 3px 3px 0;
  max-width: fit-content;
}

.gamesTable tbody tr {
  border: 1px solid var(--grey-250);
}

.gamesTable td:nth-child(1) {
  width: 30%;
}

.gamesTable td:nth-child(2) {
  width: 15%;
}

.gamesTable td:nth-child(3) {
  width: 15%;
}

.gamesTable td:nth-child(4) {
  width: 15%;
}

.gamesTable td:nth-child(5) {
  width: 15%;
}

.gamesTable td:nth-child(6) {
  width: 2rem;
}

@media (max-width: 1200px) {
  .lastModifiedColumn {
    display: none;
  }

  .infoColumn {
    display: none;
  }

  .gamesTable {
    width: 100%;
  }

  .gamesTable td:nth-child(1) {
    width: 50%;
  }

  .gamesTable td:nth-child(2) {
    width: 25%;
  }

  .gamesTable td:nth-child(3) {
    width: 25%;
  }

  .gamesTable td:nth-child(4) {
    width: 0;
  }

  .gamesTable td:nth-child(5) {
    width: 0;
  }

  .gamesTable td:nth-child(6) {
    width: 2rem;
  }
}
