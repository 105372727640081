.subtaskPreviewContainer {
  border-radius: 5px;
  background-color: var(--grey-50);
  padding: 4px 10px;
  display: table;
  border-spacing: 0 8px;
  max-width: 460px;
}

.subtaskPreviewContainer img {
  max-width: 100%;
  height: auto;
}

.subtaskPreviewContainer video {
  max-width: 100%;
}

.choiceOptionNoPointsPadding {
  padding-right: 16px;
}

.answerOptionRow,
.answerOptionRow_singleCol {
  display: table-row;
}

.answerOptionRow:not(.answerOptionRow_singleCol) > *:first-child {
  display: table-cell;
  width: 100%;
  position: relative;
}

.answerOptionRow:not(.answerOptionRow_singleCol) > *:last-child:not(:first-child) {
  display: table-cell;
  padding-inline-start: 16px;
  vertical-align: middle;
}

.choiceAnswerOptionContainer {
  background-color: white;
  border: 1px solid var(--grey-400);
  border-radius: 9px;
  color: var(--grey-700);
  padding: 8px 12px;
}

.choiceAnswerOptionImageAndText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.choiceAnswerOptionContainer_correct {
  border: 1px solid var(--green-50);
  color: var(--grey-900);
}

.missingWordPreview {
  border: 1px solid var(--green-50);
  border-radius: 9px;
  color: var(--grey-700);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--gray-100);
  padding: 8px 12px;
}

.matchPairsSidesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.matchPairsSide {
  background-color: white;
  border: 1px solid var(--grey-400);
  border-radius: 9px;
  padding: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--grey-900);
}

.matchPairsSide_noImage {
  justify-content: center;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--grey-500);
}
