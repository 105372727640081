.closeButton {
  border: none;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 99px;
  color: var(--grey-700);
  cursor: pointer;
  background-color: transparent;
}

.closeButton:hover {
  color: var(--grey-400);
}

.closeButton:disabled {
  color: var(--grey-400);
  cursor: not-allowed;
}
