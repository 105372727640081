.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  padding-left: calc(3.25rem + 8px);
}

.inputLengthSuffix {
  font-size: 0.75rem;
  color: var(--grey-600);
}

.codeSettingsInput {
  padding-top: 10px;
  padding-bottom: 10px;
  height: unset;
}

@media (min-width: 1024px) {
  .codeInputContainer {
    width: 50%;
  }

  .codeError {
    right: 50%;
  }
}

.codeSettingsInputLabel {
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  color: var(--grey-900);
}
