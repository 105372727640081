.startGameButton {
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  font-weight: 500;
  text-transform: uppercase;
  padding: 2px 8px;
  color: white;
  border: none;
  background: var(--gradient-primary-normal);
  border-radius: 99px;
  cursor: pointer;
}

.startGameButton:hover {
  opacity: 0.85;
}
.startGameButton:active {
  opacity: 0.7;
}

.noPermissionTooltip {
  max-width: 200px;
}
