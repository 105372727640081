.modalContainer {
  min-width: 25rem;
  max-width: 27rem;
  min-height: 8rem;
  z-index: 601;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: 24px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}
