.boardThumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  padding: 4px;
  align-items: center;
  background-color: var(--grey-100);
  max-width: 100px;
}

.boardThumbnailImageContainer {
  display: flex;
  height: 100px;
  width: 100%;
}

.boardThumbnailImageContainer img {
  object-fit: cover;
  width: 100% !important;
  border-radius: 9px;
}

.boardThumbnailName {
  background-color: var(--grey-100);
  color: var(--grey-900);
  padding: 4px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.activeBoardThumbnail {
  outline-style: solid !important;
  outline-color: var(--primary-normal);
  outline-width: 2px;
  outline-offset: 0px;
}

.boardThumbnailIconRow {
  position: absolute;
  display: flex;
  top: 10px;
  left: 0px;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 8px;
  gap: 4px;
}

.stateIcon,
.stateIconNoTasks,
.stateIconNoDoorsFrom,
.stateIconNoDoorsTo,
.stateIconDoorsOK {
  display: flex;
  color: white;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding-top: 2px;
  top: 10px;
  right: 10px;
}

.stateIconNoTasks {
  background-color: var(--red-800);
}

.stateIconNoDoorsFrom {
  background-color: var(--red-800);
}

.stateIconNoDoorsTo {
  background-color: var(--yellow-100);
}

.stateIconDoorsOK {
  background-color: var(--green-100);
}

.stateIconNoTasks span,
.stateIconNoDoorsFrom span,
.stateIconNoDoorsTo span,
.stateIconDoorsOK span {
  pointer-events: none;
}
