.publicGameLinkContainer {
  padding-left: calc(3.25rem + 20px);
  display: flex;
  align-items: center;
  gap: 16px;
}

.link {
  color: var(--grey-900);
}

.copyButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--primary-normal);
  display: flex;
  gap: 4px;
  align-items: center;
}

.copyButton:hover {
  color: var(--primary-dark);
}
