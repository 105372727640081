.row {
  display: flex;
  width: 100%;
}

.row > .buttonsWrapper > label {
  font-size: 0.75rem;
}

.row > label {
  color: var(--grey-700);
  padding-right: 8px;
  max-width: 8rem;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .row {
    flex-direction: column;
    gap: 8px;
  }

  .row > label {
    max-width: unset;
    padding-right: unset;
    margin-top: unset;
  }
}

.gameBoardTypeContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
}

.boardsWrapper {
  border-radius: 8px;
  border: 2px solid var(--grey-400);
  padding: 16px;
  background-color: var(--grey-50);
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.addButton {
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 6rem;
  box-sizing: border-box;
}

.withLabel {
  width: 15rem;
  padding: 1.625rem 1rem;
  margin: 0 auto;
}

.withoutLabel {
  width: fit-content;
  padding: 1.625rem;
  background-color: var(--grey-200);
}

.selectedStaticBoardThumbnail {
  min-width: 96px;
  max-width: 96px;
  border: none;
  padding: 0;
  background-color: transparent;
}

.selectedPanoramaBoardThumbnail {
  cursor: pointer;
  position: relative;
}

.editablePanoramaArea {
  border-radius: 8px;
  border: 2px solid var(--grey-400);
  overflow: hidden;
  height: 24rem;
  width: 100%;
  position: relative;
}

.selectedStaticBoardThumbnail:hover > .thumbnailImageContainer {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.thumbnailImageContainer {
  width: 96px;
  height: 96px;
  border-radius: 5px;
  position: relative;
}

.thumbnailImage {
  width: 96px;
  height: 96px;
  border-radius: 5px;
  object-fit: contain;
  background-color: white;
}

.thumbnailImagePanorama {
  object-fit: cover;
}

.thumbnailBoardName {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  gap: 0.5rem;
}

.gradientOverlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 50%);
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  height: 80%;
}

.panoramaMoreMenuWrapper {
  position: absolute;
  width: 100%;
  height: 3rem;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 90%);
}

.mapboxContainer {
  border-radius: 8px;
  border: 2px solid var(--grey-400);
  overflow: hidden;
  height: 24rem;
  width: 100%;
  position: relative;
}

.moreMenuButton {
  color: white;
}

.mapLockOverlay {
  position: absolute;
  min-width: 100%;
  height: 24rem;
  background-color: rgba(235, 235, 235, 0.6);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  z-index: 3;
  color: var(--grey-900);
}

.editMapButtonIcon {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.moveTasksButton {
  max-width: fit-content;
  align-self: center;
}
