.container {
  border-radius: 9px;
  border: 1px solid var(--grey-100);
  padding: 0.75rem 0rem 0rem 0rem;
  background-color: white;
  width: 20rem;
  position: absolute;
  z-index: 200;
  right: 0;
  top: 0;
  overflow: hidden;
}

.header {
  color: black;
  height: 4rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  padding: 0rem 1rem;
  margin-bottom: 11px;
}

.rowSeparator {
  width: 100%;
  height: 1px;
  background: var(--grey-200);
}

.menuContainer {
  padding: 5px 8px;
}

.menuContainer:hover {
  transition: all 100ms ease-in-out;
  background-color: var(--grey-200);
}

.profileInitials {
  min-width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 1.25rem;
  color: white;
  background: var(--gradient-primary-normal);
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
}

.profileName {
  display: block;
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.loaderBackgroundOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-700);
  z-index: 100;
  opacity: 0.2;
}

.loaderContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
