.taskPreviewHeaderDetails {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tag {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--grey-900);
  padding: 2px 12px;
  border-radius: 99px;
  background-color: var(--primary-light);
  text-transform: uppercase;
}

.iconAndPointsContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.iconAndPointsContainer > svg {
  color: var(--secondary-normal);
  width: 1rem;
  height: 1rem;
}
