.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.actionsContainer {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.showingAllAddNewButton {
  height: fit-content;
}

.sectionTitle {
  flex: 1;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
