.resultLineCheckboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  border: 2px solid var(--grey-500);
  border-radius: 4px;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: relative;
  outline-offset: 2px;
}

.checkbox:checked {
  background-color: var(--primary-normal);
  border-color: white;
  outline-offset: 0;
  outline: 1px solid var(--primary-normal);
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.1rem;
  border-radius: 3px;
}

.checkbox:focus-visible {
  outline: 2px solid -moz-mac-focusring;
  outline: 2px solid -webkit-focus-ring-color;
}

.highlightSearchTerm {
  font-weight: bold;
}

.listLine {
  display: flex;
  font-size: 12px;
  padding: 4px;
  gap: 8px;
  align-items: center;
}

.listContentRow {
  display: flex;
  gap: 4px;
}

.listContentColumn {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.baseTag,
.clickableTag,
.activeTag,
.instantResultsTag {
  background-color: var(--primary-normal);
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 12px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.clickableTag {
  cursor: pointer;
}

.activeTag {
  background-color: var(--primary-dark);
}

.instantResultsTag {
  cursor: pointer;
  background-color: var(--primary-normal) !important;
}

.activeTag > span,
.clickableTag > span {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.activeTag > span:last-of-type,
.clickableTag > span:last-of-type {
  margin-left: auto;
}

.expandTagChevronContainer {
  margin-left: auto;
}

.chevronWrapper {
  color: var(--grey-600);
  transition: all 200ms ease-out;
  line-height: 0;
  cursor: pointer;
}

.chevronOpen {
  color: var(--grey-900);
  transform: rotate(90deg);
}

.listContentSecondLine {
  display: flex;
  align-items: center;
  gap: 6px;
}

.listContentSecondLine span {
  color: var(--grey-700);
  display: flex;
  align-items: center;
}

.listContentSecondLine div {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
