.joinGameContainer {
  position: relative;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Seppo-App-and-Editor-primary-normal, #cc3e76);
  background: var(--White, #fff);
  /* Seppo Big Shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;
  overflow: hidden;
}

.addWithPin {
  position: relative;
  display: flex;
  padding: 2px 0px;
  align-items: flex-start;
  gap: 16px;
}

.warningBadge {
  position: absolute;
  background-color: var(--validation-error-secondary);
  color: black;
  border-radius: 4px;
  bottom: -1.25rem;
  left: 0;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  min-height: 1.0625rem;
  z-index: 1;
}

.firstCircle {
  border-radius: 50%;
  background: var(--primary-background);
  width: 251px;
  height: 251px;
  position: absolute;
  right: -49.306px;
  bottom: -76px;
  opacity: 0.4;
}

.secondCircle {
  border-radius: 50%;
  z-index: 6;
  width: 164px;
  height: 164px;
  background: var(--primary-background);
  position: absolute;
  right: 119.694px;
  top: -60px;
  opacity: 0.4;
}

.gamePinInput {
  z-index: 10;
}

.joinGameButton {
  z-index: 10;
}

.loaderBackgroundOverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-700);
  z-index: 100;
  opacity: 0.2;
}

.loaderContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
