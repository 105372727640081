:root {
  /* FONT WEIGHT VARIABLES*/
  --light: 400;
  --regular: 500;
  --bold: 700;

  /* FONT SIZE VARIABLES */
  --tiny-font: 0.75rem; /* 12px */
  --small-font: 0.833125rem; /* 13.33px */
  --normal-font: 1rem; /* 16px */
  --medium-font: 1.1rem; /* 17.6px */

  /* LINE HEIGHT VARIABLES */
  --small-font-line-height: 1.25rem; /* 20px */
  --tiny-font-line-height: 1rem; /* 16px */
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h1 {
  font-size: 2rem;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 2.5rem;
}
h2 {
  font-size: 1.7rem;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 2.25rem;
}
h3 {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 2rem;
}
h4 {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 1.5rem;
}
h5 {
  font-size: 1rem;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 1.25rem;
}

/*  Font decorators */
.light {
  font-weight: var(--light);
}
.regular {
  font-weight: var(--regular);
}
.bold {
  font-weight: var(--bold);
}
.italic {
  font-style: italic;
}

/*  Font sizes */
.tiny {
  font-size: var(--tiny-font);
  line-height: 1rem;
}
.small {
  font-size: var(--small-font);
  line-height: var(--small-font-line-height);
}
.normal {
  font-size: var(--normal-font);
  line-height: 1.5rem;
}
.medium {
  font-size: var(--medium-font);
  line-height: 1.5rem;
}

.iconWrapperBig {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
.iconWrapperMedium {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

/* Specific cases */
.detailTiny {
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
}
.detailNormal {
  font-size: 0.8rem;
  font-style: normal;
  font-weight: var(--regular);
  line-height: 1.25rem;
}

.detailBold {
  font-size: 0.833rem;
  font-style: normal;
  font-weight: var(--bold);
  line-height: 1.25rem;
  text-transform: uppercase;
}

.detailGrey700 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--grey-700);
}

/* transform */
.uppercase {
  text-transform: uppercase;
}

/* text color */
.grey-900 {
  color: var(--grey-900);
}

.grey-700 {
  color: var(--grey-700);
}

.grey-600 {
  color: var(--grey-600);
}
