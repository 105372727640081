.badgeViewContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.badgeTitleAndMoreMenu {
  display: flex;
  gap: 4px;
  align-items: center;
}

.badgeTitle {
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}

.badgeMoreMenuButton {
  padding: 0;
  font-size: 1rem;
}

.badgeImageOuterContainer {
  width: 64px;
  height: 64px;
  border-radius: 99px;
  background-color: white;
  box-shadow: 0px 2.5px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.badgeImageOuterContainer_tiny {
  width: 32px;
  height: 32px;
}

.badgeImageInnerContainer {
  width: 100%;
  height: 100%;
  border-radius: 99px;
  background-color: var(--grey-200);
}

.badgeListItem {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.selectableBadge {
  border: none;
  background-color: transparent;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  accent-color: var(--primary-normal);
}

.selectableBadge_morePadding {
  padding: 16px;
}

.selectableBadge_selected {
  background-color: var(--grey-100);
}

.selectableBadge_selectedOnDark {
  background-color: white;
}

.selectableBadge_otherSelected {
  opacity: 0.5;
}

.selectableBadge:hover .selectableBadgeImage {
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
}

.pointsContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: -10px;
}

.pointsContainer > span:first-of-type {
  color: var(--secondary-normal);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.pointsContainer > span:last-of-type {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}
