.pointsContainer {
  margin-top: 16px;
  padding: 0.5rem;
  border: 1px solid var(--secondary-normal);
  border-radius: 8px;
  background-color: var(--grey-50);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pointsContainer p {
  color: var(--grey-900);
}

.pointsTitle {
  display: flex;
  gap: 4px;
  align-items: center;
}

.pointsTitle h4 {
  color: var(--grey-900);
}

.pointsTitleIcon {
  display: flex;
  font-size: 2rem;
  color: var(--secondary-normal);
}

.pointsSection {
  display: flex;
  gap: 8px;
}

.pointsSectionDescriptionAndInput {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.pointsButton {
  flex: 1;
  display: inline-block;
  padding: 0.75rem 1rem;
  border-radius: 9px;
  background-color: #fff;
  border: 1.75px solid var(--grey-500);
  box-shadow: 0px 4px 8px 0px #0000001a;
  cursor: pointer;
}

.pointsButtonActive {
  color: #fff;
  background-color: var(--secondary-normal);
  border: 1.75px solid var(--secondary-normal);
}

.pointsButton:hover:not(.pointsButtonActive) {
  background-color: var(--secondary-light);
  border: 1.75px solid var(--secondary-light);
}

.answerSelectableIcon {
  height: 6.25rem;
}

.pointsAutomaticContainer {
  display: flex;
  gap: 8px;
  flex: 1;
}

.percentageInputSuffix {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--grey-600);
}

.percentageControl {
  width: 30%;
}

.percentageTooltip {
  max-width: 175px;
}
