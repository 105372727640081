.menuContainerUserMenu {
  border: none;
  background-color: inherit;
  padding: 1.25rem;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  align-items: center;
}

.menuContainerContextMenu {
  background-color: inherit;
  border: none;
  padding: 0.3125rem 0.5rem;
  transition: all 100ms ease-in-out;
  cursor: pointer;
}

.menuContainerContextMenu:hover {
  background: var(--grey-200);
}

.menuContainerUserMenu:hover {
  background: var(--gradient-primary-background-horizontal);
}

.menuContainerUserMenu:hover .iconContainer {
  color: var(--grey-900);
}

.menuItemLabelWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.menuLink {
  border: none;
  background: none;
  text-align: start;
  color: var(--grey-900);
  text-decoration: none;
  display: flex;
  flex: 1;
}

.subtext {
  text-align: start;
  color: var(--grey-900);
  text-decoration: none;
  padding-top: 0.25rem;
}

.iconContainer {
  color: var(--grey-600);
  font-size: 1.3125rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.submenuIcon {
  color: var(--grey-600);
  transform: rotate(270deg);
  display: flex;
  align-items: center;
}

.backIcon {
  color: var(--primary-normal);
  font-weight: 500;
  font-size: 1.3125rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.backText {
  font-weight: 700;
}
