.languageSelectContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.languageSelectContainer label {
  color: var(--grey-700);
  font-size: 1.125rem;
}

.languageSelect {
  min-width: 15rem;
}
