.pageContainer {
  width: 100%;
  min-height: calc(100vh - var(--global-notification-height));
  padding: var(--page-with-topbar-padding);
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.container {
  height: 100%;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.gamesAndPaginationContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  position: relative;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
  gap: 14px;
}

.cardDisabledOverlay {
  position: absolute;
  opacity: 0.2;
  background-color: var(--grey-600);
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 13px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  min-height: 160px;
  border-radius: 17px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  /* TODO: check if we want equal or unequal heights (current design - longer title more height) */
  height: 100%;
  outline: none;
}
.cardContainer:hover {
  transition: all 150ms;
  box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.2);
}

.cardContainer:not(:hover) {
  transition: all 150ms ease-out;
}

.top {
  display: flex;
  min-height: 93px;
  align-items: flex-start;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.top > div {
  display: flex;
  flex: 1;
  gap: 6.4px;
  justify-content: flex-end;
  position: relative;
}

.cardButton {
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  color: white !important;
  background-color: var(--primary-normal) !important;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 5.23077px 10.46154px 0px rgba(0, 0, 0, 0.1);
}

.cardButton:hover {
  transition: all 50ms ease-in;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
}

.bot {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  flex: 1;
}

.bot .gameTitle {
  margin-bottom: 7px;
  font-weight: var(--bold);
  font-size: var(--medium-font);
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: var(--grey-900);
}

.bot > div {
  margin-bottom: 5px;
}

.gameInfo {
  display: flex;
  gap: 8px;
  max-width: fit-content;
}

.gameInfo > span {
  font-size: var(--tiny-font);
  line-height: var(--tiny-font-line-height);
  color: var(--grey-900);
}

.compactIconAndPoints {
  display: flex;
  align-items: center;
  gap: 4px;
}

.compactPointsIcon {
  color: var(--secondary-normal);
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.compactPoints {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.gameInfoContainer {
  display: flex;
  justify-content: space-between;
}
