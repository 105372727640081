.pageContainer {
  width: 100%;
  min-height: calc(100vh - var(--global-notification-height));
  padding: var(--page-with-topbar-padding);
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - var(--nav-sidebar-width));
  overflow-x: auto;
}

.mainContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
}

@media (max-width: 900px) {
  .pageContainer {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    overflow-x: auto;
  }
}
