.taskForPlayerContainer {
  display: flex;
  align-items: center;
  border-radius: 9px;
  background-color: var(--grey-100);
  padding: 8px;
  gap: 10px;
  margin: 0;
  border: 1px solid transparent;
  cursor: pointer;
}

.taskForPlayerContainer:not(.taskForPlayerContainer_selected):hover {
  background-color: var(--grey-50);
  border-color: var(--grey-500);
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-normal);
  font-size: 2rem;
}

.nameAndDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}

.taskName {
  color: var(--grey-700);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  word-break: break-word;
}

.taskForPlayerContainer:not(.taskForPlayerContainer_selected):hover .taskName {
  color: var(--grey-900);
}

.details {
  display: flex;
  align-items: center;
  gap: 4px;
}

.statusAndTypePill {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--grey-900);
  padding: 2px 12px;
  border-radius: 99px;
  background-color: var(--primary-light);
  text-transform: uppercase;
}

.iconAndPointsContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--blue-100);
}

.iconAndPointsContainer > svg {
  color: var(--secondary-normal);
  width: 1rem;
  height: 1rem;
}

.iconContainer > svg {
  width: 2rem;
  height: 2rem;
}

.taskForPlayerContainer_compact {
  gap: 4px;
}

.taskForPlayerContainer_compact .iconContainer {
  font-size: 1rem;
}

.taskForPlayerContainer_compact .nameAndDetails {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.taskForPlayerContainer_compact .taskName {
  font-size: 0.75rem;
  line-height: 1rem;
}

.taskForPlayerContainer_compact .details {
  flex-direction: row-reverse;
}

.taskForPlayerContainer_ready {
  border-color: var(--secondary-normal);
}

.taskForPlayerContainer_ready .iconContainer {
  color: var(--secondary-normal);
}

.taskForPlayerContainer_ready .statusAndTypePill {
  background-color: var(--secondary-normal);
  color: white;
}

.taskForPlayerContainer_graded .iconContainer {
  color: var(--green-50);
}

.taskForPlayerContainer_graded .statusAndTypePill {
  background-color: var(--green-50);
  color: white;
}

.taskForPlayerContainer_graded .iconAndPointsContainer {
  color: var(--blue-100);
}

.taskForPlayerContainer_graded .iconAndPointsContainer {
  color: var(--secondary-normal);
}

.taskForPlayerContainer_neutral .statusAndTypePill {
  background-color: var(--grey-300);
  color: var(--grey-900);
}

.taskForPlayerContainer_neutral .iconContainer {
  color: var(--grey-500);
}

.taskForPlayerContainer_selected {
  background-color: var(--secondary-normal);
}

.taskForPlayerContainer_selected .taskName,
.taskForPlayerContainer_selected .iconAndPointsContainer,
.taskForPlayerContainer_selected .iconAndPointsContainer > svg,
.taskForPlayerContainer_selected .iconContainer {
  color: white;
}

.taskForPlayerContainer_selected .statusAndTypePill {
  background-color: var(--grey-900);
  color: white;
}
