.infoPill {
  border-radius: 100px;
  padding: 2px 8px;
  text-transform: uppercase;
  font-weight: var(--bold);
  transition: all 0.2s;
  white-space: nowrap;
}

.infoPill_primary {
  background-color: var(--primary-background);
}
.infoPill_primary:hover {
  background-color: var(--primary-normal);
  color: var(--primary-background);
}

.infoPill_community {
  background-color: var(--secondary-background);
}
.infoPill_community:hover {
  background-color: var(--secondary-normal);
  color: var(--secondary-background);
}
