.fieldWithErrorWrapper {
  position: relative;
}

.hasError :global(.form-field-with-outline-error),
.hasError:global(.form-field-with-outline-error) {
  outline: 2px solid var(--validation-error);
}
.hasError :global(.form-field-with-rounded-outline-error),
.hasError:global(.form-field-with-rounded-outline-error) {
  border-radius: 8px;
  outline: 2px solid var(--validation-error);
}

.errorContainer {
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 8px 0 24px;
  display: inline-block;
  position: absolute;
  right: 0;
  color: #fff;
  background-color: var(--validation-error);
  text-align: right;
  top: calc(100% - 14px);
  z-index: 1;
}

.submitErrorContainer {
  background-color: var(--validation-error);
  color: white;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
}

.separateFormFieldError {
  color: #fff;
  background-color: var(--validation-error);
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  max-width: fit-content;
}
