.taskEvaluationSort {
  padding: 12px 16px;
  background-color: var(--grey-50);
  display: flex;
  align-items: center;
  gap: 8px;
}

.taskEvaluationSortInput {
  min-width: 230px;
}
