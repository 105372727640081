.backdrop {
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop_darkened {
  background-color: var(--backdrop-500);
}

.forceTop {
  z-index: 600;
}
