.initial {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.35s;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.35s;
}

.fadeOutPartial {
  opacity: 0.3;
  transition: opacity 0.35s;
}
