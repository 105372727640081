.addTimeContainer {
  display: flex;
  gap: 8px;
}

.addTimeContainer_withError {
  border-radius: 8px;
  outline: 2px solid var(--validation-error);
  position: relative;
}

.addTimeErrorMessage {
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 8px 0 24px;
  display: inline-block;
  position: absolute;
  right: 0;
  color: #fff;
  background-color: var(--validation-error);
  text-align: right;
  top: calc(100% - 8px);
  z-index: 1;
}

.label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.input {
  max-width: 69px;
  padding: 4px 4px 4px 12px;
  border: 1px solid var(--grey-300);
  border-radius: 9px;
}
