.awardBadgeContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
}

.badgeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.badgeListItem {
  list-style: none;
}

.noBadgesText {
  color: var(--grey-700);
  text-align: center;
  padding: 0 16px;
  font-size: 1rem;
  line-height: 1.5rem;
}
