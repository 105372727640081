.field {
  resize: none;
  padding: 10px 12px;
  background: #ffffff;
  border: 1.5px solid var(--grey-400);
  border-radius: 9px;
  font-size: 1.125rem;
  outline-color: var(--primary-normal);
}

.field::placeholder {
  color: var(--grey-600);
  opacity: 1;
}

.textAreaError {
  top: calc(100% - 18px);
}

.textAreaWithLabelAfter {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.remainingCharsLabel {
  color: var(--grey-600);
  margin-top: 10px;
  font-size: 12px;
}
