.automaticBadgeContainer {
  padding-left: calc(3.25rem + 8px);
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.currentBadgeAndButton {
  display: flex;
  gap: 16px;
  align-items: center;
}

.badgePointsArea {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.badgeIdError {
  max-width: fit-content;
}
