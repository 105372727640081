.icon {
  background-color: initial;
  border: initial;
  color: var(--secondary-normal);
  pointer-events: none !important;
}

.notReachable {
  color: var(--grey-500);
  stroke: var(--grey-500);
}

.normalLine {
  stroke: var(--secondary-normal);
}
